import React, { Component } from "react";
import "../Home.css";
import ShopperOrdersTable from "./ShopperOrdersTable";
import ShopperDetail from "./ShopperDetail";
import ShopperDeclinedTransactionsTable from "./ShopperDeclinedTransactionsTable";
import ShopperDanglingTransactionsTable2 from "./ShopperDanglingTransactionsTable2";
import { API, Storage } from "aws-amplify";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { merge } from "lodash";
import moment from "moment";
import { Auth } from "aws-amplify";
import { withStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";

import config from "../../config";

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    marginBottom: theme.spacing(2),
  },
  rootSpinner: {
    padding: theme.spacing(2, 2),
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  danglingTransactions: {
    paddingBottom: theme.spacing(2),
  },
});

class Shopper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      shopper: null,
      shopperOrders: null,
      shopperCards: null,
      shopperDanglingTransactions: null,
      shopperDeclinedTransactions: null,
      shopperNotes: null,
      shopperNotesLoading: false,
      shopperPhoneIsOptedOut: null,
      shopperCardsLoading: false,
      shopperOnboardingStatus: {},
      shopperUserRemapping: []
    };
  }

  async componentDidMount() {
    try {
      this.getShopperOrders();
    } catch (e) {
      alert(e);
    }

    try {
      this.getShopperCards();
    } catch (e) {
      alert(e);
    }

    try {
      this.getShopperNotes();
    } catch (e) {
      alert(e);
    }

    try {
      // TODO - i can speed this up by sending the cardAcctIds but I'm having an issue formatting it
      this.shopperDanglingTransactionsList();
    } catch (e) {
      alert(e);
    }

    try {
      // TODO - i can speed this up by sending the cardAcctIds but I'm having an issue formatting it
      this.shopperDeclinedTransactionsList();
    } catch (e) {
      alert(e);
    }

    try {
      this.getShopperOnboardingStatus();
    } catch (e) {
      alert(e);
    }

    try {
      this.getShopperUserMapping();
    } catch (e) {
      alert(e);
    }

    try {
      const shopper = await this.getShopper();
      this.setState({ shopper }, () => {
        // this must be done after shopper is set as it depends on the phone number of a shopper
        try {
          this.checkShopperPhoneOptOut();
        } catch (e) {
          alert(e);
        }
      });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  getShopper = async () => {
    return await API.get("shoppers", `/shoppers/${this.props.match.params.id}`);
  };

  getShopperOnboardingStatus = async () => {
    const response = await API.get(
      "shoppers",
      `/shoppers/${this.props.match.params.id}/onboarding-status`
    );

    this.setState({
      shopperOnboardingStatus: !isEmpty(response) ? response : {},
    });
  };

  getShopperUserMapping = async () => {
    const response = await API.get(
      "shoppers",
      `/shoppers/${this.props.match.params.id}/user-remapping`
    );

    this.setState({
      shopperUserRemapping: !isEmpty(response) ? [response] : [],
    });
  };

  getShopperOrders = async () => {
    const response = await API.get(
      "shoppers",
      `/shoppers/${this.props.match.params.id}/orders`,
      {
        queryStringParameters: {
          "max-rows": "500", // TODO: capping this at 500 so it loads faster and doesn't hit AWS API Gateway limit, need to add pagination
        },
      }
    );
    if (!isEmpty(response)) {
      const shopperOrders = [];
      for (const shopperOrder of response) {
        // adjust the fee so the table displays in dollars
        //shopperOrder['deliveryFee'] = shopperOrder['deliveryFee'] / 100

        // Insert a dangling transaction field
        var hasDanglingTransaction = false;
        if ("transactions" in shopperOrder) {
          if (
            shopperOrder["transactions"].length > 0 &&
            shopperOrder.orderStatus === "Created"
          ) {
            hasDanglingTransaction = true;
          }
        }
        shopperOrder["hasDanglingTransaction"] = hasDanglingTransaction;
        shopperOrders.push(shopperOrder);
      }
      this.setState({
        shopperOrders: shopperOrders,
      });
    } else {
      this.setState({
        shopperOrders: null,
      });
    }
    //alert(JSON.stringify(response, null, 2));
    //console.log(JSON.stringify(response, null, 2))
  };

  getShopperCards = async () => {
    this.setState({ shopperCardsLoading: true });
    const response = await API.get(
      "shoppers",
      `/shoppers/${this.props.match.params.id}/cards`
    );
    this.setState({ shopperCardsLoading: false });

    if (!isEmpty(response)) {
      this.setState({
        shopperCards: response,
      });
    } else {
      this.setState({
        shopperCards: null,
      });
    }
  };

  getShopperNotes = async () => {
    this.setState({ shopperNotesLoading: true });
    const response = await API.get(
      "shoppers",
      `/shoppers/${this.props.match.params.id}/notes`
    );
    this.setState({ shopperNotesLoading: false });

    if (!isEmpty(response)) {
      this.setState({
        shopperNotes: response,
      });
    } else {
      this.setState({
        shopperNotes: null,
      });
    }
  };

  checkShopperPhoneOptOut = async () => {
    if (this.state.shopper.phone) {
      const response = await API.get(
        "shoppers",
        `/sns/phone/${this.state.shopper.phone}`
      );

      if (!isEmpty(response)) {
        this.setState({
          shopperPhoneIsOptedOut: response.isOptedOut,
        });
      } else {
        this.setState({
          shopperPhoneIsOptedOut: null,
        });
      }
    }
  };

  updateShopperOrder = (shopperOrder) => {
    // only send the parts we want to update
    var body = {};
    // force whatever they type to uppercase
    body["couponCode"] = shopperOrder["couponCode"].toUpperCase();
    // re-adjust back to cents
    //body['deliveryFee'] = shopperOrder['deliveryFee'] * 100
    // TODO - for some reason material table to changeing this to a string, change it back to an int
    body["tip"] = parseInt(shopperOrder["tip"]);

    return API.patch(
      "shoppers",
      `/shoppers/${this.props.match.params.id}/orders/${shopperOrder.id}`,
      {
        body: body,
        queryStringParameters: {
          "delivery-timestamp": shopperOrder["deliveryTimestamp"], // sending this does a faster operation in the backend
        },
      }
    );
  };

  reopenShopperOrder = (shopperOrder) => {
    // sending body is unnecessary, the endpoint sets internalStatus to 'Auth' and orderStatus to 'Created' for both LinkedOrder and Order
    return API.patch(
      "shoppers",
      `/shoppers/${this.props.match.params.id}/orders/${shopperOrder.id}/reopen`,
      {
        queryStringParameters: {
          // these are all required for reopen
          "delivery-timestamp": shopperOrder["deliveryTimestamp"],
          "client-id": shopperOrder["clientId"],
          "order-timestamp": shopperOrder["orderTimestamp"],
        },
      }
    );
  };

  cancelShopperOrder = (shopperOrder) => {
    // sending body is unnecessary, the endpoint sets internalStatus to 'Auth' and orderStatus to 'Created' for both LinkedOrder and Order
    return API.patch(
      "shoppers",
      `/shoppers/${this.props.match.params.id}/orders/${shopperOrder.id}/cancel`,
      {
        queryStringParameters: {
          // these are all required for cancel
          "delivery-timestamp": shopperOrder["deliveryTimestamp"],
          phone: this.state.shopper["phone"],
        },
      }
    );
  };

  moveShopperOrder = (shopperOrder, destShopperId) => {
    // sending body is unnecessary, the endpoint sets internalStatus to 'Auth' and orderStatus to 'Created' for both LinkedOrder and Order
    return API.patch(
      "shoppers",
      `/shoppers/${this.props.match.params.id}/orders/${shopperOrder.id}/move`,
      {
        queryStringParameters: {
          // these are all required for cancel
          "delivery-timestamp": shopperOrder["deliveryTimestamp"],
          "dest-shopper-id": destShopperId,
        },
      }
    );
  };

  uncancelShopperOrder = (shopperOrder) => {
    // sending body is unnecessary, the endpoint sets internalStatus to 'Auth' and orderStatus to 'Created' for both LinkedOrder and Order
    return API.patch(
      "shoppers",
      `/shoppers/${this.props.match.params.id}/orders/${shopperOrder.id}/uncancel`,
      {
        queryStringParameters: {
          // these are all required for reopen
          "delivery-timestamp": shopperOrder["deliveryTimestamp"],
          "client-id": shopperOrder["clientId"],
          "order-timestamp": shopperOrder["orderTimestamp"],
        },
      }
    );
  };

  dissociateShopperOrderTransaction = (shopperOrder, transaction) => {
    // sending body is unnecessary, the endpoint sets internalStatus to 'Auth' and orderStatus to 'Created' for both LinkedOrder and Order
    return API.patch(
      "shoppers",
      `/shoppers/${this.props.match.params.id}/orders/${shopperOrder.id}/transactions/${transaction.id}/dissociate`,
      {
        queryStringParameters: {
          // these are all required for dissociate
          "delivery-timestamp": shopperOrder["deliveryTimestamp"],
          phone: this.state.shopper["phone"],
        },
      }
    );
  };

  dissociateShopperOrderExtTransaction = (shopperOrder, transaction) => {
    // sending body is unnecessary, the endpoint sets internalStatus to 'Auth' and orderStatus to 'Created' for both LinkedOrder and Order
    return API.patch(
      "shoppers",
      `/shoppers/${this.props.match.params.id}/orders/${shopperOrder.id}/external-transactions/${transaction.id}/dissociate`,
      {
        queryStringParameters: {
          // these are all required for dissociate
          "delivery-timestamp": shopperOrder["deliveryTimestamp"],
          phone: this.state.shopper["phone"],
        },
      }
    );
  };

  updateShopperTransaction = (transaction) => {
    // only send the parts we want to update (we are only doing ignore for now)
    var body = {};

    body["ignore"] = transaction.ignore;

    // sending body is unnecessary, the endpoint sets internalStatus to 'Auth' and orderStatus to 'Created' for both LinkedOrder and Order
    return API.patch(
      "shoppers",
      `/shoppers/${this.props.match.params.id}/transactions/${transaction.id}`,
      {
        body: body,
        queryStringParameters: {
          // these are all required for transactions list
          "card-acct-id": transaction.cardAcctId,
        },
      }
    );
  };

  shopperOrdersUpdate = async (newData, oldData) => {
    const response = await this.updateShopperOrder(newData);
    if (!isEmpty(response)) {
      // adjust the fee so the table displays in dollars
      //response['deliveryFee'] = response['deliveryFee'] / 100
      const data = this.state.shopperOrders;
      const index = data.indexOf(oldData);
      // NOTE: This merge is important because during api updates the sharedList is not sent back so we need to keep the original data
      const mergedData = merge(newData, response);
      data[index] = mergedData;
      this.setState({ data });
    } // otherwise nothing to update
  };

  shopperOrderReopen = async (rowData) => {
    const response = await this.reopenShopperOrder(rowData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // adjust the fee so the table displays in dollars
      //response['deliveryFee'] = response['deliveryFee'] / 100
      const data = this.state.shopperOrders;
      const index = data.indexOf(rowData);
      // NOTE: This merge is important because during api updates the sharedList is not sent back so we need to keep the original data
      const mergedData = merge(rowData, response);
      data[index] = mergedData;
      this.setState({ data });

      // now refetch shopper since it will have changed
      try {
        const shopper = await this.getShopper();
        this.setState({ shopper });
      } catch (e) {
        alert(e);
      }
    } // otherwise nothing to update
  };

  shopperOrderCancel = async (rowData) => {
    const response = await this.cancelShopperOrder(rowData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // adjust the fee so the table displays in dollars
      //response['deliveryFee'] = response['deliveryFee'] / 100
      const data = this.state.shopperOrders;
      const index = data.indexOf(rowData);
      // NOTE: This merge is important because during api updates the sharedList is not sent back so we need to keep the original data
      const mergedData = merge(rowData, response);
      data[index] = mergedData;
      this.setState({ data });
    } // otherwise nothing to update
  };

  shopperOrderMove = async (rowData, destShopperId) => {
    const response = await this.moveShopperOrder(rowData, destShopperId)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (response) {
      console.log("the res", response)
      if (response === true) {
        // A true return means the order was removed
        const data = this.state.shopperOrders;
        const index = data.indexOf(rowData);
        // remove this row
        data.splice(index, 1);
        this.setState({ data} );
      } else {
        alert("Error! Move unsuccessful. Contact an admin.");
        return null;
      }
    } // otherwise nothing to update
  };

  shopperOrderUncancel = async (rowData) => {
    const response = await this.uncancelShopperOrder(rowData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // adjust the fee so the table displays in dollars
      //response['deliveryFee'] = response['deliveryFee'] / 100
      const data = this.state.shopperOrders;
      const index = data.indexOf(rowData);
      // NOTE: This merge is important because during api updates the sharedList is not sent back so we need to keep the original data
      const mergedData = merge(rowData, response);
      data[index] = mergedData;
      this.setState({ data });

      // now refetch shopper since it will have changed
      try {
        const shopper = await this.getShopper();
        this.setState({ shopper });
      } catch (e) {
        alert(e);
      }
    } // otherwise nothing to update
  };

  shopperTransactionRemove = async (rowData, transactionData) => {
    const response = await this.dissociateShopperOrderTransaction(
      rowData,
      transactionData
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // adjust the fee so the table displays in dollars
      //response['deliveryFee'] = response['deliveryFee'] / 100
      const data = this.state.shopperOrders;
      const index = data.indexOf(rowData);
      // NOTE: This merge is important because during api updates the sharedList is not sent back so we need to keep the original data
      // first delete the transactions so we get the new list, then merge
      delete rowData["transactions"];
      delete rowData["transactionsList"];
      const mergedData = merge(rowData, response);
      data[index] = mergedData;
      this.setState({ data });
      // after getting back the new updated transactions list, refetch the individual transactions to create transactionsList
      this.shopperTransactionsList(mergedData);
    } // otherwise nothing to update
  };

  shopperExtTransactionRemove = async (rowData, transactionData) => {
    const response = await this.dissociateShopperOrderExtTransaction(
      rowData,
      transactionData
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error)
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // adjust the fee so the table displays in dollars
      //response['deliveryFee'] = response['deliveryFee'] / 100
      const data = this.state.shopperOrders;
      const index = data.indexOf(rowData);
      // NOTE: This merge is important because during api updates the sharedList is not sent back so we need to keep the original data
      // first delete the transactions so we get the new list, then merge
      delete rowData["transactions"];
      delete rowData["transactionsList"];
      delete rowData["externalTransactions"];
      const mergedData = merge(rowData, response);
      data[index] = mergedData;
      this.setState({ data });
      // after getting back the new updated transactions list, refetch the individual transactions to create transactionsList
      this.shopperTransactionsList(mergedData);
    } // otherwise nothing to update
  };

  shopperTransactionsList = async (rowData) => {
    const index = rowData.tableData.id;
    const response = await API.get(
      "shoppers",
      `/shoppers/${rowData.shopperId}/orders/${rowData.id}/transactions`,
      {
        queryStringParameters: {
          // these are all required for transactions list
          "delivery-timestamp": rowData.deliveryTimestamp,
          "client-id": rowData.clientId,
          "order-timestamp": rowData.orderTimestamp,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      const newShopperOrders = [...this.state.shopperOrders];
      newShopperOrders[index]["transactionsList"] = response;
      this.setState({
        shopperOrders: newShopperOrders,
      });
    }
  };

  shopperOrderSharedList = async (rowData) => {
    const index = rowData.tableData.id;
    // pull out the bits needed to fetch the shared list
    if ("sharedListIdentifiers" in rowData) {
      if (rowData["sharedListIdentifiers"].length > 0) {
        // currently there should only be one shared list
        const sharedList = rowData["sharedListIdentifiers"][0];
        const listId = sharedList["listId"];
        const snapshotId =
          "snapshotId" in sharedList ? sharedList["snapshotId"] : "0";
        const listOwnerId = sharedList["owner"];

        const response = await API.get(
          "shoppers",
          `/shoppers/${rowData.shopperId}/orders/${rowData.id}/sharedlist/${listId}`,
          {
            queryStringParameters: {
              "list-owner-id": listOwnerId,
              "snapshot-id": snapshotId,
            },
          }
        )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            alert("Error! " + error.response["data"]);
            return null;
          });
        // it's okay if response is empty, that just means no items
        // TODO - it may be better to just send back the items instead of injecting them like this.
        const newShopperOrders = [...this.state.shopperOrders];
        // insert the shared list where we prevously had embedded in the order list call
        newShopperOrders[index]["sharedListIdentifiers"][0]["items"] = response;
        this.setState({
          shopperOrders: newShopperOrders,
        });
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  shopperOrderNotesList = async (rowData) => {
    const index = rowData.tableData.id;
    const response = await API.get(
      "shoppers",
      `/shoppers/${rowData.shopperId}/orders/${rowData.id}/notes`
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      const newShopperOrders = [...this.state.shopperOrders];
      newShopperOrders[index]["notesList"] = response;
      this.setState({
        shopperOrders: newShopperOrders,
      });
    }
  };

  deactivateShopper = async () => {
    const response = await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}/deactivate`,
      {
        queryStringParameters: {
          phone: this.state.shopper["phone"],
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const shopper = this.state.shopper;
      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
    }
  };

  activateShopper = async () => {
    const response = await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}/activate`,
      {
        queryStringParameters: {
          phone: this.state.shopper["phone"],
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const shopper = this.state.shopper;
      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
    }
  };

  logoutShopper = async () => {
    const response = await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}/logout`,
      {
        queryStringParameters: {
          phone: this.state.shopper["phone"],
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      let status = response["status"]
      if (status) {
        alert("Shopper has been sucessfully logged out.")
      } else {
        alert("There was an error logging out the shopper. Please contact an administrator.")
      }
    }
  };

  optInShopperPhone = async () => {
    const response = await API.patch(
      "shoppers",
      `/sns/phone/${this.state.shopper.phone}/opt-in`,
      {}
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      this.setState({
        shopperPhoneIsOptedOut: response.isOptedOut, // note the api always send this back as false
      });
    }
  };

  removeZipcodeSearchShopper = async () => {
    // only send the parts we want to update (we are only doing ignore for now)
    var body = {};

    body["kickedOutZipcode"] = true;

    const response = await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const shopper = this.state.shopper;
      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
    }
  };


  unremoveZipcodeSearchShopper = async () => {
    // only send the parts we want to update (we are only doing ignore for now)
    var body = {};

    body["kickedOutZipcode"] = false;

    const response = await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const shopper = this.state.shopper;
      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
    }
  };

  optInOutSearchShopper = async (optIn) => {
    // only send the parts we want to update (we are only doing ignore for now)
    var body = {};

    body["optInZipcode"] = optIn;

    const response = await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const shopper = this.state.shopper;
      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
    }
  };

  unpauseShopperUntil = async (unpauseUntilTimestamp) => {
    // only send the parts we want to update (we are only doing ignore for now)
    var body = {};
    // convert to string to match how timestamps are stored in dynamo
    body["unpausedByOperatorUntilTimestamp"] = unpauseUntilTimestamp.toString();

    const response = await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const shopper = this.state.shopper;
      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
    }
  };

  saveInstantPayoutEnabled = async (checked) => {
    var body = {"instantPayoutEnabled": checked};

    const response = await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const shopper = this.state.shopper;
      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
    }
  };

  saveExternalTransactionsEnabled = async (checked) => {
    var body = {"externalTransactionsEnabled": checked};

    const response = await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const shopper = this.state.shopper;
      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
    }
  };

  saveResortClosedDatesEnabled = async (checked) => {
    var body = {"resortClosedDatesEnabled": checked};

    const response = await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const shopper = this.state.shopper;
      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
    }
  };

  saveHasBetaAccess = async (checked) => {
    var body = {"hasBetaAccess": checked};

    const response = await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const shopper = this.state.shopper;
      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
    }
  };

  addMccToShopper = async (mccData) => {
    var body = {};

    if ("extraMcc" in mccData) {
      body["mcc"] = mccData.extraMcc;
    }

    if (
      "expiresAt" in mccData &&
      mccData.expiresAt != "" &&
      mccData.expiresAt != null
    ) {
      var currentTime = parseInt(+new Date() / 1000);

      if (mccData.expiresAt == "1d") {
        mccData.expiresAt = currentTime + 24 * 60 * 60;
      } else if (mccData.expiresAt == "1w") {
        mccData.expiresAt = currentTime + 7 * 24 * 60 * 60;
      } else if (mccData.expiresAt == "1h") {
        mccData.expiresAt = currentTime + 60 * 60;
      } else {
        mccData.expiresAt = null;
      }

      body["expiresAt"] = mccData.expiresAt;
    } else {
      body["expiresAt"] = null;
    }

    const response = await API.post(
      "shoppers",
      `/shoppers/${this.state.shopper.id}/extra-mcc`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });

    if (!isEmpty(response)) {
      const shopper = this.state.shopper;
      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
      return true; // the update was successful
    } else {
      return false; // the update was unsuccesful (so we don't close the popover)
    }
  };

  deleteMccFromShopper = async (mcc) => {
    var body = {};

    if (mcc == null || mcc === "" || mcc.length !== 4) {
      return;
    }

    const response = await API.del(
      "shoppers",
      `/shoppers/${this.state.shopper.id}/extra-mcc`,
      {
        body: { mcc },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });

    if (!isEmpty(response)) {
      let shopper = this.state.shopper;

      // `merge` doesn't handle delete.
      delete shopper["extraMccs"];

      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
      return true; // the update was successful
    } else {
      return false; // the update was unsuccesful (so we don't close the popover)
    }
  };

  addUserRemappingToShopper = async (userRemapping) => {
    var body = {};

    if ("toId" in userRemapping) {
      body["toId"] = userRemapping.toId;
    }
    if (
      "expiresAt" in userRemapping &&
      userRemapping.expiresAt != "" &&
      userRemapping.expiresAt != null
    ) {
      var currentTime = parseInt(+new Date() / 1000);

      if (userRemapping.expiresAt == "1d") {
        userRemapping.expiresAt = (currentTime + 24 * 60 * 60).toString();
      } else if (userRemapping.expiresAt == "1w") {
        userRemapping.expiresAt = (currentTime + 7 * 24 * 60 * 60).toString();
      } else if (userRemapping.expiresAt == "1h") {
        userRemapping.expiresAt = (currentTime + 60 * 60).toString();
      } else {
        userRemapping.expiresAt = null;
      }

      body["expiresAt"] = userRemapping.expiresAt;
    } else {
      body["expiresAt"] = null;
    }

    const response = await API.post(
      "shoppers",
      `/shoppers/${this.state.shopper.id}/user-remapping`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });

    if (!isEmpty(response)) {
      this.setState({
        shopperUserRemapping: [response],
      });
      return true; // the update was successful
    } else {
      return false; // the update was unsuccesful (so we don't close the popover)
    }
  };

  deleteUserRemappingFromShopper = async (toId) => {
    var body = {};

    if (toId == null || toId === "") {
      return;
    }

    const response = await API.del(
      "shoppers",
      `/shoppers/${this.state.shopper.id}/user-remapping`,
      {
        body: { toId }
      }
    )
      .then((response) => {
        // the response is empty, but set it to something so we know the delete went through
        return {'isDeleted': true};
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });

    if (!isEmpty(response)) {
      this.setState({
        shopperUserRemapping: []
      });
      return true; // the update was successful
    } else {
      return false; // the update was unsuccesful (so we don't close the popover)
    }
  };

  deleteUnpauseUntilFromShopper = async (unpauseUntil) => {
    var body = {};

    // Just blanking it out
    // TODO - look into checking for a match before removing
    body["unpausedByOperatorUntilTimestamp"] = "";

    const response = await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });

    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const shopper = this.state.shopper;
      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
    }
  };

  addSyntheticTransaction = async (transaction) => {
    var body = {};

    body = {
      cardAcctId: transaction.cardAcctId,
      transactionAmount: Math.round(
        parseFloat(transaction.transactionAmount) * 100.0
      ),
      merchantName: transaction.merchantName,
      merchantCity: transaction.merchantCity,
      merchantState: transaction.merchantState,
      merchantZip: transaction.merchantZip,
      merchantCountry: "US",
    };

    const response = await API.post(
      "shoppers",
      `/shoppers/${this.state.shopper.id}/synthetic-transaction`,
      {
        body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error?.response["data"]);
        return null;
      });

    if (!isEmpty(response)) {
      this.shopperDanglingTransactionsList();

      return true; // the update was successful
    } else {
      return false; // the update was unsuccesful (so we don't close the popover)
    }
  };

  updateFundingShopper = async (fundingData) => {
    var params = { queryStringParameters: {} };
    if ("fundingAmount" in fundingData) {
      params.queryStringParameters["funding-amount"] = parseInt(
        fundingData.fundingAmount.replace(".", ""),
        10
      ); // remove the decimal point and convert to int of cents
    }
    if ("fundingProfile" in fundingData) {
      params.queryStringParameters["funding-profile"] =
        fundingData.fundingProfile;
    }
    if ("transactionLimit" in fundingData) {
      params.queryStringParameters["transaction-limit"] = parseInt(
        fundingData.transactionLimit.replace(".", ""),
        10
      ); // remove the decimal point and convert to int of cents
    }

    const response = await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}/funding`,
      params
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const shopper = this.state.shopper;
      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
      return true; // the update was successful
    } else {
      return false; // the update was unsuccesful (so we don't close the popover)
    }
  };

  changePhoneShopper = async (phoneData) => {
    var params = { queryStringParameters: {} };
    if ("phone" in phoneData) {
      params.queryStringParameters["new-phone"] = "+1" + phoneData.phone; // the phone should be coming in as a ten digit string from the form
      params.queryStringParameters["old-phone"] = this.state.shopper["phone"];
    }

    const response = await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}/phone`,
      params
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const shopper = this.state.shopper;
      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
      return true; // the update was successful
    } else {
      return false; // the update was unsuccesful (so we don't close the popover)
    }
  };

  updateSubscriptionPlanShopper = async (subscriptionData) => {
    var params = { queryStringParameters: {} };
    if ("subscriptionPlan" in subscriptionData) {
      params.queryStringParameters["subscription-plan"] =
        subscriptionData.subscriptionPlan;
    }

    const response = await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}/subscription`,
      params
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the subscriptionPlan field
      // TODO add what we need
      const shopper = this.state.shopper;
      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
      return true; // the update was successful
    } else {
      return false; // the update was unsuccesful (so we don't close the popover)
    }
  };

  addNoteShopper = async (noteData) => {
    const user = await Auth.currentAuthenticatedUser();
    const { attributes, username } = user;

    // only send the parts we want to update
    var body = {};

    body["note"] = noteData.shopperNote;
    body["coach"] = noteData.coach;
    body["date"] = noteData.date;
    body["noteType"] = noteData.noteType;

    // add in the bao operator info since I'm not sure how to do it on the backend yet
    body["baoUserId"] = username; // this used to be attributes.sub but in the new pool we are using username (which is userId)
    body["baoFirstName"] = attributes.given_name;
    body["baoLastName"] = attributes.family_name;
    body["baoEmail"] = attributes.email;

    const response = await API.post(
      "shoppers",
      `/shoppers/${this.state.shopper.id}/notes`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      var shopperNotes = this.state.shopperNotes;
      if (shopperNotes) {
        shopperNotes.unshift(response); // add to beginning of array as we are showing recent dates first
      } else {
        shopperNotes = [response];
      }
      this.setState({
        shopperNotes: shopperNotes,
      });
      return true; // the update was successful
    } else {
      return false; // the update was unsuccesful (so we don't close the popover)
    }
  };

  updateShopperNote = async (noteId, noteData) => {
    // only send the parts we want to update
    var body = {};

    body["pinned"] = noteData.pinned;

    await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}/notes/${noteId}`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });

    await this.getShopperNotes();

    return true; // the update was successful
  };

  updateShopperWebPath = async (webPathData) => {
    var params = { queryStringParameters: {} };
    params.queryStringParameters["web-path"] = webPathData.webPath;

    const response = await API.patch(
      "shoppers",
      `/shoppers/${this.state.shopper.id}/webpath`,
      params
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const shopper = this.state.shopper;
      const mergedData = merge(shopper, response);
      this.setState({
        shopper: mergedData,
      });
      return true; // the update was successful
    } else {
      return false; // the update was unsuccesful (so we don't close the popover)
    }
  };

  addNoteShopperOrder = async (rowData, noteData) => {
    const user = await Auth.currentAuthenticatedUser();
    const { attributes } = user;
    const { id } = rowData; // this is the orderId

    // only send the parts we want to update
    var body = {};

    body["note"] = noteData.shopperOrderNote;
    // add in the bao operator info since I'm not sure how to do it on the backend yet
    body["baoUserId"] = attributes.sub;
    body["baoFirstName"] = attributes.given_name;
    body["baoLastName"] = attributes.family_name;

    const response = await API.post(
      "shoppers",
      `/shoppers/${this.state.shopper.id}/orders/${id}/notes`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      const shopperOrders = this.state.shopperOrders;
      const index = shopperOrders.indexOf(rowData);
      // see if this is the first note being added
      if ("notesList" in rowData) {
        const prevNotesList = rowData["notesList"];
        prevNotesList.unshift(response); // add to beginning of array as we are showing recent dates first
        // overwrite the existing list with the updated one
        rowData["notesList"] = prevNotesList;
      } else {
        // create it for the first time
        rowData["notesList"] = [response];
      }
      shopperOrders[index] = rowData;
      this.setState({ shopperOrders });
      return true; // the update was successful
    } else {
      return false; // the update was unsuccesful (so we don't close the popover)
    }
  };

  // force set the ignore bool and add in a status field
  fixTransaction = (transaction) => {
    // the transaction may or may not have an ignore boolean.  if it does not, make one and set it false
    if (!("ignore" in transaction)) {
      transaction["ignore"] = false;
    }
    // also set a status field that designates some as "dangling" if they are old but not ignored
    if (transaction.ignore) {
      transaction["status"] = "Ignore";
    } else {
      // see how old it is
      const transactionUtcTime = new Date(transaction.transactionUtcTime);
      const nowTime = Date.now();
      const diff = new moment.duration(nowTime - transactionUtcTime);
      // if it's greater than 4 hours it is "dangling"
      if (diff.asHours() > 4.0) {
        transaction["status"] = "Dangling";
      } else {
        transaction["status"] = "Open";
      }
    }
    return transaction;
  };

  shopperDanglingTransactionsList = async () => {
    const response = await API.get(
      "shoppers",
      `/shoppers/${this.props.match.params.id}/dangling-transactions`,
      {
        // 'queryStringParameters': {
        //   // these are all required for transactions list
        //   'card-acct-ids': this.state.shopperCards['cardAcctIds']  // TODO sending this is not working
        // }
      }
    );
    if (!isEmpty(response)) {
      const fixedResponse = response.map((transaction) => {
        // force set the ignore bool and add in a status field
        return this.fixTransaction(transaction);
      });
      this.setState({
        shopperDanglingTransactions: fixedResponse,
      });
    }
  };

  shopperDeclinedTransactionsList = async () => {
    const response = await API.get(
      "shoppers",
      `/shoppers/${this.props.match.params.id}/declined-transactions`,
      {
        queryStringParameters: {
          //'card-acct-ids': this.state.shopperCards['cardAcctIds']  // TODO sending this is not working
          "hours-ago": 12,
        },
      }
    );

    if (!isEmpty(response)) {
      // const fixedResponse = response.map(transaction => {
      //   // force set the ignore bool and add in a status field
      //   return this.fixTransaction(transaction)
      // })
      this.setState({
        shopperDeclinedTransactions: response,
      });
    }
  };

  shopperTransactionIgnore = async (transactionData) => {
    // set the ignore bit to the opposite of what it was or true if it didn't exist (it should now always exist since we are setting when creating the list)
    var ignore = true;
    if ("ignore" in transactionData) {
      ignore = !transactionData.ignore;
    }
    transactionData["ignore"] = ignore;

    const response = await this.updateShopperTransaction(transactionData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      const transaction = this.fixTransaction(response);
      const data = this.state.shopperDanglingTransactions;
      const index = data.indexOf(transactionData);
      const mergedData = merge(transactionData, transaction);
      data[index] = mergedData;

      // now refetch shopper since it will have changed
      try {
        const shopper = await this.getShopper();
        this.setState({ shopper });
      } catch (e) {
        alert(e);
      }
    } // otherwise nothing to update
  };

  createStripeVerifyAcctLink = async () => {
    const response = await API.get(
      "shoppers",
      `/shoppers/${this.state.shopper.id}/stripe-acct-verify-link`,
      {
        queryStringParameters: {
          "stripe-acct-id": this.state.shopper["stripeConnectAcctId"],
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      return response;
    }
  };

  render() {
    const { classes } = this.props;
    const { confirmReopenOpen } = this.state;

    return (
      <div className="notes">
        {this.state.shopper ? (
          <Paper className={classes.root}>
            <ShopperDetail
              shopper={this.state.shopper}
              shopperCards={this.state.shopperCards}
              shopperNotes={this.state.shopperNotes}
              shopperNotesLoading={this.state.shopperNotesLoading}
              shopperCardsLoading={this.state.shopperCardsLoading}
              shopperPhoneIsOptedOut={this.state.shopperPhoneIsOptedOut}
              shopperOnboardingStatus={this.state.shopperOnboardingStatus}
              shopperUserRemapping={this.state.shopperUserRemapping}
              optInPhone={this.optInShopperPhone}
              updateSubscriptionPlan={this.updateSubscriptionPlanShopper}
              deactivate={this.deactivateShopper}
              activate={this.activateShopper}
              removeZipcodeSearch={this.removeZipcodeSearchShopper}
              unremoveZipcodeSearch={this.unremoveZipcodeSearchShopper}
              optInOutSearch={this.optInOutSearchShopper}
              updateFunding={this.updateFundingShopper}
              changePhone={this.changePhoneShopper}
              addShopperNote={this.addNoteShopper}
              updateShopperWebpath={this.updateShopperWebPath}
              createStripeVerifyAcctLink={this.createStripeVerifyAcctLink}
              addMccToShopper={this.addMccToShopper}
              deleteMccFromShopper={this.deleteMccFromShopper}
              addUserRemappingToShopper={this.addUserRemappingToShopper}
              deleteUserRemappingFromShopper={this.deleteUserRemappingFromShopper}
              shopperNoteUpdate={this.updateShopperNote}
              addSyntheticTransaction={this.addSyntheticTransaction}
              saveInstantPayoutEnabled={this.saveInstantPayoutEnabled}
              saveExternalTransactionsEnabled={this.saveExternalTransactionsEnabled}
              saveResortClosedDatesEnabled={this.saveResortClosedDatesEnabled}
              saveHasBetaAccess={this.saveHasBetaAccess}
              unpauseShopperUntil={this.unpauseShopperUntil}
              deleteUnpauseUntilFromShopper={this.deleteUnpauseUntilFromShopper}
              logoutShopper={this.logoutShopper}
            />
          </Paper>
        ) : (
          <Paper className={classes.rootSpinner}>
            <CircularProgress />
          </Paper>
        )}
        <div className={classes.danglingTransactions}>
          {this.state.shopperDeclinedTransactions ? (
            <Paper>
              <ShopperDeclinedTransactionsTable
                transactions={this.state.shopperDeclinedTransactions}
              />
            </Paper>
          ) : null}
        </div>
        <div className={classes.danglingTransactions}>
          {this.state.shopperDanglingTransactions ? (
            <ShopperDanglingTransactionsTable2
              transactions={this.state.shopperDanglingTransactions}
              ignore={this.shopperTransactionIgnore}
            />
          ) : null}
        </div>
        {this.state.shopperOrders ? (
          <ShopperOrdersTable
            shopper={this.state.shopper}
            shopperOrders={this.state.shopperOrders}
            onReqTransaction={this.shopperTransactionsList}
            onReqNotes={this.shopperOrderNotesList}
            onReqSharedList={this.shopperOrderSharedList}
            addShopperOrderNote={this.addNoteShopperOrder}
            editField={this.shopperOrdersUpdate}
            reopen={this.shopperOrderReopen}
            cancel={this.shopperOrderCancel}
            move={this.shopperOrderMove}
            uncancel={this.shopperOrderUncancel}
            removeTransaction={this.shopperTransactionRemove}
            removeExtTransaction={this.shopperExtTransactionRemove}
          />
        ) : (
          <ShopperOrdersTable />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Shopper);
