import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import CouponTable from "./CouponTable";

import "../Home.css";

import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    marginBottom: theme.spacing(2),
  },
});

function Coupons(_props) {
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    setLoading(true);

    API.get("coupons", "/coupons")
      .then((response) => {
        setCoupons(response);
      })
      //.catch(() => alert("Could not load coupons!"))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div>
      <CouponTable coupons={coupons} loading={loading} />
    </div>
  );
}

export default withStyles(styles)(Coupons);
