import React, { useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";

const styles = (_theme) => ({
  root: {
    flexGrow: 1,
  },
  buttonProgress: {
    //color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -10,
    marginLeft: -12,
  },
  farRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "baseline",
    paddingTop: 10,
  },
});

function ClientNotesPopoverForm(props) {
  const { classes, label, initialValues, submitFunc, name } = props;
  const [loading, setLoading] = useState(false);

  async function onSubmit(values) {
    setLoading(true);
    await submitFunc(values);
    setLoading(false);
  }

  async function validate(values) {
    // TODO - check if they actually changed something
    var errors = {};

    if (!(name in values) || values[name] === null) {
      errors[name] = "Please enter something.";
    }

    return errors;
  }

  return (
    <>
      {/* <Typography>The content of the Popover.</Typography> */}
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit }) => (
          <form
            className={classes.root}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <TextField
              disabled={loading}
              multiline={true}
              style={{ width: 480 }}
              label={label}
              name={name}
            />
            <div className={classes.farRight}>
              <Button disabled={loading} type="submit" color="primary">
                Submit
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </div>
          </form>
        )}
      />
    </>
  );
}

export default withStyles(styles)(ClientNotesPopoverForm);
