import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Tooltip from "@material-ui/core/Tooltip";
import NumberFormat from "react-number-format";

const BoldTableCell = withStyles((theme) => ({
  body: {
    fontWeight: 500,
  },
}))(TableCell);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  tableTotal: {
    color: "red",
  },
  sideBySide: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const formatter = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function OrderPricingDialog(props) {
  const [open, setOpen] = React.useState(false);
  const { rowData, classes } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function renderPricingModel() {
    function row(title, value) {
      return (
        <TableRow key={title}>
          <TableCell component="th" scope="row">
            {title}
          </TableCell>
          <TableCell align="right">{value}</TableCell>
        </TableRow>
      );
    }
    function preTipPm() {
      const pretip = rowData.pricingModel?.preTipPm;
      if (pretip) {
        if (pretip.chosenPercent && pretip.chosenPercent != "0") {
          return row("Pre Tip", `${pretip.chosenPercent}%`);
        } else if (
          pretip.chosenFixedGratuity &&
          pretip.chosenFixedGratuity != 0
        ) {
          return row(
            "Pre Tip",
            `${formatter.format(pretip.chosenFixedGratuity / 100.0)}`
          );
        }
      }
    }

    function flatPm() {
      const flatpm = rowData.pricingModel?.flatPm;
      if (flatpm) {
        return row(
          "Service Fee (Flat)",
          `${formatter.format(flatpm.fee / 100.0)}`
        );
      }
    }

    function percentPm() {
      const percentpm = rowData.pricingModel?.percentPm;
      if (percentpm) {
        return row(
          "Service Fee (%)",
          `${percentpm.percent}%   (${formatter.format(
            percentpm.minFee / 100.0
          )} minimum)`
        );
      }
    }

    function clientPlatformPm() {
      const clientppm = rowData.pricingModel?.clientPlatformPm;
      if (clientppm) {
        let value = "";

        if (clientppm.flatFee && clientppm.flatFee != 0) {
          value += `Fixed: ${formatter.format(clientppm.flatFee / 100.0)}  `;
        }

        if (clientppm.percentOnLoan && clientppm.percentOnLoan != "0") {
          value += `On Loan: ${clientppm.percentOnLoan}%  `;
        }

        if (clientppm.percentOnTotal && clientppm.percentOnTotal != "0") {
          value += `On Total: ${clientppm.percentOnTotal}%  `;
        }

        return row("Platform Fee (Client)", value);
      }
    }

    function shopperPlatformPm() {
      const shopperppm = rowData.pricingModel?.shopperPlatformPm;

      if (shopperppm) {
        let value = "";

        if (shopperppm.flatFee && shopperppm.flatFee != 0) {
          value += `Fixed: ${formatter.format(shopperppm.flatFee / 100.0)}  `;
        }

        if (shopperppm.percentOnLoan && shopperppm.percentOnLoan != "0") {
          value += `On Loan: ${shopperppm.percentOnLoan}%  `;
        }

        if (shopperppm.percentOnTotal && shopperppm.percentOnTotal != "0") {
          value += `On Total: ${shopperppm.percentOnTotal}%  `;
        }

        return row("Platform Fee (Shopper)", value);
      }
    }

    function dumplingPm() {
      const dumplingpm = rowData.pricingModel?.dumplingPm;
      if (dumplingpm) {
        let value = "";

        if (dumplingpm.flatFee && dumplingpm.flatFee != 0) {
          value += `Fixed: ${formatter.format(dumplingpm.flatFee / 100.0)}  `;
        }

        if (dumplingpm.percentOnLoan && dumplingpm.percentOnLoan != "0") {
          value += `On Loan: ${dumplingpm.percentOnLoan}%  `;
        }

        if (dumplingpm.percentOnTotal && dumplingpm.percentOnTotal != "0") {
          value += `On Total: ${dumplingpm.percentOnTotal}%  `;
        }

        if (
          dumplingpm.percentOnTotalMinusPlatform &&
          dumplingpm.percentOnTotalMinusPlatform != "0"
        ) {
          value += `On Total (minus platform): ${dumplingpm.percentOnTotalMinusPlatform}%  `;
        }

        if (
          dumplingpm.percentOnEarningsBeforeCcFee &&
          dumplingpm.percentOnEarningsBeforeCcFee != "0"
        ) {
          value += `On earnings (before cc): ${dumplingpm.percentOnEarningsBeforeCcFee}%  `;
        }

        return row("Dumpling Fee", value);
      }
    }

    return (
      <div className={classes.sideBySide}>
        <TableContainer component={Paper} style={{ marginRight: 20 }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  Pricing Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {preTipPm()}
              {flatPm()}
              {percentPm()}
              {clientPlatformPm()}
              {shopperPlatformPm()}
              {dumplingPm()}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  function renderPriceBreakdown() {
    return (
      <div className={classes.sideBySide}>
        <TableContainer component={Paper} style={{ marginRight: 20 }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  {rowData.priceBreakdown.title}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.priceBreakdown.lineItems.map((row) => (
                <TableRow key={row.key}>
                  <TableCell component="th" scope="row">
                    {row.key.includes("Adjustment") ? (
                      <Tooltip
                        title={row.key.split(/\|(.+)/)[1]}
                        placement="bottom-start"
                        arrow
                      >
                        <span
                          style={{ cursor: "pointer", fontStyle: "italic" }}
                        >
                          {row.key.split("|")[0]}
                        </span>
                      </Tooltip>
                    ) : (
                      row.key
                    )}
                  </TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                </TableRow>
              ))}
              <TableRow key={rowData.priceBreakdown.final.key}>
                <BoldTableCell
                  component="th"
                  scope="row"
                  classes={styles.tableTotal}
                >
                  {rowData.priceBreakdown.final.key}
                </BoldTableCell>
                <BoldTableCell align="right">
                  {rowData.priceBreakdown.final.value}
                </BoldTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  {rowData.earningsBreakdown.title}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.earningsBreakdown.lineItems.map((row) => (
                <TableRow key={row.key}>
                  <TableCell component="th" scope="row">
                    {row.key.includes("Adjustment") ? (
                      <Tooltip
                        title={row.key.split(/\|(.+)/)[1]}
                        placement="bottom-start"
                        arrow
                      >
                        <span
                          style={{ cursor: "pointer", fontStyle: "italic" }}
                        >
                          {row.key.split("|")[0]}
                        </span>
                      </Tooltip>
                    ) : (
                      row.key
                    )}
                  </TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                </TableRow>
              ))}
              <TableRow key={rowData.earningsBreakdown.final.key}>
                <BoldTableCell
                  component="th"
                  scope="row"
                  classes={styles.tableTotal}
                >
                  {rowData.earningsBreakdown.final.key}
                </BoldTableCell>
                <BoldTableCell align="right">
                  {rowData.earningsBreakdown.final.value}
                </BoldTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return (
    <div>
      {rowData.priceBreakdown ? (
        <Button
          aria-label="money"
          onClick={handleClickOpen}
          variant="outlined"
          color="primary"
        >
          <NumberFormat
            value={rowData.priceBreakdown.final.value}
            displayType={"text"}
            thousandSeparator={true}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={"$"}
          />
        </Button>
      ) : rowData.pricingModel ? (
        <div>
          <IconButton aria-label="money" onClick={handleClickOpen}>
            <AttachMoneyIcon />
          </IconButton>
        </div>
      ) : (
        "N/A"
      )}
      <Dialog
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Order Pricing
        </DialogTitle>
        <DialogContent dividers>
          {rowData.priceBreakdown
            ? renderPriceBreakdown()
            : renderPricingModel()}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(OrderPricingDialog);
