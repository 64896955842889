import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  buttonProgress: {
    //color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: 24,
    marginLeft: -60,
  },
  sideBySide: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  }
})


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      format="###-###-####"
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,  // we are using the string representation to avoid float multiplication issues
          },
        });
      }}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function PhonePopoverForm(props) {
  const {classes, label, initialValues, submitFunc, name} = props
  const [loading, setLoading] = React.useState(false)
  //const [openDialog, setOpenDialog] = React.useState(false)

  async function onSubmit(values) {
    // check for 10 digits
    if (values[name].length != 10) {
      return { [name]: 'A phone number must contain 10 digits.' };
    }

    if (window.confirm("PLEASE MAKE SURE THE USER IS SIGNED OUT OF THE APP BEFORE MAKING THIS CHANGE. THEY NEED TO BE SIGNED OUT, THEY CAN'T JUST KILL THE APP, THEY NEED TO BE SIGNED OUT.")) {
      setLoading(true)
      await submitFunc(values)
      setLoading(false)
    }
  }

  async function validate(values) {
    // TODO - check if they actually changed something

    if (!(name in values) || values[name] === null) {  // note, we want to let zeros through but not null
      return { [name]: 'Please enter something.' };
    }

    return;
  }
  
  return ( 
    <>
      {/* <Typography>The content of the Popover.</Typography> */}
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, values }) => (
          <form className={classes.root} onSubmit={handleSubmit} noValidate autoComplete="off">
            <>
              <TextField disabled={loading} style={{ width: 160 }} label={label} name={name} InputProps={{ inputComponent: NumberFormatCustom, endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                </React.Fragment>
              )}} />
              <Typography style={{paddingTop: 20, width: 160}} variant="body2">Change the login phone number.</Typography>
            </>
            {/* <pre>{JSON.stringify(values)}</pre> */}
          </form>
        )}
      />
    </>
  );
}

export default withStyles(styles)(PhonePopoverForm)
