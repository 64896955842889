import React from "react";
import OrderTable from "./OrderTable";

import "../Home.css";

import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    marginBottom: theme.spacing(2),
  },
});

function Orders(_props) {
  return (
    <div>
      <OrderTable />
    </div>
  );
}

export default withStyles(styles)(Orders);
