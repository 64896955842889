import React, { Component } from "react";
import Moment from 'react-moment';
import 'moment-timezone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import NotesPopoverFrom from './NotesPopoverForm.js'
import Linkify from 'react-linkify';

// TODO - define this globally or import
const linkifyComponentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
  </a>
);

const styles = theme => ({
  root: {
    width: '100%',
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
  },
  customItem: {
    color: 'black',
    fontWeight: 550
  },  
  greenButtonText: {
    fontSize: 15,
    fontWeight: 500,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: '#FFFFFF'
    }
  },
  sideBySide: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  sideBySideRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  }
})

class ShopperOrderNotesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveryValue: null,
      showLoading: false
    };
  }

  render() {
    const { rowData, classes, notesLoading } = this.props;
    const { notesList } = rowData

    var renderedNotesList = []
    if (notesList) {
      renderedNotesList = notesList.map((row, index) => (
        <TableRow key={row.id}>
          <TableCell component="th" scope="row">
            <Moment unix format="MMM DD, h:mm A z" tz="America/Los_Angeles">{Number(row.createdAtTimestamp)}</Moment>{' - '}
            <span style={{fontWeight: 500}}>{row.baoFirstName}{' '}{row.baoLastName}</span>
            {' - '}
            <Linkify componentDecorator={linkifyComponentDecorator}>{row.note}</Linkify>
          </TableCell>
        </TableRow>
      ))
    } else {
      renderedNotesList = null
    }
 
    return (
      <div style={{ width: "100%" }}>    
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <div className={classes.sideBySide}>
                  <div className={classes.sideBySideRow}>
                    Notes
                  </div>
                  <div>
                    <PopupState variant="popover" popupId="demo-popup-popover">
                      {(popupState) => (
                        <div className={classes.sideBySideRow}>
                          <div onClick={e => {e.stopPropagation();}}>
                            <Typography {...bindTrigger(popupState)} displayType={'text'} className={classes.greenButtonText} style={{cursor:'pointer', width: 80}}>Add Note</Typography>
                          </div>
                          <Popover
                            onClick={e => {e.stopPropagation();}}
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <Box p={2}>
                              <NotesPopoverFrom submitFunc={async (values) => {
                                const response = await this.props.addShopperOrderNote(rowData, values)
                                if (response) {
                                  popupState.close()  // update was succesful, close the popover
                                }
                              }} name="shopperOrderNote" label="Order Note" initialValues={{"shopperOrderNote": null}}/>
                            </Box>
                          </Popover>
                        </div>
                      )}
                    </PopupState>
                  </div>
                </div>
                {notesLoading ? <LinearProgress /> : null}
              </TableCell>
            </TableRow>
          </TableHead> 
          <TableBody>
            {renderedNotesList}
          </TableBody>
        </Table> 
      </div>
    );
  }
}

export default withStyles(styles)(ShopperOrderNotesTable);
