import React, { Component } from "react";
import NumberFormat from "react-number-format";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Moment from "react-moment";
import PhonePopoverForm from "./PhonePopoverForm.js";
import ClientNotesTable from "./ClientNotesTable";
import ClientNote from "./ClientNote";
import ClientNotesPopoverForm from "./ClientNotesPopoverForm";
import UserRemappingPopoverForm from "./UserRemappingPopoverForm.js";
import Badge from "@material-ui/core/Badge";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  // client summary
  bigAvatar: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 0,
    width: 100,
    height: 100,
  },
  chip: {
    margin: theme.spacing(1),
  },
  errorChip: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
  },
  header: {
    color: theme.palette.text.secondary,
  },
  // Expansitoin
  expansionRoot: {
    width: "100%",
  },
  expansionRootNotes: {
    width: "100%",
    paddingTop: 10,
  },
  summary: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  deactivateButton: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  },
  buttonWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: red[200],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  notesDetails: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  column: {
    flexBasis: "33.33%",
    paddingRight: theme.spacing(2),
  },
  errorText: {
    color: theme.palette.error.main,
  },
  rowStyle: {
    display: "flex",
    flexDirection: "row",
  },
  inputButton: {
    fontSize: 16,
  },
  finalGrid: {
    paddingBottom: 6,
  },
  greenButtonText: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  sideBySide: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  sideBySideRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
});

class ClientDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deactivateButtonLoading: false,
      deleteButtonLoading: false,
      zipcodeSearchButtonLoading: false,
      optInButtonLoading: false,
      logoutButtonLoading: false
    };
  }

  renderClientAccountTypeChip() {
    const { classes, client } = this.props;
    if (client.deactivated) {
      return (
        <Chip
          size="small"
          label="DEACTIVATED"
          className={classes.errorChip}
          color="primary"
        />
      );
    } else if (client.acctType && client.acctType.toLowerCase() === "test") {
      return (
        <Chip
          size="small"
          label="TEST"
          className={classes.errorChip}
          color="primary"
        />
      );
    } else {
      return (
        <Chip
          size="small"
          label="STANDARD"
          className={classes.chip}
          color="primary"
        />
      );
    }
  }

  createIntercomLink() {
    const { client } = this.props;
    // construct the intercom link parameters and base64 encode as that is how their filter systmem works
    const parameters = `{"predicates":[{"attribute":"role","comparison":"eq","type":"role","value":"user_role"},{"attribute":"user_id","comparison":"eq","type":"string","value":"${client.id}"}]}`;
    const base64Parameters = btoa(parameters);
    // change the link based on if this is dev or prod
    const intercomId =
      process.env.REACT_APP_STAGE === "prod" ? "imrv9vmw" : "je157sua";
    const linkURL = `https://app.intercom.com/a/apps/${intercomId}/users/segments/all-users:${base64Parameters}`;
    return linkURL;
  }

  formattedUserRemapping(clientUserRemapping, classes, deleteUserRemappingFromClient) {
    function deleteUserRemappingWrapper(userId) {
      return function () {
        if (
          window.confirm(
            "Are you sure you want to remove this user remapping from this Client?"
          )
        ) {
          deleteUserRemappingFromClient(userId);
        }
      };
    }

    var currentTime = parseInt(+new Date() / 1000);

    return clientUserRemapping.map(function (userRemapping) {
      const type = userRemapping.type
      if (userRemapping.expiresAt == null) {
        return (
          <Badge
            badgeContent={(type && type.startsWith("BossBuddy") ? "Boss Buddy " : "") + "∞"}
            color="primary"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Button
              variant="outlined"
              color="primary"
              className={classes.mccCode}
              data-value={userRemapping.userId}
              onClick={deleteUserRemappingWrapper(userRemapping.userId)}
            >
              {userRemapping.userId}
            </Button>
          </Badge>
        );
      } else {
        const hours = (userRemapping.expiresAt - currentTime) / 60.0 / 60.0;
        if (hours < 24) {
          return (
            <Badge
              badgeContent={(type && type.startsWith("BossBuddy") ? "Boss Buddy " : "") + `${Math.round(hours)} h`}
              color="primary"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Button
                variant="outlined"
                color="primary"
                className={classes.mccCode}
                data-value={userRemapping.userId}
                onClick={deleteUserRemappingWrapper(userRemapping.userId)}
              >
                {userRemapping.userId}
              </Button>
            </Badge>
          );
        } else {
          return (
            <Badge
              badgeContent={`${Math.round(hours / 24)} d`}
              color="primary"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Button
                variant="outlined"
                color="primary"
                className={classes.mccCode}
                data-value={userRemapping.userId}
                onClick={deleteUserRemappingWrapper(userRemapping.userId)}
              >
                {userRemapping.userId}
              </Button>
            </Badge>
          );
        }
      }
    });
  }

  render() {
    const { classes, client, clientNotes, clientNotesLoading, clientUserRemapping } = this.props;

    var referralSource = "Dumpling app geosearch";
    switch (client.referralSource) {
      case "shoppage":
        referralSource = "Shopper webpage download";
        break;
      case "":
        referralSource = "Shopper invite";
        break;
      case undefined:
        referralSource = "Shopper invite";
        break;
    }

    const ExpansionPanelDetailsPadded = withStyles((theme) => ({
      root: {
        padding: theme.spacing(1, 8, 3, 3), // this is to compensate for the expansion icon on the right
      },
    }))(MuiExpansionPanelDetails);

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item>
            <Grid
              item
              xs={3}
              sm
              container
              direction="column"
              alignItems="center"
              justify="flex-start"
            >
              <Grid item xs>
                <Avatar
                  alt="Client Image"
                  src={client.image}
                  className={classes.bigAvatar}
                />
              </Grid>
              <Grid item xs>
                {this.renderClientAccountTypeChip()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container spacing={1}>
              <Grid item xs>
                <Grid
                  item
                  xs
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Typography gutterBottom variant="h5">
                      {client.firstName} {client.lastName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item xs>
                    <Grid
                      item
                      xs
                      container
                      direction="row"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item>
                        <Typography gutterBottom variant="body1">
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-popover"
                          >
                            {(popupState) => (
                              <div>
                                <span className={classes.header}>Phone</span>
                                &nbsp;&nbsp;
                                <NumberFormat
                                  {...bindTrigger(popupState)}
                                  className={classes.greenButtonText}
                                  style={{ cursor: "pointer" }}
                                  format="###-###-####"
                                  value={client.phone.slice(2)}
                                  displayType={"text"}
                                />
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <Box p={2}>
                                    <PhonePopoverForm
                                      submitFunc={async (values) => {
                                        const response =
                                          await this.props.changePhone(values);
                                        if (response) {
                                          popupState.close(); // update was succesful, close the popover
                                        }
                                      }}
                                      name="phone"
                                      label="Phone Number"
                                    />
                                  </Box>
                                </Popover>
                              </div>
                            )}
                          </PopupState>
                        </Typography>
                      </Grid>
                      <Grid item>
                        {this.props.clientPhoneIsOptedOut !== null ? (
                          <Typography variant="body1" gutterBottom>
                            {this.props.clientPhoneIsOptedOut ? (
                              <span className={classes.errorText}>
                                * Opted Out{" "}
                              </span>
                            ) : (
                              ""
                            )}
                          </Typography>
                        ) : (
                          <Typography variant="body1" gutterBottom></Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" gutterBottom>
                        <span className={classes.header}>Email</span>
                        &nbsp;&nbsp;
                        {client.email}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <span className={classes.header}>Id</span>
                        &nbsp;&nbsp;
                        {client.id}
                      </Typography>
                      <Grid
                        item
                        xs
                        container
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid item>
                          {client.stripeConnectAcctId && (
                            <Typography variant="body1" gutterBottom>
                              <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://dashboard.stripe.com/connect/accounts/${client.stripeConnectAcctId}`}
                              >
                                Stripe Connet
                              </Link>
                            </Typography>
                          )}
                        </Grid>
                        <Grid item>
                          {client.stripeConnectAcctId && (
                            <Typography variant="body1" gutterBottom>
                              <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://dashboard.stripe.com/search?query=${client.firstName}%20${client.lastName}`}
                              >
                                Stripe Search
                              </Link>
                            </Typography>
                          )}
                        </Grid>
                        <Grid item>
                          {client.id && (
                            <Typography variant="body1" gutterBottom>
                              <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                href={this.createIntercomLink()}
                              >
                                Intercom
                              </Link>
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.expansionRoot}>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              <div className={classes.column}>
                <Typography className={classes.summary}>
                  Profile Stats
                </Typography>
              </div>
              <div className={classes.column}>
                <Typography className={classes.summary}>
                  Delivery Address
                </Typography>
              </div>
              <div className={classes.column}>
                <Typography className={classes.summary}>
                  Account Actions
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetailsPadded className={classes.details}>
              <div className={classes.column}>
                <Grid container>
                  <Typography className={classes.secondaryHeading}>
                    Signup date
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography className={classes.heading}>
                    <Moment unix format="MMM DD, YYYY">
                      {Number(client.signupTimestamp)}
                    </Moment>
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography className={classes.secondaryHeading}>
                    Profile completed date
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography className={classes.heading}>
                    <Moment unix format="MMM DD, YYYY">
                      {Number(client.eventProfileCompleted)}
                    </Moment>
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography className={classes.secondaryHeading}>
                    App last opened time
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography className={classes.heading}>
                    <Moment unix format="MMM DD, YYYY">
                      {Number(client.eventAppLastOpened)}
                    </Moment>
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography className={classes.secondaryHeading}>
                    Has shopper?
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography className={classes.heading}>
                    {client.hasShopper ? "True" : "False"}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography className={classes.secondaryHeading}>
                    Referral Source
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography className={classes.heading}>
                    {referralSource}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography className={classes.secondaryHeading}>
                    Intercom email
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography className={classes.heading}>
                    {client.intercomEmail}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography className={classes.secondaryHeading}>
                    Phone opted out
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography className={classes.heading}>
                    {this.props.clientPhoneIsOptedOut ? "True" : "False"}
                  </Typography>
                </Grid>
              </div>
              <div className={classes.column}>
                <p>
                  <Typography className={classes.heading}>
                    {client.deliveryArea}
                  </Typography>
                </p>
              </div>
              <div className={classes.column}>
                <Grid container>
                  <div className={classes.buttonWrapper}>
                    {client.deactivated ? (
                      <Button
                        className={classes.deactivateButton}
                        color="default"
                        disabled={this.state.deactivateButtonLoading}
                        onClick={async () => {
                          if (
                            window.confirm(
                              "Are you sure you want to activate this client?  This will allow them access to the app."
                            )
                          ) {
                            this.setState({ deactivateButtonLoading: true });
                            await this.props.activate();
                            this.setState({ deactivateButtonLoading: false });
                          }
                        }}
                      >
                        Activate Client
                      </Button>
                    ) : (
                      <Button
                        className={classes.deactivateButton}
                        color="default"
                        disabled={this.state.deactivateButtonLoading}
                        onClick={async () => {
                          if (
                            window.confirm(
                              "Are you sure you want to deactivate this client?  This will prevent them from performing any actions in the app.  It should only be used for clients suspected of fraud.  It is not for clients wishing to 'delete' their account."
                            )
                          ) {
                            this.setState({ deactivateButtonLoading: true });
                            await this.props.deactivate();
                            this.setState({ deactivateButtonLoading: false });
                          }
                        }}
                      >
                        Deactivate Client (fraud suspect)
                      </Button>
                    )}
                    {this.state.deactivateButtonLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
                <Grid container>
                  <div className={classes.buttonWrapper}>
                    <Button
                      className={classes.deactivateButton}
                      color="default"
                      disabled={this.state.deleteButtonLoading}
                      onClick={async () => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this client?  This action is permanent and cannot be undone. They should not be logged into the app. Please double check their intercom account is deleted too, it will take a few mins to go away."
                          )
                        ) {
                          this.setState({ deleteButtonLoading: true });
                          await this.props.delete();
                          this.setState({ deleteButtonLoading: false });
                        }
                      }}
                    >
                      Delete Client (permanently)
                    </Button>
                    {this.state.deleteButtonLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
                <Grid container>
                  <div className={classes.buttonWrapper}>
                    <Button
                      className={classes.deactivateButton}
                      color="default"
                      disabled={this.state.logoutButtonLoading}
                      onClick={async () => {
                        if (
                          window.confirm(
                            "Are you sure you want to logout this client?  This log them out from all devices. NOTE! It will take 1 hour for the logout to occur."
                          )
                        ) {
                          this.setState({ logoutButtonLoading: true });
                          await this.props.logout();
                          this.setState({ logoutButtonLoading: false });
                        }
                      }}
                    >
                      Logout client
                    </Button>
                    {this.state.logoutButtonLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
                <Box className={classes.paper} style={{ float: "left" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        User Remapping
                      </Typography>
                      <Typography variant="body1">
                        <PopupState
                          variant="popover"
                          popupId="demo-popup-popover"
                        >
                          {(popupState) => (
                            <div>
                              <Button
                                //className={classes.inputButton}
                                color="primary"
                                {...bindTrigger(popupState)}
                              >
                                Add remapping
                              </Button>
                              <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              >
                                <Box p={2}>
                                  <UserRemappingPopoverForm
                                    submitFunc={async (values) => {
                                      const response =
                                        await this.props.addUserRemappingToClient(
                                          values
                                        );
                                      if (response) {
                                        popupState.close(); // update was succesful, close the popover
                                      }
                                    }}
                                    initialValues={{
                                      expiresAt: "1h",
                                    }}
                                  />
                                </Box>
                              </Popover>
                            </div>
                          )}
                        </PopupState>
                      </Typography>
                    </Grid>
                    <Grid item>
                      {this.formattedUserRemapping(
                        clientUserRemapping,
                        classes,
                        this.props.deleteUserRemappingFromClient
                      )}
                    </Grid>
                  </Grid>
                </Box>
                {this.props.clientPhoneIsOptedOut ? (
                  <Grid container>
                    <div className={classes.buttonWrapper}>
                      <Button
                        className={classes.deactivateButton}
                        color="default"
                        disabled={this.state.optInButtonLoading}
                        onClick={async () => {
                          if (
                            window.confirm(
                              "Only opt-in clients whom have indicated they are unable to receive SMS messages."
                            )
                          ) {
                            this.setState({ optInButtonLoading: true });
                            await this.props.optInPhone();
                            this.setState({ optInButtonLoading: false });
                          }
                        }}
                      >
                        Opt-In Phone Number
                      </Button>
                      {this.state.optInButtonLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </Grid>
                ) : null}
              </div>
            </ExpansionPanelDetailsPadded>
          </ExpansionPanel>
        </div>
        <div className={classes.expansionRootNotes}>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2c-content"
              id="panel2c-header"
            >
              <div className={classes.sideBySide}>
                <div className={classes.sideBySideRow}>
                  <Typography className={classes.summary}>Notes</Typography>
                  {clientNotes ? (
                    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                      <ClientNote note={clientNotes[0]} />
                    </div>
                  ) : clientNotesLoading ? (
                    <CircularProgress size={20} style={{ marginLeft: 24 }} />
                  ) : null}
                </div>
                <div>
                  <PopupState variant="popover" popupId="demo-popup-popover">
                    {(popupState) => (
                      <div className={classes.sideBySideRow}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Typography
                            {...bindTrigger(popupState)}
                            displayType={"text"}
                            className={classes.greenButtonText}
                            style={{ cursor: "pointer", width: 80 }}
                          >
                            Add Note
                          </Typography>
                        </div>
                        <Popover
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Box p={2}>
                            <ClientNotesPopoverForm
                              submitFunc={async (values) => {
                                const response = await this.props.addNoteClient(
                                  values
                                );
                                if (response) {
                                  popupState.close(); // update was succesful, close the popover
                                }
                              }}
                              name="clientNote"
                              label="Note"
                              initialValues={{ clientNote: null }}
                            />
                          </Box>
                        </Popover>
                      </div>
                    )}
                  </PopupState>
                </div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.notesDetails}>
              <ClientNotesTable
                notes={clientNotes}
                clientNoteUpdate={this.props.clientNoteUpdate}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ClientDetail);
