import React, { Component } from "react";
import "../Home.css";
import ClientsOrdersTable from "./ClientOrdersTable";
import ClientDetail from "./ClientDetail";
import { API } from "aws-amplify";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { merge } from "lodash";
import { Auth } from "aws-amplify";
import { withStyles } from "@material-ui/core/styles";

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    marginBottom: theme.spacing(2),
  },
  rootSpinner: {
    padding: theme.spacing(2, 2),
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  danglingTransactions: {
    paddingBottom: theme.spacing(2),
  },
});

class Client extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      client: null,
      clientOrders: null,
      clientPhoneIsOptedOut: null,
      clientNotes: null,
      clientUserRemapping: []
    };
  }

  async componentDidMount() {
    try {
      this.getClientOrders();
    } catch (e) {
      alert(e);
    }

    try {
      const client = await this.getClient();
      this.setState({ client }, () => {
        // this must be done after client is set as it depends on the phone number of a client
        try {
          this.checkClientPhoneOptOut();
        } catch (e) {
          alert(e);
        }
      });
    } catch (e) {
      alert(e);
    }

    try {
      this.getClientNotes();
    } catch (e) {
      alert(e);
    }

    try {
      this.getClientUserMapping();
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  getClient() {
    return API.get("clients", `/clients/${this.props.match.params.id}`);
  }

  getClientUserMapping = async () => {
    const response = await API.get(
      "clients",
      `/clients/${this.props.match.params.id}/user-remapping`
    );

    this.setState({
      clientUserRemapping: !isEmpty(response) ? [response] : [],
    });
  };

  getClientOrders = async () => {
    const response = await API.get(
      "clients",
      `/clients/${this.props.match.params.id}/orders`,
      {
        queryStringParameters: {
          "max-rows": "1000", // TODO: capping this at 500 so it loads faster and doesn't hit AWS API Gateway limit, need to add pagination
        },
      }
    );

    if (!isEmpty(response)) {
      const clientOrders = [];
      for (const clientOrder of response) {
        // adjust the fee so the table displays in dollars
        //shopperOrder['deliveryFee'] = shopperOrder['deliveryFee'] / 100
        clientOrders.push(clientOrder);
      }
      this.setState({
        clientOrders: clientOrders,
      });
    } else {
      this.setState({
        clientOrders: null,
      });
    }
    //alert(JSON.stringify(response, null, 2));
    //console.log(JSON.stringify(response, null, 2))
  };

  getClientNotes = async () => {
    this.setState({ clientNotesLoading: true });
    const response = await API.get(
      "clients",
      `/clients/${this.props.match.params.id}/notes`
    );
    this.setState({ clientNotesLoading: false });

    if (!isEmpty(response)) {
      this.setState({
        clientNotes: response,
      });
    } else {
      this.setState({
        clientNotes: null,
      });
    }
  };

  deactivateClient = async () => {
    const response = await API.patch(
      "clients",
      `/clients/${this.props.match.params.id}/deactivate`,
      {
        queryStringParameters: {
          phone: this.state.client["phone"],
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const client = this.state.client;
      const mergedData = merge(client, response);
      this.setState({
        client: mergedData,
      });
    }
  };

  activateClient = async () => {
    const response = await API.patch(
      "clients",
      `/clients/${this.props.match.params.id}/activate`,
      {
        queryStringParameters: {
          phone: this.state.client["phone"],
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a shopper doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const client = this.state.client;
      const mergedData = merge(client, response);
      this.setState({
        client: mergedData,
      });
    }
  };

  addUserRemappingToClient = async (userRemapping) => {
    var body = {};

    if ("toId" in userRemapping) {
      body["toId"] = userRemapping.toId;
    }
    if (
      "expiresAt" in userRemapping &&
      userRemapping.expiresAt != "" &&
      userRemapping.expiresAt != null
    ) {
      var currentTime = parseInt(+new Date() / 1000);

      if (userRemapping.expiresAt == "1d") {
        userRemapping.expiresAt = (currentTime + 24 * 60 * 60).toString();
      } else if (userRemapping.expiresAt == "1w") {
        userRemapping.expiresAt = (currentTime + 7 * 24 * 60 * 60).toString();
      } else if (userRemapping.expiresAt == "1h") {
        userRemapping.expiresAt = (currentTime + 60 * 60).toString();
      } else {
        userRemapping.expiresAt = null;
      }

      body["expiresAt"] = userRemapping.expiresAt;
    } else {
      body["expiresAt"] = null;
    }

    const response = await API.post(
      "clients",
      `/clients/${this.state.client.id}/user-remapping`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });

    if (!isEmpty(response)) {
      this.setState({
        clientUserRemapping: [response],
      });
      return true; // the update was successful
    } else {
      return false; // the update was unsuccesful (so we don't close the popover)
    }
  };

  deleteUserRemappingFromClient = async (toId) => {
    var body = {};

    if (toId == null || toId === "") {
      return;
    }

    const response = await API.del(
      "clients",
      `/clients/${this.state.client.id}/user-remapping`,
      {
        body: { toId }
      }
    )
      .then((response) => {
        // the response is empty, but set it to something so we know the delete went through
        return {'isDeleted': true};
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });

    if (!isEmpty(response)) {
      this.setState({
        clientUserRemapping: []
      });
      return true; // the update was successful
    } else {
      return false; // the update was unsuccesful (so we don't close the popover)
    }
  };

  logoutClient = async () => {
    const response = await API.patch(
      "clients",
      `/clients/${this.props.match.params.id}/logout`,
      {
        queryStringParameters: {
          phone: this.state.client["phone"],
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      let status = response["status"]
      if (status) {
        alert("Client has been sucessfully logged out.")
      } else {
        alert("There was an error logging out the client. Please contact an administrator.")
      }
    }
  };

  deleteClient = async () => {
    const response = await API.patch(
      "clients",
      `/clients/${this.props.match.params.id}/delete`,
      {
        queryStringParameters: {
          phone: this.state.client["phone"],
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql only returns the id for simplicity
      // TODO add what we need
      const client = this.state.client;
      const mergedData = merge(client, response);
      this.setState({
        client: mergedData,
      });
    } else {
      // they are deleted
      this.setState({
        client: null,
        clientPhoneIsOptedOut: null,
      });
      this.forceUpdate();
    }
  };

  checkClientPhoneOptOut = async () => {
    if (this.state.client.phone) {
      const response = await API.get(
        "clients",
        `/sns/phone/${this.state.client.phone}`
      );

      if (!isEmpty(response)) {
        this.setState({
          clientPhoneIsOptedOut: response.isOptedOut,
        });
      } else {
        this.setState({
          clientPhoneIsOptedOut: null,
        });
      }
    }
  };

  optInClientPhone = async () => {
    const response = await API.patch(
      "clients",
      `/sns/phone/${this.state.client.phone}/opt-in`,
      {}
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      this.setState({
        clientPhoneIsOptedOut: response.isOptedOut, // note the api always send this back as false
      });
    }
  };

  changePhoneClient = async (phoneData) => {
    var params = { queryStringParameters: {} };
    if ("phone" in phoneData) {
      params.queryStringParameters["new-phone"] = "+1" + phoneData.phone; // the phone should be coming in as a ten digit string from the form
      params.queryStringParameters["old-phone"] = this.state.client["phone"];
    }

    const response = await API.patch(
      "clients",
      `/clients/${this.state.client.id}/phone`,
      params
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });
    if (!isEmpty(response)) {
      // the graphql for a client doesn't contain all the fields we need
      // so instead of adding all those I'll just merge in the deactivated field
      // TODO add what we need
      const client = this.state.client;
      const mergedData = merge(client, response);
      this.setState({
        client: mergedData,
      });
      return true; // the update was successful
    } else {
      return false; // the update was unsuccesful (so we don't close the popover)
    }
  };

  addNoteClient = async (noteData) => {
    const user = await Auth.currentAuthenticatedUser();
    const { attributes, username } = user;

    // only send the parts we want to update
    var body = {};

    body["note"] = noteData.clientNote;

    // add in the bao operator info since I'm not sure how to do it on the backend yet
    body["baoUserId"] = username; // this used to be attributes.sub but in the new pool we are using username (which is userId)
    body["baoFirstName"] = attributes.given_name;
    body["baoLastName"] = attributes.family_name;
    body["baoEmail"] = attributes.email;

    const response = await API.post(
      "clients",
      `/clients/${this.state.client.id}/notes`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });

    if (!isEmpty(response)) {
      var clientNotes = this.state.clientNotes;

      if (clientNotes) {
        clientNotes.unshift(response); // add to beginning of array as we are showing recent dates first
      } else {
        clientNotes = [response];
      }
      this.setState({
        clientNotes: clientNotes,
      });
      return true; // the update was successful
    } else {
      return false; // the update was unsuccesful (so we don't close the popover)
    }
  };

  updateClientNote = async (noteId, noteData) => {
    // only send the parts we want to update
    var body = {};

    body["pinned"] = noteData.pinned;

    await API.patch(
      "clients",
      `/clients/${this.state.client.id}/notes/${noteId}`,
      {
        body: body,
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });

    await this.getClientNotes();

    return true; // the update was successful
  };

  shopperOrderNotesList = async (rowData) => {
    const index = rowData.tableData.id;
    const response = await API.get(
      "shoppers",
      `/shoppers/${rowData.shopperId}/orders/${rowData.id}/notes`
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });

    if (!isEmpty(response)) {
      const newClientOrders = [...this.state.clientOrders];
      newClientOrders[index]["notesList"] = response;
      this.setState({
        clientOrders: newClientOrders,
      });
    }
  };

  shopperOrderSharedList = async (rowData) => {
    const index = rowData.tableData.id;
    // pull out the bits needed to fetch the shared list
    if ("sharedListIdentifiers" in rowData) {
      if (rowData["sharedListIdentifiers"].length > 0) {
        // currently there should only be one shared list
        const sharedList = rowData["sharedListIdentifiers"][0];
        const listId = sharedList["listId"];
        const snapshotId =
          "snapshotId" in sharedList ? sharedList["snapshotId"] : "0";
        const listOwnerId = sharedList["owner"];

        const response = await API.get(
          "shoppers",
          `/shoppers/${rowData.shopperId}/orders/${rowData.id}/sharedlist/${listId}`,
          {
            queryStringParameters: {
              "list-owner-id": listOwnerId,
              "snapshot-id": snapshotId,
            },
          }
        )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            alert("Error! " + error.response["data"]);
            return null;
          });

        // it's okay if response is empty, that just means no items
        // TODO - it may be better to just send back the items instead of injecting them like this.
        const newClientOrders = [...this.state.clientOrders];
        // insert the shared list where we prevously had embedded in the order list call
        newClientOrders[index]["sharedListIdentifiers"][0]["items"] = response;
        this.setState({
          clientOrders: newClientOrders,
        });
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  shopperTransactionsList = async (rowData) => {
    const index = rowData.tableData.id;
    const response = await API.get(
      "shoppers",
      `/shoppers/${rowData.shopperId}/orders/${rowData.id}/transactions`,
      {
        queryStringParameters: {
          // these are all required for transactions list
          "delivery-timestamp": rowData.deliveryTimestamp,
          "client-id": rowData.clientId,
          "order-timestamp": rowData.orderTimestamp,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Error! " + error.response["data"]);
        return null;
      });

    if (!isEmpty(response)) {
      const newClientOrders = [...this.state.clientOrders];
      newClientOrders[index]["transactionsList"] = response;
      this.setState({
        clientOrders: newClientOrders,
      });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="notes">
        {this.state.client ? (
          <Paper className={classes.root}>
            <ClientDetail
              client={this.state.client}
              clientNotes={this.state.clientNotes}
              deactivate={this.deactivateClient}
              activate={this.activateClient}
              delete={this.deleteClient}
              clientPhoneIsOptedOut={this.state.clientPhoneIsOptedOut}
              optInPhone={this.optInClientPhone}
              changePhone={this.changePhoneClient}
              addNoteClient={this.addNoteClient}
              clientNoteUpdate={this.updateClientNote}
              logout={this.logoutClient}
              clientUserRemapping={this.state.clientUserRemapping}
              addUserRemappingToClient={this.addUserRemappingToClient}
              deleteUserRemappingFromClient={this.deleteUserRemappingFromClient}
            />
          </Paper>
        ) : (
          <Paper className={classes.rootSpinner}>
            <CircularProgress />
          </Paper>
        )}
        {this.state.clientOrders ? (
          <ClientsOrdersTable
            shopper={this.state.client}
            shopperOrders={this.state.clientOrders}
            onReqTransaction={this.shopperTransactionsList}
            onReqNotes={this.shopperOrderNotesList}
            onReqSharedList={this.shopperOrderSharedList}
          />
        ) : (
          <ClientsOrdersTable />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Client);
