import React, { Component } from "react";
import MaterialTable from "material-table";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import "moment-timezone";
import getState from "../../utils/stateFromZip.js";
import { CopyToClipboard } from "react-copy-to-clipboard";
import OrderPricingDialog from "../shoppers/OrderPricingDialog";
import ShopperOrderNotesTable from "../shoppers/ShopperOrderNotesTable.js";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  address: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
  },
  customItem: {
    color: "black",
    fontWeight: 550,
  },
  table: {
    minWidth: 650,
  },
  transactionPrice: {
    color: "black",
    fontWeight: 550,
  },
});

class ClientOrdersTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveryValue: null,
      showLoading: false,
      notesLoading: false,
      notesLoadingId: "", // the id of the loading note so we only show the loading bar for the note that is loading
    };
  }

  requestNotes = async (rowData) => {
    this.setState({ notesLoadingId: rowData.id });
    this.setState({ notesLoading: true });
    await this.props.onReqNotes(rowData);
    this.setState({ notesLoading: false });
    this.setState({ notesLoadingId: "" });
  };

  render() {
    const { classes, shopper } = this.props;
    const { deliveryValue } = this.state;

    const urlPrefix = process.env.REACT_APP_STAGE === "prod" ? "" : "dev-";

    return (
      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          isLoading={!this.props.shopperOrders || this.state.showLoading}
          actions={[
            (rowData) => ({
              disabled: rowData.orderStatus !== "Created", // only let them cancel created orders
              icon: "cancel",
              tooltip: "Cancel (Created Only)",
              onClick: async (event, rowData) => {
                if (
                  window.confirm("Are you sure you want to cancel this order?")
                ) {
                  this.setState({ showLoading: true });
                  await this.props.cancel(rowData);
                  this.setState({ showLoading: false });
                }
              },
            }),
            (rowData) => ({
              disabled: rowData.orderStatus !== "Complete", // only let them reopen closed orders
              icon: "unarchive",
              tooltip: "Reopen (Complete Only)",
              onClick: async (event, rowData) => {
                if (
                  window.confirm(
                    "Stripe payment transfer is now automatically reversed.  You do not need to manually do this."
                  )
                ) {
                  this.setState({ showLoading: true });
                  await this.props.reopen(rowData);
                  this.setState({ showLoading: false });
                }
              },
            }),
          ]}
          options={{ pageSize: 20, pageSizeOptions: [5, 10, 20, 100] }}
          columns={[
            { title: "Status", field: "orderStatus", editable: "never" },
            {
              title: "Id",
              field: "id",
              editable: "never",
              render: (rowData) =>
                rowData.id ? rowData.id.split("-", 1) : "Not Available",
            },
            {
              title: "Shopper",
              field: "shopperId",
              editable: "never",
              render: (rowData) => (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/shoppers/${rowData.shopperId}`}
                >
                  {rowData.shopperFirstName
                    ? rowData.shopperFirstName
                    : "Shopper"}{" "}
                  {rowData.shopperLastName}
                </Link>
              ),
            },
            {
              title: "Order Created",
              field: "orderTimestamp",
              editable: "never",
              render: (rowData) => (
                <Moment
                  unix
                  format="MMM DD, YYYY h:mm A z"
                  tz="America/Los_Angeles"
                >
                  {Number(rowData.orderTimestamp)}
                </Moment>
              ),
            },
            {
              title: "Delivery Date",
              field: "deliveryTimestamp",
              editable: "never",
              render: (rowData) => (
                <Moment unix format="MMM DD, YYYY" tz="America/Los_Angeles">
                  {Number(rowData.deliveryTimestamp)}
                </Moment>
              ),
            },
            {
              title: "Store",
              field: "serviceRequestList[0].errandList[0].businessName",
              editable: "never",
            },
            { title: "First", field: "firstName", editable: "never" },
            { title: "Last", field: "lastName", editable: "never" },
            {
              title: "Pricing",
              field: "priceBreakdown",
              editable: "never",
              render: (rowData) => <OrderPricingDialog rowData={rowData} />,
            },
            {
              title: "Subscription",
              field: "pricingModel.name",
              editable: "never",
              render: (rowData) =>
                rowData.pricingModel && rowData.pricingModel.name
                  ? rowData.pricingModel.name
                  : "N/A",
            },
            // { title: "Delivery Fee", field: "deliveryFee",// type: "currency",
            //   render: rowData => <NumberFormat value={rowData.deliveryFee} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} prefix={'$'} />,
            //   editComponent: props => (
            //     <NumberFormat isNumericString={false} value={props.value} customInput={TextField} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} onValueChange={(values) => {
            //         const {formattedValue, value, floatValue} = values;
            //         // formattedValue = $2,223
            //         // value ie, 2223
            //         props.onChange(floatValue)
            //       }}
            //     />
            //   )
            // },
            {
              title: "PreTip",
              field: "tip",
              type: "numeric",
              editable: false,
              render: (rowData) => {
                if (rowData.pricingModel) {
                  if (rowData.pricingModel.preTipPm) {
                    if (rowData.pricingModel.preTipPm.chosenPercent) {
                      return (
                        <span>
                          {rowData.pricingModel.preTipPm.chosenPercent} %
                        </span>
                      );
                    } else if (
                      rowData.pricingModel.preTipPm.chosenFixedGratuity
                    ) {
                      return (
                        <NumberFormat
                          value={
                            rowData.pricingModel.preTipPm.chosenFixedGratuity /
                            100.0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={"$"}
                        />
                      );
                    } else {
                      // client chose nothing
                      return <span>After</span>;
                    }
                  } else {
                    // the shopper deleted all pre tip options
                    return <span>N/A</span>;
                  }
                } else {
                  // ancient orders just use the tip
                  return <span>{rowData.tip} %</span>;
                }
              },
            },
            { title: "Coupon Code", field: "couponCode",
            render: (rowData) => {
              var displayCouponCode = rowData.couponCode
              if (displayCouponCode && displayCouponCode.length > 12) {
                displayCouponCode = displayCouponCode.substring(0,12) + "..."
              }
              return <span>{displayCouponCode}</span>
              }
            }
          ]}
          data={this.props.shopperOrders}
          title={
            this.props.shopper &&
            this.props.shopper.openOrderCount &&
            parseInt(this.props.shopper.openOrderCount) > 0
              ? `Orders (${this.props.shopper.openOrderCount} open)`
              : "Orders"
          }
          localization={{
            body: {
              editTooltip: "Edit (Created Only)",
            },
          }}
          editable={{
            isEditable: (rowData) => rowData.orderStatus === "Created", // only name(a) rows would be editable
            onRowUpdate: (newData, oldData) =>
              // this returns a promise
              this.props.editField(newData, oldData),
          }}
          detailPanel={[
            {
              onClick: (rowData) => {
                // not doing an await here as we don't want to hold up the viewing of the items while this load
                this.props.onReqTransaction(rowData);
                // not doing an await here as we don't want to hold up the viewing of the items while this load
                this.requestNotes(rowData);
                // not doing an await here as we don't want to hold up the viewing of the items while this load
                this.props.onReqSharedList(rowData);
              },
              render: (rowData) => {
                if (rowData.serviceRequestList[0].errandList[0]) {
                  // check if user has a sharedList, fall back to the shoppingList if not as very old orders are this way
                  let itemList: JSX.Element;
                  let transactionsList: JSX.Element;
                  // TODO - I should really be dymaically calling the shared list here to save bandwidth and so that it doesn't need
                  // to be injected during the ShopperOrders call.  This could also help not having to merge data returned from operations on orders
                  if ("sharedListIdentifiers" in rowData) {
                    if ("items" in rowData.sharedListIdentifiers[0]) {
                      if (rowData.sharedListIdentifiers[0].items === null) {
                        itemList = React.createElement("div", {}, "");
                      } else {
                        const customPrice = [
                          "Costco",
                          "BJ's",
                          "Sam's Club",
                        ].includes(
                          rowData.serviceRequestList[0].errandList[0]
                            .businessName
                        )
                          ? 1000.0
                          : 300.0;
                        const estimatedTotal =
                          rowData.sharedListIdentifiers[0].items.reduce(
                            function (accum, row) {
                              const price = row.price ? row.price : customPrice;
                              const units =
                                row.count !== null && row.count >= 0
                                  ? row.count
                                  : 1;

                              return accum + price * units;
                            },
                            0
                          );

                        const catalogName =
                          rowData.serviceRequestList[0].errandList[0].businessName.toLowerCase();

                        itemList = rowData.sharedListIdentifiers[0].items.map(
                          (row) => {
                            const customUrl = `https://${urlPrefix}dimsum.dumpling.us/product/new?name=${encodeURIComponent(
                              row.name
                            )}&price=${encodeURIComponent(
                              (row.price || 0) / 100.0
                            )}&imageLink=${encodeURIComponent(
                              row.image
                            )}&displaySize=${encodeURIComponent(
                              row.displaySize
                            )}&sellerName=${encodeURIComponent(catalogName)}`;

                            return (
                              <TableRow key={row.itemId}>
                                <TableCell component="th" scope="row">
                                  {row.count} x{" "}
                                  {row.price ? (
                                    <NumberFormat
                                      value={row.price / 100.0}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      fixedDecimalScale={true}
                                      decimalScale={2}
                                      prefix={"$"}
                                    />
                                  ) : (
                                    ""
                                  )}{" "}
                                  {row.isCustom ? (
                                    <span className={classes.customItem}>
                                      Custom:
                                    </span>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {row.name}{" "}
                                  {row.displaySize ? (
                                    <>({row.displaySize})</>
                                  ) : (
                                    ""
                                  )}
                                  {row.note ? (
                                    <>
                                      {" "}
                                      -{" "}
                                      <span
                                        className={classes.secondaryHeading}
                                      >
                                        {row.note}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {row.isCustom ? (
                                    <a href={customUrl} target="_blank">
                                      add to dimsum
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        );
                        if (itemList.length > 0) {
                          itemList.unshift(
                            <TableRow key={9999}>
                              <TableCell component="th" scope="row">
                                <strong>
                                  <NumberFormat
                                    value={estimatedTotal / 100.0}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    prefix={"$"}
                                  />
                                </strong>{" "}
                                <span>
                                  roughly estimated total,{" "}
                                  <strong>
                                    {
                                      rowData.sharedListIdentifiers[0].items.filter(
                                        (row) => row.isCustom
                                      ).length
                                    }
                                  </strong>{" "}
                                  custom items.
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      }
                    }
                  } else {
                    itemList =
                      rowData.serviceRequestList[0].errandList[0].shoppingList.map(
                        (row) => (
                          <TableRow key={row.itemId}>
                            <TableCell component="th" scope="row">
                              {row.itemName}
                            </TableCell>
                          </TableRow>
                        )
                      );
                  }
                  if ("transactionsList" in rowData) {
                    transactionsList = rowData.transactionsList.map(
                      (row, index) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {rowData.orderStatus === "Created" ? (
                              <Button
                                style={{ marginRight: 10 }}
                                color="secondary"
                                onClick={async () => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to remove this transaction from this order?"
                                    )
                                  ) {
                                    this.setState({ showLoading: true });
                                    await this.props.removeTransaction(
                                      rowData,
                                      rowData.transactionsList[index]
                                    );
                                    this.setState({ showLoading: false });
                                  }
                                }}
                              >
                                Remove
                              </Button>
                            ) : null}
                            <NumberFormat
                              className={classes.transactionPrice}
                              value={row.transactionAmount / 100.0}
                              displayType={"text"}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={2}
                              prefix={"$"}
                            />{" "}
                            - {row.merchantName}
                            {", "}
                            {row.merchantCity}
                            {", "}
                            {row.merchantState}
                            {" - "}
                            <Moment
                              format="MMM DD, YYYY h:mm A z"
                              tz="America/New_York"
                            >{`${row.transactionUtcTime}Z`}</Moment>{" "}
                            <Link
                              style={{ marginLeft: 10 }}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={row.image}
                            >
                              View Receipt
                            </Link>
                          </TableCell>
                        </TableRow>
                      )
                    );
                  }
                  return (
                    <React.Fragment>
                      <div className={classes.address}>
                        <span className={classes.secondaryHeading}>
                          Delivery Address
                        </span>
                        &nbsp;&nbsp;
                        {rowData.deliveryAddress.addressLine1}
                        {rowData.deliveryAddress.addressLine2
                          ? ", " + rowData.deliveryAddress.addressLine2
                          : ""}{" "}
                        - {rowData.deliveryAddress.city},{" "}
                        {rowData.deliveryAddress.state}{" "}
                        {rowData.deliveryAddress.zipcode}
                      </div>
                      <div className={classes.address}>
                        <span className={classes.secondaryHeading}>
                          CC Address
                        </span>
                        &nbsp;&nbsp;
                        {rowData.orderPaymentMethod.address ? (
                          `"${
                            rowData.orderPaymentMethod.name
                              ? rowData.orderPaymentMethod.name
                              : "Not Available"
                          }" ${
                            rowData.orderPaymentMethod.address.addressLine1
                          }${
                            rowData.orderPaymentMethod.address.addressLine2
                              ? ", " +
                                rowData.orderPaymentMethod.address.addressLine2
                              : ""
                          } - ${
                            rowData.orderPaymentMethod.address.city
                              ? rowData.orderPaymentMethod.address.city + ", "
                              : "Not Available, "
                          } ${getState(
                            rowData.orderPaymentMethod.address.zipcode
                          )} ${rowData.orderPaymentMethod.address.zipcode}`
                        ) : (
                          <>
                            <span>
                              N/A - This information was not collected{" "}
                            </span>
                            <span style={{ fontWeight: 600 }}>by dumpling</span>
                            <span>
                              {" "}
                              for credit cards added prior to 4/8/20.
                            </span>
                          </>
                        )}
                        &nbsp;&nbsp;
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://dashboard.stripe.com/customers/${rowData.stripeCustomerId}`}
                        >
                          View in Stripe
                        </Link>
                      </div>
                      <Paper className={classes.paper}>
                        {"transactions" in rowData &&
                        rowData["transactions"].length > 0 ? (
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  Transactions
                                  {!transactionsList ? (
                                    <LinearProgress />
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>{transactionsList}</TableBody>
                          </Table>
                        ) : null}
                      </Paper>
                      <Paper className={classes.paper}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                Items
                                {!itemList ? <LinearProgress /> : null}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>{itemList}</TableBody>
                        </Table>
                      </Paper>
                    </React.Fragment>
                  );
                }
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ClientOrdersTable);
