import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, MenuItem, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Form } from "react-final-form";
import { TextField, Select } from "mui-rff";
import NumberFormat from "react-number-format";

import Upload from "../../components/Upload";

const styles = (_theme) => ({
  root: {
    flexGrow: 1,
  },
  buttonProgress: {
    //color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -10,
    marginLeft: -12,
  },
  farRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "baseline",
    paddingTop: 10,
  },
  noteDate: {
    marginTop: 20,
  },
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      fixedDecimalScale // need to set this to always show zeros so the string to int math always works
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value, // we are using the string representation to avoid float multiplication issues
          },
        });
      }}
      thousandSeparator
      prefix="$"
      decimalScale={2}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function SyntheticTransactionPopoverForm(props) {
  const { classes, shopperCards, submitFunc } = props;
  const [loading, setLoading] = useState(false);
  const [cardAcctId, setCardAcctId] = useState(null);

  async function onSubmit(values) {
    values.cardAcctId = cardAcctId;
    //values.receiptFile = values.receiptFile[0];

    setLoading(true);
    await submitFunc(values);
    setLoading(false);
  }

  async function validate(values) {
    // TODO - check if they actually changed something
    var errors = {};

    if (cardAcctId == null) {
      return { cardAcctId: "Please enter something." };
    }

    if (!("transactionAmount" in values) || values.transactionAmount == null) {
      return { transactionAmount: "Please enter something." };
    }

    //if (!("receiptFile" in values) || values.receiptFile == null) {
    //  return { receiptFile: "Please enter something." };
    //}

    if (!("merchantName" in values) || values.merchantName == null) {
      return { merchantName: "Please enter something." };
    }

    if (!("merchantCity" in values) || values.merchantCity == null) {
      return { merchantCity: "Please enter something." };
    }

    if (!("merchantState" in values) || values.merchantState == null) {
      return { merchantState: "Please enter something." };
    }

    if (!("merchantZip" in values) || values.merchantZip == null) {
      return { merchantZip: "Please enter something." };
    }

    return errors;
  }

  function cardItems() {
    return shopperCards.map((shopperCard) => {
      const cardAcctId = shopperCard.cardAcctId;
      const label = cardAcctId.startsWith("ic_")
        ? `Stripe Card ${shopperCard.last4}`
        : `PEX Card ${shopperCard.last4}`;

      return <MenuItem value={cardAcctId}>{label}</MenuItem>;
    });
  }

  return (
    <>
      {/* <Typography>The content of the Popover.</Typography> */}
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, values }) => (
          <form
            className={classes.root}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <Select
              name="cardAcctId"
              label="Card"
              onChange={(e) => setCardAcctId(e.target.value)}
              value={cardAcctId}
            >
              {cardItems()}
            </Select>
            <br />
            <TextField
              disabled={loading}
              style={{ width: 200 }}
              label="Amount"
              name="transactionAmount"
              InputProps={{
                inputComponent: NumberFormatCustom,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                  </React.Fragment>
                ),
              }}
            />
            {
              //<Upload name="receiptFile" label="Receipt" />
            }
            <br />
            <TextField
              label="Merchant Name"
              name="merchantName"
              style={{ width: 200 }}
            />
            <br />
            <TextField
              label="Merchant City"
              name="merchantCity"
              style={{ width: 200 }}
            />
            <br />
            <TextField
              label="Merchant State"
              name="merchantState"
              style={{ width: 200 }}
            />
            <br />
            <TextField
              label="Merchant Zip"
              name="merchantZip"
              style={{ width: 200 }}
            />
            <br />
            <div className={classes.farRight}>
              <Button disabled={loading} type="submit" color="primary">
                Submit
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </div>
          </form>
        )}
      />
    </>
  );
}

export default withStyles(styles)(SyntheticTransactionPopoverForm);
