import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

function createData(name, transactionLimit, maxDanglingSpend, fundingPerOrder) {
    return { name, transactionLimit, maxDanglingSpend, fundingPerOrder };
  }
  
  const rows = [
    createData('Basic', "$275", "$275", "$275"),
    createData('Newbie', "$275", "$550", "$275"),
    createData('Standard', "$375", "$1,125", "$375"),
    createData('Trusted', "$750", "$1,500", "$375"),
    createData('VIP', "$1,500", "$2,000", "$500"),
    createData('Bao Master', "$2,000", "$2,500", "$500"),
    createData('Haitham Special', "$1,500", "$3,000", "$1,500"),
    createData('Pao 💥', "$1,500", "$7,000", "$675"),
    createData('Resort ⛷', "$2,000", "$10,000", "$1,000"),
  ];
  

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <IconButton aria-label="info" onClick={handleClickOpen}>
        <InfoIcon />
      </IconButton>
      <Dialog maxWidth="md" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Funding Profiles
        </DialogTitle>
        <DialogContent dividers>
            {/* <TableContainer component={Paper}> */}
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Profile</TableCell>
                        <TableCell align="right">Transaction Limit</TableCell>
                        <TableCell align="right">Max Open Spend</TableCell>
                        <TableCell align="right">Funding per Order</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                            {row.name}
                        </TableCell>
                        <TableCell align="right">{row.transactionLimit}</TableCell>
                        <TableCell align="right">{row.maxDanglingSpend}</TableCell>
                        <TableCell align="right">{row.fundingPerOrder}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            {/* </TableContainer> */}
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}