import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { Select } from "mui-rff";
import { MenuItem } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InfoIcon from '@material-ui/icons/Info';
import 'date-fns';
import MomentUtils from '@date-io/moment';
import { DatePicker } from "mui-rff";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  buttonProgress: {
    //color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: 24,
    marginLeft: -60,
  },
  sideBySide: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  info: {
    verticalAlign: 'middle'
  }
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      fixedDecimalScale // need to set this to always show zeros so the string to int math always works
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value, // we are using the string representation to avoid float multiplication issues
          },
        });
      }}
      thousandSeparator
      prefix="$"
      decimalScale={2}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function UnpausePopoverForm(props) {
  const { classes, initialValues, submitFunc } = props;
  const [loading, setLoading] = React.useState(false);
  //const [openDialog, setOpenDialog] = React.useState(false)

  async function onSubmit(values) {
    const tz = 'Pacific/Honolulu';
    console.log(values.unpauseUntil.clone().tz(tz).endOf('day').unix())
    // Convert to a timestamp at the end of the day in Hawaii so all shoppers get at least until the end of the day
    const unpauseUntilTimestamp = values.unpauseUntil.clone().tz(tz).endOf('day').unix() 

    setLoading(true);
    await submitFunc(unpauseUntilTimestamp);
    setLoading(false);
  }

  const today = new Date()  
  const maxDate = new Date().setDate(today.getDate() + 60)  
  const defaultDate = new Date().setDate(today.getDate() + 30)  
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => {
        return (
          <form
            className={classes.root}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <DatePicker
                label="Date"
                name="unpauseUntil"
                required={true}
                dateFunsUtils={MomentUtils}
                margin="normal"
                variant="inline"
                format="MMM Do YYYY"
                disablePast={true}
                maxDate={maxDate}
                initialFocusedDate={defaultDate}
              />
            <div className={classes.sideBySide}>
              <Button disabled={loading} type="submit" color="primary" >
                Submit
              </Button>
              {loading && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
              />
            )}
            </div>
          </form>
        );
      }}
    />
  );
}

export default withStyles(styles)(UnpausePopoverForm);
