import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { Form } from 'react-final-form';
import { Select } from 'mui-rff';
import { MenuItem } from '@material-ui/core';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  buttonProgress: {
    //color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: 28,
    marginLeft: 34,
    // marginTop: 24,  sideBySide numbers
    // marginLeft: -60,
  },
  sideBySide: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  farRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    paddingTop: 10
  }
})



function SubscriptionPopoverForm(props) {
  const {classes, label, initialValues, submitFunc, name} = props
  const [loading, setLoading] = React.useState(false)

  async function onSubmit(values) {
    setLoading(true)
    await submitFunc(values)
    setLoading(false)
  }

  async function validate(values) {
    // TODO - check if they actually changed something

    if (!(name in values) || values[name] === null) {  // note, we want to let zeros through but not null
      return { [name]: 'Please enter something.' };
    }
    return;
  }
  
  return ( 
    <>
      {/* <Typography>The content of the Popover.</Typography> */}
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, values }) => (
          <form className={classes.root} onSubmit={handleSubmit} noValidate autoComplete="off">
              <>
                <Select style={{ minWidth: 160 }} name={name} label={label} formControlProps={{ margin: 'normal' }} >
                  <MenuItem value="Legacy Starter">Pioneer Starter</MenuItem>
                  <MenuItem value="Legacy Pro">Pioneer Pro</MenuItem>
                  <MenuItem value="Starter">Starter</MenuItem>
                  <MenuItem value="Pro">Pro</MenuItem>
                  <MenuItem value="Pro v2">Pro v2</MenuItem>
                  <MenuItem value="Tycoon">Tycoon</MenuItem>
                  <MenuItem value="Tycoon v2">Tycoon v2</MenuItem>
                  <MenuItem value="Resort Pro">Resort Pro</MenuItem>
                  <MenuItem value="Resort Pro v2">Resort Pro v2</MenuItem>
                  <MenuItem value="Resort Pro v3">Resort Pro v3</MenuItem>
                  <MenuItem value="Resort Pro v4">Resort Pro v4</MenuItem>
                  <MenuItem value="Resort Shopper">Resort Shopper</MenuItem>
                  <MenuItem value="Resort Grocery Platform - RCC Vail">Resort Grocery Platform - RCC Vail</MenuItem>
                  <MenuItem value="Resort Grocery Platform - Abode Luxury Rentals">Resort Grocery Platform - Abode Luxury Rentals</MenuItem>
                  <MenuItem value="Resort Grocery Platform - AvantStay">Resort Grocery Platform - AvantStay</MenuItem>
                  <MenuItem value="Resort Grocery Platform - Key Concierge">Resort Grocery Platform - Key Concierge</MenuItem>
                </Select>
                <div className={classes.farRight}>
                  <Button disabled={loading} type="submit" color="primary">Submit</Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </>
          </form>
        )}
      />
    </>
  );
}

export default withStyles(styles)(SubscriptionPopoverForm)
