import React, { useState, useEffect } from "react"
import  axios from "axios";
import "./ShopperSearchDebugPage.css";
import Typography from '@material-ui/core/Typography';
import Moment from "react-moment";
import moment from "moment";
import "moment-timezone";

const ShopperSearchDebugPage = props => {
//const [resultList, setResultList] = useState([])
const [resultList2, setResultList2] = useState([])
const [resultList3, setResultList3] = useState([])

const nowTimestamp = moment().unix()

useEffect(() => {
    /**
     * @todo abstract away baseURL and auth_token
     *  can use dotenv, still will be exposed in network tab
     * 
     * @todo will need promise polyfill
     */

    const apiURL = process.env.REACT_APP_STAGE === 'prod'
     ? "https://web.dumpling.us/prod5/"
     : "https://vffvo31ez6.execute-api.us-east-2.amazonaws.com/dev";

    // axios({
    //   method: "post",
    //   url: apiURL,

    //   headers: {
    //     "Content-Type": "application/json",
    //     "Authorization": "0e44ded7-3d5f-4621-823e-e9a7af363692",

    //   },
    //   data: {
    //     "path": "",
    //     "zipcode": props.match.params.zipcode,
    //     "version": "distance"
    //   }
    // })
    // .then((response) => {
    //   //console.log(response);
    //   setResultList(response.data.shoppers);
    // }, (error) => {
    //   console.log(`Something went wrong fetching geosearch data: ${error}`)
    // })

    axios({
      method: "post",
      url: apiURL,

      headers: {
        "Content-Type": "application/json",
        "Authorization": "0e44ded7-3d5f-4621-823e-e9a7af363692",

      },
      data: {
        "path": "",
        "zipcode": props.match.params.zipcode,
        "scoringProfile": "geoScoringWindowCancelConvDumplingBoosted",
        "version": "distance",
        "includePausedShoppers": true
        //"searchIndexURL": "https://dev-dumplinggeo.search.windows.net/indexes/azureblob-geo-enhanced-v4-index/docs{0}"
      }
    })
    .then((response) => {
      //console.log(response);
      setResultList2(response.data.shoppers);
    }, (error) => {
      console.log(`Something went wrong fetching geosearch data: ${error}`)
    })

    // 3rd column
    axios({
      method: "post",
      url: apiURL,

      headers: {
        "Content-Type": "application/json",
        "Authorization": "0e44ded7-3d5f-4621-823e-e9a7af363692",

      },
      data: {
        "path": "",
        "zipcode": props.match.params.zipcode,
        "scoringProfile": "geoScoringWindowCancelConvDumplingBoosted",
        "version": "exactMatchZipcode",
        "includePausedShoppers": true
      }
    })
    .then((response) => {
      //console.log(response);
      setResultList3(response.data.shoppers);
    }, (error) => {
      console.log(`Something went wrong fetching geosearch data: ${error}`)
    })

  }, []);


  return (
    <>
      <p>
        <b>Liefetime Stats:</b> Data for liftetime of biz owner from all sources.<br></br>
        <b>Marketplace Stats - Filter:</b> Data for just marketplace sourced clients, used to filter out poor performers. Could be 30, 60, 90 days.<br></br>
        <b>Marketplace Stats - 30 Days:</b> Data for just marketplace sourced clients for last 30 days.<br></br>
        <b>Combined Stats - 30 Days:</b> Data for all sourced clients for last 30 days.<br></br>
        <hr />
        <b>New leads:</b> New leads in the last 37-7 days + new leads that completed an order in last 7-0 days. (non-converting leads in last 7 days not counted) <br></br>
        <b>New clients:</b> Total new leads in the last 37 days that completed an order.<br></br>
        <b>Conversion rate:</b> Actual percentatge of leads that convererted in last 30 days.<br></br>
        <b>Conversion rate (filter):</b> Percentatge of marketplace leads that convererted in last 30, 60, 90 days (used for filtering).<br></br>
        <b>Conversion rate period:</b> The amount of time Conversion rate (filter) was run over.  Could be 30, 60 or 90 days.<br></br>
        <b>Conversion rate (boosted):</b> Percentatge of marketplace leads that convererted in last 30 days with bonuses for non-marketplace leads (used for order).<br></br>
        <b>Repeat clients:</b> Clients that completed 2+ orders.<br></br>
        <b>Complete orders:</b> Number of complete orders in the last 30 days.<br></br>
        <b>Canceled orders:</b> Number of canceled orders in the last 30 days (multi-store and operator cancels omitted).<br></br>
        <b>Canceled orders, 1st time:</b> Number of canceled orders by first time clients only in the last 30 days (Multi-store and operator cancels omitted).<br></br>
        <b>Cancelation rate:</b> Percent of orders in last 30 days that were canceled (Does not include cancels for multi-store, opeartor cancels. New shoppers given 2 free cancels).<br></br>
        <b>Cancelation rate, 1st time:</b> Percent of orders by first time clients only in last 30 days that were canceled (Does not include cancels for multi-store, opeartor cancels. New shoppers given 2 free cancels).<br></br>
        <b>Cancelation rate, 1st time (filter):</b> Percent of orders by first time clients only in last 30, 60, 90 days that were canceled (Does not include cancels for multi-store, opeartor cancels. New shoppers given 2 free cancels) (used for filtering).<br></br>
        <hr />
        <b>Last order:</b> Date of last complete order. For the case of a shopper with no orders, this is their creation date.<br></br>
      </p>
      <div class="row">
      {/* <div class="column">
        <div class="container">
          <Typography variant="h6" component="h3" style={{marginTop:'10px'}}>
            Current scoring algorithm, shopper lifetime stats.
          </Typography>
        </div>
        {
          resultList !== [] ? 
          resultList.map((shopper) => {
            return (
            // <Link to={`/app/owner/${shopper.webPath}}`>

            <div key={shopper.id} className="shopper-result-card" >
              <div class="row">
                <div style={{flex:'35%'}}>
                  <div class="profile_circle">
                    <img src={shopper.image}/>
                  </div>
                  <p style={{marginLeft:'38px'}}>{shopper['@search.score']}</p>
                </div>
                <div style={{flex:'42%', overflow:'hidden', height:'180px'}} class="column">
                  <p><a href={"../" + shopper.id}><b>{shopper.displayName}</b></a></p> 
                  <p>{shopper.businessName}</p> 
                  <p><a href={"./" + shopper.deliveryAreaZipcode}>{shopper.deliveryAreaZipcode}</a></p> 
                  <p>Distance: {shopper.distanceMi}</p>
                  <p>Last order: {new Date(shopper.lastOrderTimestamp * 1000).toLocaleDateString("en-US")}</p>
                  <p>{shopper.deliveryArea}</p>
                </div>
                <div class="column">
                  <p>Lifetime rating: {shopper.rating}</p>
                  <p>Lifetime reviews: {shopper.reviewCount}</p> 
                  <p>Lifetime connections: {shopper.clientCount}</p>
                  <p>Lifetime repeat clients: {shopper.repeatClientCount}</p>
                  <p>Lifetime orders: {shopper.orderCount}</p>
                </div>
              </div>


              <p style={{marginTop:'14px', overflow:'hidden'}} class="center-text">{shopper.tagline}</p> 
              </div>
            )
          }) :
          <h1>No results</h1>
        }
      </div> */}
      <div class="column">
        <div class="container">
          <Typography variant="h6" component="h3" style={{marginTop:'10px'}}>
            Current scoring algorithm.
          </Typography>
          </div>
        {
          resultList2 !== [] ? 
          resultList2.map((shopper) => {
            return (
            // <Link to={`/app/owner/${shopper.webPath}}`>

            <div key={shopper.id} className="shopper-result-card" >
              {/* <button type="button" onClick={() => handleNavigateToOwner(shopper)}> */}
              <div class="row" style={((shopper.pausedFromMarketplaceByConversionRate || shopper.pausedFromMarketplaceByCancelationRate) && shopper.unpausedByOperatorUntilTimestamp < nowTimestamp) || shopper.kickedOutZipcode ? {backgroundColor:"#E0E0E0"}:{}}>
                <div style={{flex:'30%'}}>
                  <div class="profile_circle">
                    <img src={shopper.image}/>
                  </div>
                  <p style={{marginLeft:'38px'}}>{shopper['@search.score']}</p>
                </div>
                <div style={{flex:'46%', overflow:'hidden', marginRight: '20px'}} class="column">
                  <p><a href={"../" + shopper.id}><b>{shopper.displayName}</b></a></p> 
                  <p>{shopper.businessName}</p> 
                  <p><a href={"./" + shopper.deliveryAreaZipcode}>{shopper.deliveryAreaZipcode}</a></p> 
                  <p>Paused on Marketplace: {(shopper.pausedFromMarketplaceByConversionRate || shopper.pausedFromMarketplaceByCancelationRate) && shopper.unpausedByOperatorUntilTimestamp < nowTimestamp ? <b>True</b>:"False"}</p>
                  <p>Removed from Marketplace: {shopper.kickedOutZipcode ? <b>True</b>:"False"}</p>
                  <p>Distance: {shopper.distanceMi}</p>
                  <p>Last order: {new Date(shopper.lastOrderTimestamp * 1000).toLocaleDateString("en-US")}</p>
                  <p className="truncate">Location: {shopper.deliveryArea}</p> 
                  <p className="truncate">Tagline: {shopper.tagline}</p>
                  <hr />
                  <Typography variant="overline" style={{ fontWeight: 600 }}>Lifetime Stats</Typography>
                  <div style={{marginLeft: '4px'}}>
                    <p>Rating: {shopper.rating}</p>
                    <p>Review count: {shopper.reviewCount}</p> 
                    <p>Clients: {shopper.clientCount}</p>
                    <p>Repeat clients: {shopper.repeatClientCount}</p>
                    <p>Complete orders: {shopper.orderCount}</p>
                  </div>
                </div>
                <div class="column">
                <Typography variant="overline" style={{ fontWeight: 600 }}>Marketplace Stats - Filter</Typography>
                  <div style={{marginLeft: '4px'}}>
                    <p>New leads: {shopper.clientCountWindowDumplingFilter !== null?shopper.clientCountWindowDumplingFilter:"??"}</p>
                    <p>New clients: {shopper.clientCountWindowDumplingFilter !== null?Math.round(shopper.clientCountWindowDumplingFilter*shopper.conversionRateDumplingFilter/100):"??"}</p>
                    <p>Conversion rate period: {shopper.conversionRateDumplingFilterPeriod !== null?shopper.conversionRateDumplingFilterPeriod:"??"} days</p>
                    <p>Conversion rate: {shopper.conversionRateDumplingFilter !== null?shopper.conversionRateDumplingFilter:"??"}%</p>
                    <p>Cancelation rate, 1st time: {shopper.cancelationRateDumplingFilter !== null?100-shopper.cancelationRateDumplingFilter:"??"}%</p>
                  </div>
                  <hr />
                  <Typography variant="overline" style={{ fontWeight: 600 }}>Marketplace Stats - 30 Days</Typography>
                  <div style={{marginLeft: '4px'}}>
                    <p>New leads: {shopper.clientCountWindowDumpling !== null?shopper.clientCountWindowDumpling:"??"}</p>
                    <p>New clients: {shopper.clientCountWindowDumpling !== null?Math.round(shopper.clientCountWindowDumpling*shopper.conversionRateDumpling/100):"??"}</p>
                    <p>Conversion rate: {shopper.conversionRateDumpling !== null?shopper.conversionRateDumpling:"??"}%</p>
                    <p>Conversion rate (boosted): {shopper.conversionRateDumplingBoosted !== null?shopper.conversionRateDumplingBoosted:"??"}%</p>
                    <p>Complete orders: {shopper.orderCountWindowDumpling}</p>
                    <p>Canceled orders, 1st time: {shopper.cancelCountWindowDumpling}</p>
                    <p>Cancelation rate, 1st time: {shopper.cancelationRateDumpling !== null?100-shopper.cancelationRateDumpling:"??"}%</p>
                  </div>
                  <hr />
                  <Typography variant="overline" style={{ fontWeight: 600 }}>Combined Stats - 30 Days</Typography>
                  <div style={{marginLeft: '4px'}}>
                    <p>New leads: {shopper.clientCountWindow !== null?shopper.clientCountWindow:"??"}</p>
                    <p>New clients: {shopper.clientCountWindow !== null?Math.round(shopper.clientCountWindow*shopper.conversionRate/100):"??"}</p>
                    <p>Conversion rate: {shopper.conversionRate !== null?shopper.conversionRate:"??"}%</p>
                    <p>Complete orders: {shopper.orderCountWindow}</p>
                    <p>Canceled orders: {shopper.cancelCountWindow}</p>
                    <p>Cancelation rate: {shopper.cancelationRate !== null?100-shopper.cancelationRate:"??"}%</p>
                    <p>Rating: {shopper.ratingWindow}</p>
                    <p>Review count: {shopper.reviewCountWindow}</p> 
                    <p>Repeat clients: {shopper.repeatClientCountWindow}</p>
                  </div>       
                </div>
              </div>
              {/* <p style={{marginTop:'14px', overflow:'hidden'}} class="center-text">{shopper.tagline}</p>  */}
              </div>
            )
          }) :
          <h1>No results</h1>
        }
      </div>
      <div class="column">
        <div class="container">
          <Typography variant="h6" component="h3" style={{marginTop:'10px'}}>
            Exact Zip Code Match, Current scoring algorithm.
          </Typography>
          </div>
        {
          resultList3 !== [] ? 
          resultList3.map((shopper) => {
            console.log(shopper)
            return (
            // <Link to={`/app/owner/${shopper.webPath}}`>

            <div key={shopper.id} className="shopper-result-card" >
              {/* <button type="button" onClick={() => handleNavigateToOwner(shopper)}> */}
              <div class="row" style={((shopper.pausedFromMarketplaceByConversionRate || shopper.pausedFromMarketplaceByCancelationRate) && shopper.unpausedByOperatorUntilTimestamp < nowTimestamp) || shopper.kickedOutZipcode ? {backgroundColor:"#E0E0E0"}:{}}>
                <div style={{flex:'30%'}}>
                  <div class="profile_circle">
                    <img src={shopper.image}/>
                  </div>
                  <p style={{marginLeft:'38px'}}>{shopper['@search.score']}</p>
                </div>
                <div style={{flex:'46%', overflow:'hidden', marginRight: '20px'}} class="column">
                  <p><a href={"../" + shopper.id}><b>{shopper.displayName}</b></a></p> 
                  <p>{shopper.businessName}</p> 
                  <p><a href={"./" + shopper.deliveryAreaZipcode}>{shopper.deliveryAreaZipcode}</a></p> 
                  <p>Paused on Marketplace: {(shopper.pausedFromMarketplaceByConversionRate || shopper.pausedFromMarketplaceByCancelationRate) && shopper.unpausedByOperatorUntilTimestamp < nowTimestamp ? <b>True</b>:"False"}</p>
                  <p>Removed from Marketplace: {shopper.kickedOutZipcode ? <b>True</b>:"False"}</p>
                  <p>Distance: {shopper.distanceMi}</p>
                  <p>Last order: {new Date(shopper.lastOrderTimestamp * 1000).toLocaleDateString("en-US")}</p>
                  <p className="truncate">Location: {shopper.deliveryArea}</p> 
                  <p className="truncate">Tagline: {shopper.tagline}</p>
                  <hr />
                  <Typography variant="overline" style={{ fontWeight: 600 }}>Lifetime Stats</Typography>
                  <div style={{marginLeft: '4px'}}>
                    <p>Rating: {shopper.rating}</p>
                    <p>Review count: {shopper.reviewCount}</p> 
                    <p>Clients: {shopper.clientCount}</p>
                    <p>Repeat clients: {shopper.repeatClientCount}</p>
                    <p>Complete orders: {shopper.orderCount}</p>
                  </div>
                </div>
                <div class="column">
                <Typography variant="overline" style={{ fontWeight: 600 }}>Marketplace Stats - Filter</Typography>
                  <div style={{marginLeft: '4px'}}>
                    <p>New leads: {shopper.clientCountWindowDumplingFilter !== null?shopper.clientCountWindowDumplingFilter:"??"}</p>
                    <p>New clients: {shopper.clientCountWindowDumplingFilter !== null?Math.round(shopper.clientCountWindowDumplingFilter*shopper.conversionRateDumplingFilter/100):"??"}</p>
                    <p>Conversion rate period: {shopper.conversionRateDumplingFilterPeriod !== null?shopper.conversionRateDumplingFilterPeriod:"??"} days</p>
                    <p>Conversion rate: {shopper.conversionRateDumplingFilter !== null?shopper.conversionRateDumplingFilter:"??"}%</p>
                    <p>Cancelation rate, 1st time: {shopper.cancelationRateDumplingFilter !== null?100-shopper.cancelationRateDumplingFilter:"??"}%</p>
                  </div>
                  <hr />
                  <Typography variant="overline" style={{ fontWeight: 600 }}>Marketplace Stats - 30 Days</Typography>
                  <div style={{marginLeft: '4px'}}>
                    <p>New leads: {shopper.clientCountWindowDumpling !== null?shopper.clientCountWindowDumpling:"??"}</p>
                    <p>New clients: {shopper.clientCountWindowDumpling !== null?Math.round(shopper.clientCountWindowDumpling*shopper.conversionRateDumpling/100):"??"}</p>
                    <p>Conversion rate: {shopper.conversionRateDumpling !== null?shopper.conversionRateDumpling:"??"}%</p>
                    <p>Conversion rate (boosted): {shopper.conversionRateDumplingBoosted !== null?shopper.conversionRateDumplingBoosted:"??"}%</p>
                    <p>Complete orders: {shopper.orderCountWindowDumpling}</p>
                    <p>Canceled orders, 1st time: {shopper.cancelCountWindowDumpling}</p>
                    <p>Cancelation rate, 1st time: {shopper.cancelationRateDumpling !== null?100-shopper.cancelationRateDumpling:"??"}%</p>
                  </div>
                  <hr />
                  <Typography variant="overline" style={{ fontWeight: 600 }}>Combined Stats - 30 Days</Typography>
                  <div style={{marginLeft: '4px'}}>
                    <p>New leads: {shopper.clientCountWindow !== null?shopper.clientCountWindow:"??"}</p>
                    <p>New clients: {shopper.clientCountWindow !== null?Math.round(shopper.clientCountWindow*shopper.conversionRate/100):"??"}</p>
                    <p>Conversion rate: {shopper.conversionRate !== null?shopper.conversionRate:"??"}%</p>
                    <p>Complete orders: {shopper.orderCountWindow}</p>
                    <p>Canceled orders: {shopper.cancelCountWindow}</p>
                    <p>Cancelation rate: {shopper.cancelationRate !== null?100-shopper.cancelationRate:"??"}%</p>
                    <p>Rating: {shopper.ratingWindow}</p>
                    <p>Review count: {shopper.reviewCountWindow}</p> 
                    <p>Repeat clients: {shopper.repeatClientCountWindow}</p>
                  </div>       
                </div>
              </div>
              {/* <p style={{marginTop:'14px', overflow:'hidden'}} class="center-text">{shopper.tagline}</p>  */}
              </div>
            )
          }) :
          <h1>No results</h1>
        }
      </div>
    </div>
    </>
  )
}

export default ShopperSearchDebugPage
