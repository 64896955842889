import React, { useState } from "react";
import { Button, MenuItem, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Form } from "react-final-form";
import { TextField, Select } from "mui-rff";
import DatePicker from "@material-ui/pickers/DatePicker";

const styles = (_theme) => ({
  root: {
    flexGrow: 1,
  },
  buttonProgress: {
    //color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -10,
    marginLeft: -12,
  },
  farRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "baseline",
    paddingTop: 10,
  },
  noteDate: {
    marginTop: 20,
  },
});

function NotesPopoverForm(props) {
  const { classes, label, initialValues, submitFunc, name } = props;
  const [loading, setLoading] = useState(false);
  const [noteType, setNoteType] = useState("default");

  async function onSubmit(values) {
    values.noteType = noteType;

    setLoading(true);
    await submitFunc(values);
    setLoading(false);
  }

  async function validate(values) {
    // TODO - check if they actually changed something
    var errors = {};

    if (!(name in values) || values[name] === null) {
      errors[name] = "Please enter something.";
    }

    if (
      (noteType == "coaching" || noteType == "office hours") &&
      values["date"] == null
    ) {
      errors["date"] = "Please enter the session date.";
    }

    if (noteType == "coaching" && values["coach"] == null) {
      errors["coach"] = "Please enter the person who did the coaching.";
    }

    return errors;
  }

  function extraFields() {
    if (noteType == "coaching") {
      return (
        <>
          <TextField
            disabled={loading}
            style={{ width: 480 }}
            label="Coach"
            name="coach"
          />
          <br />
          <TextField className={classes.noteDate} name="date" type="date" />
          <br />
        </>
      );
    }

    if (noteType == "office hours") {
      return (
        <>
          <TextField className={classes.noteDate} name="date" type="date" />
          <br />
        </>
      );
    }
  }

  return (
    <>
      {/* <Typography>The content of the Popover.</Typography> */}
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, values }) => (
          <form
            className={classes.root}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <Select
              name="noteType"
              label="Type"
              onChange={(e) => setNoteType(e.target.value)}
              value={noteType}
            >
              <MenuItem value="default">Support</MenuItem>
              <MenuItem value="coaching">Coaching</MenuItem>
              <MenuItem value="office hours">Office Hours</MenuItem>
            </Select>
            <br />
            {extraFields()}
            <TextField
              disabled={loading}
              multiline={true}
              style={{ width: 480 }}
              label={label}
              name={name}
            />
            <div className={classes.farRight}>
              <Button disabled={loading} type="submit" color="primary">
                Submit
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </div>
          </form>
        )}
      />
    </>
  );
}

export default withStyles(styles)(NotesPopoverForm);
