import React, { Component } from "react";
import "moment-timezone";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import ShopperNote from "./ShopperNote";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
  },
  customItem: {
    color: "black",
    fontWeight: 550,
  },
});

class ShopperNotesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveryValue: null,
      showLoading: false,
    };
  }

  render() {
    const { notes, shopperNoteUpdate } = this.props;

    var notesList = [];
    if (notes) {
      notesList = notes.map((row, _index) => (
        <TableRow key={row.id}>
          <TableCell component="th" scope="row">
            <ShopperNote note={row} showButtons shopperNoteUpdate={shopperNoteUpdate} />
          </TableCell>
        </TableRow>
      ));
    } else {
      notesList = null;
    }
    return (
      <div style={{ width: "100%" }}>
        <Table size="small">
          <TableBody>{notesList}</TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(ShopperNotesTable);
