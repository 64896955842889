import React, { Component } from "react";
import {Map, InfoWindow, Marker, GoogleApiWrapper, Circle} from 'google-maps-react';
import { API } from "aws-amplify";

class MapContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      shoppersDeliveryMap: null,
    };
  }

  async componentDidMount() {
    try {
      const shoppersDeliveryMap = await this.getShoppersDeliveryMap();
      this.setState({ shoppersDeliveryMap });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  getShoppersDeliveryMap() {
    return API.get("shoppers", `/shoppers/delivery-map`);
  }

  onMouseoverMarker(props, marker, e) {
    console.log('mouseover')
  }

  renderCircles(shoppersDeliveryMap) {
    return [{}].concat(shoppersDeliveryMap).map(
      (coord, i) =>
        <Circle
          key={i}
          radius={16000}
          center={{ lat: parseFloat(coord.lat), lng: parseFloat(coord.long) }}
          onMouseover={this.onMouseoverMarker}
          onClick={() => console.log('click')}
          onMouseout={() => console.log('mouseout')}
          strokeColor='#FF0000'
          strokeOpacity={0.6}
          strokeWeight={2}
          fillColor='#FF0000'
          fillOpacity={0.2}
        />
    );
  }

  render() {
    return (
      <Map google={this.props.google}
        zoom={10}
        initialCenter={{
          lat: 47.463644,
          lng: -122.097151
        }}>
        <Marker />
        {this.state.shoppersDeliveryMap && this.renderCircles(this.state.shoppersDeliveryMap)}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyC3MJHUP-pqrp3GLF_qYuVnf83gKogypXk')
})(MapContainer)
