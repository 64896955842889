import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { API } from "aws-amplify";


export default function ShopperAutocomplete(props) {
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("")
  const [options, setOptions] = React.useState([]);
  //const starting = options.length === 0 && inputValue;
  const [loading, setLoading] = React.useState(false)


  React.useEffect(() => {
    let active = true;

    if (!inputValue) {
        setOptions([])
        return undefined
    }
    // if (!loading) {
    //   return undefined;
    // }

    (async () => {
      setLoading(true)  // TODO - i don't like how this looks but the loading attribute on the autocomplete is not working how i want it
      const response = await API.get('shoppers', `/shoppers/search`, {
        'queryStringParameters': {
          // these are all required for reopen
          'query': inputValue,
          'mode': 'suggest'
        }
      });
     setLoading(false)

      if (active) {
        if (response) {
            setOptions(response)
        } 
      }
    })();


    return () => {
      active = false;
    };
  }, [inputValue]);


  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      freeSolo
      style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
    //   getOptionSelected={(option, value) => option.name === value.name}
    //   getOptionLabel={(option) => option.name}
      options={options}
      onInputChange={(event, value, reason) => {
          if (reason === 'input') { 
            setInputValue(value)
          }
        }}
      onChange={async (event, value, reason) => {
          if (reason === 'select-option' || reason === 'create-option') {
              setLoading(true)
              await props.search(value)
              setLoading(false)
          }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Name Search"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
