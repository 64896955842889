import React, { Component } from "react";
import MaterialTable from "material-table";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import { withStyles } from "@material-ui/core/styles";
import "moment-timezone";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  address: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
  },
  customItem: {
    color: "black",
    fontWeight: 550,
  },
  table: {
    minWidth: 650,
  },
  transactionPrice: {
    color: "black",
    fontWeight: 550,
  },
});

class ShopperDangleTransactions2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          isLoading={!this.props.transactions || this.state.showLoading}
          actions={[
            (rowData) => ({
              icon: rowData.ignore ? "visibility" : "visibility_off",
              tooltip: rowData.ignore
                ? "Show transaction"
                : "Ignore transaction",
              onClick: async (event, rowData) => {
                // if (window.confirm("Are you sure you want to cancel this order?")) {
                this.setState({ showLoading: true });
                await this.props.ignore(rowData);
                this.setState({ showLoading: false });
                //  }
              },
            }),
          ]}
          options={{
            search: false,
            pageSize: 3,
            pageSizeOptions: [3, 10, 20],
            filtering: true,
            rowStyle: (rowData) => {
              return rowData.ignore === true
                ? { color: `rgba(0, 0, 0, 0.38)` }
                : {}; // I can't figure out how to access themes here (this is theme.palette.text.disabled)
            },
          }}
          columns={[
            {
              title: "Status",
              field: "status",
              cellStyle: (status) => {
                return status === "Dangling" ? { color: "#f44336" } : {}; // I can't figure out how to access themes here (this is theme.palette.error.main)
              },
              lookup: { Ignore: "Ignore", Open: "Open", Dangling: "Dangling" },
            },
            {
              title: "Amount",
              field: "transactionAmount",
              editable: "never",
              filtering: false,
              render: (rowData) => (
                <NumberFormat
                  value={rowData.transactionAmount / 100.0}
                  displayType={"text"}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={"$"}
                />
              ),
            },
            {
              title: "Card",
              field: "cardAcctId",
              editable: "never",
              filtering: false,
              render: (rowData) =>
                rowData.cardAcctId.includes("ic_")
                  ? "Stripe " + rowData.webhook.card.last4
                  : "PEX",
            },
            {
              title: "Merchant",
              field: "merchantName",
              editable: "never",
              filtering: false,
            },
            {
              title: "City",
              field: "merchantCity",
              editable: "never",
              filtering: false,
            },
            {
              title: "State",
              field: "merchantState",
              editable: "never",
              filtering: false,
            },
            {
              title: "Zip",
              field: "merchantZip",
              editable: "never",
              filtering: false,
            },
            {
              title: "Transaction Date",
              field: "transactionUtcTime",
              editable: "never",
              filtering: false,
              defaultSort: "desc", // this list could be from different sources so we need to apply this filter
              render: (rowData) => (
                <Moment
                  format="MMM DD, YYYY h:mm A z"
                  tz="America/New_York"
                >{`${rowData.transactionUtcTime}Z`}</Moment>
              ),
            },
            {
              title: "Age",
              field: "transactionUtcTime",
              editable: "never",
              filtering: false,
              render: (rowData) => (
                <Moment fromNow>{`${rowData.transactionUtcTime}Z`}</Moment>
              ),
            },
          ]}
          data={this.props.transactions}
          title="Detached Transactions"
          padding="dense"
        />
      </div>
    );
  }
}

export default withStyles(styles)(ShopperDangleTransactions2);
