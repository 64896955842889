import React, { Component } from "react";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import "./App.css";
import Routes from "./Routes";
import { withAuthenticator } from '@aws-amplify/ui-react';
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
import Layout from './containers/Layout';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: green[500]
    },
    primary: {
      main: green[600]
    },
    error:  {
      main: red[500],
      light: red[50]
    }
  },
  overrides: {
    MuiTableCell: {
      paddingCheckbox: {
        // increase the padding fo the far left title when it's an action
        padding: '0px 0px 0px 16px',
      },
    },
  },
});


class App extends Component {
  render() {
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Layout>
            <Routes />
          </Layout>
        </MuiThemeProvider>
      </div>
    );
  }
}

const signUpConfig = {
  hideAllDefaults: true,
  signUpFields: [
    {
      label: 'Email',
      key: 'username',
      required: true,
      placeholder: 'Email',
      type: 'email',
      displayOrder: 1,
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      placeholder: 'Password',
      type: 'password',
      displayOrder: 2,
    },
  ],
};

export default withAuthenticator(App, {
                // Render a sign out button once logged in
                includeGreetings: false,
                signUpConfig
              });
