import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Field } from "react-final-form";

function UploadWrapper(props) {
  const {
    input: { onChange },
    label,
  } = props;

  const [filename, setFilename] = useState(null);

  function onLocalChange(event) {
    const file = event.target.files[0];

    setFilename(file.name);

    onChange(event.target.files);
  }

  return (
    <Button component="label" onChange={onLocalChange}>
      {!!filename ? filename : label}
      <input type="file" hidden />
    </Button>
  );
}

function Upload(props) {
  const { label, name, type, fieldProps, ...rest } = props;

  return (
    <Field
      name={name}
      render={({ input }) => (
        <UploadWrapper input={input} label={label} {...rest} />
      )}
      {...fieldProps}
    />
  );
}

export default Upload;
