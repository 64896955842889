import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Layout from "./containers/Layout";
import Profile from "./containers/Profile";
import Coupons from "./containers/coupons/Coupons";
import Orders from "./containers/orders/Orders";
import Shoppers from "./containers/shoppers/Shoppers";
import Shopper from "./containers/shoppers/Shopper";
import DeliveryMap from "./containers/shoppers/DeliveryMap";
import Clients from "./containers/clients/Clients";
import Client from "./containers/clients/Client";
import NotFound from "./containers/NotFound";
import ShopperSearchDebugPage from "./containers/shoppers/ShopperSearchDebugPage";

export default () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/profile" exact component={Profile} />
    <Route path="/layout" exact component={Layout} />
    <Route path="/shoppers" exact component={Shoppers} />
    <Route path="/shoppers/:id" exact component={Shopper} />
    <Route path="/delivery-map" exact component={DeliveryMap} />
    <Route path="/clients" exact component={Clients} />
    <Route path="/clients/:id" exact component={Client} />
    <Route
      path="/shoppers/search/:zipcode"
      exact
      component={ShopperSearchDebugPage}
    />
    <Route path="/orders" exact component={Orders} />
    <Route path="/coupons" exact component={Coupons} />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
