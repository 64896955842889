import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  buttonProgress: {
    //color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -12,
  },
  farRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    paddingTop: 10
  }
})


function WebPathPopoverForm(props) {
  const {classes, label, initialValues, submitFunc, name} = props
  const [loading, setLoading] = React.useState(false)

  async function onSubmit(values) {
    setLoading(true)
    await submitFunc(values)
    setLoading(false)
  }

  async function validate(values) {
    // TODO - check if they actually changed something

    if (!(name in values) || values[name] === null) { 
      return { [name]: 'Please enter something.' };
    }
    // make sure it is all lowercase
    const valueLower = values[name].toLowerCase()
    if (valueLower !== values[name]) {
      return { [name]: 'Must be all lowercase.' };
    }
    // make sure there are no special characters
    const reg = /[^a-zA-Z0-9-]/g
    if (values[name].match(reg)) {
      return { [name]: 'No special characters.  Only letters, numbers and hyphen.' };
    }
    return;
  }
  
  return ( 
    <>
      {/* <Typography>The content of the Popover.</Typography> */}
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, values }) => (
          <form className={classes.root} onSubmit={handleSubmit} noValidate autoComplete="off">
            <>
              <TextField disabled={loading} style={{ width: 200 }} label={label} name={name} InputProps={{ endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  </React.Fragment>
                )}} />
              <Typography style={{paddingTop: 20, width: 200}} variant="body2">Vanity changes are reserved for Pro accounts.</Typography>
            </>
          </form>
        )}
      />
    </>
  );
}

export default withStyles(styles)(WebPathPopoverForm)
