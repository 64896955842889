import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Select } from 'mui-rff';
import { MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CustomizedDialogs from './FundingProfileDialog'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  buttonProgress: {
    //color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: 24,
    marginLeft: -60,
  },
  sideBySide: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  }
})


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      fixedDecimalScale  // need to set this to always show zeros so the string to int math always works
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,  // we are using the string representation to avoid float multiplication issues
          },
        });
      }}
      thousandSeparator
      prefix="$"
      decimalScale={2}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function PopoverForm(props) {
  const {classes, label, initialValues, submitFunc, name} = props
  const [loading, setLoading] = React.useState(false)
  //const [openDialog, setOpenDialog] = React.useState(false)

  async function onSubmit(values) {
    if (name === "fundingProfile") {
      if (window.confirm("Changing a shopper's funding profile will persist after today.  Please confirm you wish to make this change.")) {
        setLoading(true)
        await submitFunc(values)
        setLoading(false)
      }
    } else {
      setLoading(true)
      await submitFunc(values)
      setLoading(false)
    }
  }

  async function validate(values) {
    // TODO - check if they actually changed something

    if (!(name in values) || values[name] === null) {  // note, we want to let zeros through but not null
      return { [name]: 'Please enter something.' };
    }
    return;
  }
  
  return ( 
    <>
      {/* <Typography>The content of the Popover.</Typography> */}
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, values }) => (
          <form className={classes.root} onSubmit={handleSubmit} noValidate autoComplete="off">
            {name === "fundingProfile" ?
                <>
                <Select style={{ minWidth: 160 }} name={name} label={label} formControlProps={{ margin: 'normal' }} >
                  <MenuItem value="basic">Basic</MenuItem>
                  <MenuItem value="newbie">Newbie</MenuItem>
                  <MenuItem value="standard">Standard</MenuItem>
                  <MenuItem value="trusted">Trusted</MenuItem>
                  <MenuItem value="vip">VIP</MenuItem>
                  <MenuItem value="baomaster">Bao Master</MenuItem>
                  <MenuItem value="haithamspecial">Haitham Special</MenuItem>
                  <MenuItem value="pao">Pao 💥</MenuItem>
                  <MenuItem value="resort">Resort ⛷</MenuItem>
                </Select>
                <div className={classes.sideBySide}>
                <Button disabled={loading} type="submit" color="primary">Submit</Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                <CustomizedDialogs />
                </div>
              </>
            :
              <>
                <TextField disabled={loading} style={{ width: 160 }} label={label} name={name} InputProps={{ inputComponent: NumberFormatCustom, endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  </React.Fragment>
                )}} />
                {name === 'transactionLimit' ? <Typography style={{paddingTop: 20, width: 160}} variant="body2">This will be reset at 9pm Pacific time.</Typography> :  <Typography style={{paddingTop: 20, width: 160}} variant="body2">Additonal funding will persist after today.</Typography>}
              </>
            }
            {/* <pre>{JSON.stringify(values)}</pre> */}
          </form>
        )}
      />
    </>
  );
}

export default withStyles(styles)(PopoverForm)
