import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { Select } from "mui-rff";
import { MenuItem } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  buttonProgress: {
    //color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: 48,
    marginLeft: -82,
  },
  sideBySide: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  info: {
    verticalAlign: 'middle'
  },
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      fixedDecimalScale // need to set this to always show zeros so the string to int math always works
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value, // we are using the string representation to avoid float multiplication issues
          },
        });
      }}
      thousandSeparator
      prefix="$"
      decimalScale={2}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function MCCPopoverForm(props) {
  const { classes, initialValues, submitFunc } = props;
  const [loading, setLoading] = React.useState(false);
  //const [openDialog, setOpenDialog] = React.useState(false)

  async function onSubmit(values) {
    values.extraMcc = (values.extraMcc || "").trim()

    if (values.extraMcc === "") {
      window.alert("MCC code can't be empty.");
      return;
    }

    if (values.extraMcc.length !== 4) {
      window.alert("MCC code has to be 4 digits long.");
      return;
    }

    if (values.extraMcc == "5993") {
      window.alert("MCC cannot be for cigars.");
      return;
    }

    if (values.expiresAt === "" || values.expiresAt === null) {
      if (
        !window.confirm(
          "Please confirm that you wish to add this MCC code permanently for this shopper?"
        )
      ) {
        return;
      }
    }

    setLoading(true);
    await submitFunc(values);
    setLoading(false);
  }

  const handleMCCInfoButton = () => {
    window.open("https://stripe.com/docs/issuing/categories");
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => {
        return (
          <form
            className={classes.root}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="MCC"
              name="extraMcc"
              style={{ minWidth: 100 }}
            />
            <Select
              name="expiresAt"
              label="Expiration"
              formControlProps={{ margin: "normal" }}
              defaultValue="never"
            >
              <MenuItem value="">Never</MenuItem>
              <MenuItem value="1h">1 hour</MenuItem>
              <MenuItem value="1d">1 day</MenuItem>
              <MenuItem value="1w">1 week</MenuItem>
            </Select>
            <div className={classes.sideBySide}>
              <Button disabled={loading} type="submit" color="primary" >
                Submit
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
              <div>
                <IconButton aria-label="info" onClick={handleMCCInfoButton}>
                  <InfoIcon />
                </IconButton>
              </div>
            </div>
          </form>
        );
      }}
    />
  );
}

export default withStyles(styles)(MCCPopoverForm);
