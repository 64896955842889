import React, { useState } from "react";
import { API } from "aws-amplify";
import MaterialTable from "material-table";
import Moment from "react-moment";
import { withStyles } from "@material-ui/core/styles";
import { Chip, Link } from "@material-ui/core";
import moment from "moment";
import "moment-timezone";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  address: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
  },
  customItem: {
    color: "black",
    fontWeight: 550,
  },
  table: {
    minWidth: 650,
  },
  transactionPrice: {
    color: "black",
    fontWeight: 550,
  },
  chipCanceled: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
    color: "#ffffff",
  },
  chipCreated: {
    margin: theme.spacing(1),
  },
  chipComplete: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
  },
});

function OrdersTable(props) {
  const { classes } = props;

  const [loading, setLoading] = useState(false);

  function doSearch(query) {
    return new Promise((resolve, reject) => {
      setLoading(true);

      const sort =
        query.orderBy && query.orderDirection
          ? { column: query.orderBy.field, direction: query.orderDirection }
          : null;

      API.get("orders", "/orders/search", {
        queryStringParameters: {
          query: query.search || "",
          top: query.pageSize,
          skip: query.page * query.pageSize,
          sortColumn: sort?.column,
          sortDirection: sort?.direction,
        },
      })
        .then((response) => {
          resolve({
            data: response.results,
            page: parseInt(response.skip / query.pageSize),
            totalCount: response.count,
          });
        })
        .catch(() => reject())
        .finally(() => setLoading(false));
    });
  }

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        title="Orders"
        data={doSearch}
        isLoading={loading}
        options={{
          pageSize: 20,
          pageSizeOptions: [20],
          sorting: true,
          debounceInterval: 500,
        }}
        columns={[
          {
            title: "Id",
            field: "orderId",
            editable: "never",
            render: (rowData) =>
              rowData.orderId ? rowData.orderId.split("-", 1) : "Not Available",
          },
          {
            title: "Status",
            field: "orderStatus",
            editable: "never",
            render: (rowData) => (
              <Chip
                label={rowData.orderStatus}
                className={classes[`chip${rowData.orderStatus}`]}
              />
            ),
          },
          {
            title: "Client",
            field: "clientName",
            editable: "never",
            render: (rowData) => (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={`/clients/${rowData.clientId}`}
              >
                {rowData.clientName || "N/A"}
              </Link>
            ),
          },
          {
            title: "Shopper",
            field: "shopperName",
            editable: "never",
            render: (rowData) => (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={`/shoppers/${rowData.shopperId}`}
              >
                {rowData.shopperName || "N/A"}
              </Link>
            ),
          },
          {
            title: "Delivery Date",
            field: "deliveryTimestampUtc",
            editable: "never",
            render: (rowData) => (
              <Moment format="MMM DD, YYYY hh:mm a ZZ">
                {moment
                  .unix(rowData.deliveryTimestampUtc)
                  .utcOffset(rowData.utcOffset)}
              </Moment>
            ),
          },
          {
            title: "Delivery",
            field: "delivery",
            editable: "never",
            sorting: false,
            render: (rowData) => (
              <div>
                {rowData.deliveryAddress1}
                {rowData.deliveryAddress2
                  ? ", " + rowData.deliveryAddress2
                  : ""}{" "}
                - {rowData.deliveryCity}, {rowData.deliveryProvince}{" "}
                {rowData.deliveryPostalCode}
              </div>
            ),
          },
          {
            title: "Order Created",
            field: "orderTimestamp",
            editable: "never",
            render: (rowData) => (
              <Moment format="MMM DD, YYYY hh:mm a ZZ">
                {moment
                  .unix(rowData.orderTimestamp)
                  .utcOffset(rowData.utcOffset)}
              </Moment>
            ),
          },
          {
            title: "Store",
            field: "storeName",
            editable: "never",
          },
        ]}
      />
    </div>
  );
}

export default withStyles(styles)(OrdersTable);
