import React, {useEffect} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Typography from '@material-ui/core/Typography';
import { Auth, Hub } from "aws-amplify";
import QRCode from 'qrcode.react'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  buttonProgress: {
    //color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: 24,
    marginLeft: -60,
  },
  sideBySide: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  }
})


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      format="###-###"
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,  // we are using the string representation to avoid float multiplication issues
          },
        });
      }}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function TOTPPopoverForm(props) {
  const {classes, label, initialValues, submitFunc, name, popupState} = props
  const [loading, setLoading] = React.useState(false)
  const [radio1Loading, setRadio1Loading] = React.useState(false)
  const [radio2Loading, setRadio2Loading] = React.useState(false)
  const [qrcode, setQrcode] = React.useState('')
  const [mfaPref, setMfaPref] = React.useState('')
  //const [openDialog, setOpenDialog] = React.useState(false)


  useEffect(() => {
    async function preferredMFA() {
      let user = await Auth.currentAuthenticatedUser();
      let data = await Auth.getPreferredMFA(user,{
        // Optional, by default is false. 
        // If set to true, it will get the MFA type from server side instead of from local cache.
        bypassCache: false 
      })
      setMfaPref(data)
    }
    preferredMFA()
  }, [])



  async function handleChange(e) {
    const { name, value } = e.target;
    let user = await Auth.currentAuthenticatedUser();
    switch (value) {
      case "SMS_MFA":
        setRadio1Loading(true)
        setMfaPref('SMS_MFA')
        await Auth.setPreferredMFA(user, 'SMS');
        setRadio1Loading(false)
        popupState.close()
        break;
      case "SOFTWARE_TOKEN_MFA":
        setRadio2Loading(true)
        setMfaPref('SOFTWARE_TOKEN_MFA')
        const { attributes, username } = user;
        const issuer = 'AWSCognito'
        const code = await Auth.setupTOTP(user)
        const config = process.env.REACT_APP_STAGE === "prod" ? 'DumplingBao' : 'DevDumplingBao'
        const qrcode = "otpauth://totp/" + config + ":"+ attributes.email + "?secret=" + code + "&issuer=" + issuer;
        setQrcode(qrcode)
        setRadio2Loading(false)
        break;
    }
  }



  async function onSubmit(values) {
    // check for 10 digits
    if (values[name].length != 6) {
      return { [name]: 'Must be 6 digits.' };
    }
    setLoading(true)
    await submitFunc(values)
    setLoading(false)
  }

  async function validate(values) {
    // TODO - check if they actually changed something
    return;
  }
  
  return ( 
    <>
      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">MFA Preference</FormLabel>
          <RadioGroup aria-label="mfaPref" name="mfaPref" value={mfaPref} onChange={handleChange}>
            <div className={classes.sideBySide}>
              <FormControlLabel value="SMS_MFA" control={<Radio />} label="SMS" />
              {radio1Loading? <CircularProgress color="inherit" size={20} /> : null}
            </div>
            <div className={classes.sideBySide}>
              <FormControlLabel value="SOFTWARE_TOKEN_MFA" control={<Radio />} label="TOTP" />
              {radio2Loading? <CircularProgress color="inherit" size={20} /> : null}
            </div>
          </RadioGroup>
        </FormControl> 
      </div>
      <div>
        {qrcode ? <>
          <QRCode value={qrcode}/>
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={validate}
            render={({ handleSubmit, values }) => (
              <form className={classes.root} onSubmit={handleSubmit} noValidate autoComplete="off">
                <>
                  <TextField disabled={loading} style={{ width: 160 }} label={label} name={name} InputProps={{ inputComponent: NumberFormatCustom, endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    </React.Fragment>
                  )}} />
                  <Typography style={{paddingTop: 20, width: 160}} variant="body2">Scan QR code with Authenticator app, then enter code to set MFA.</Typography>
                </>
                {/* <pre>{JSON.stringify(values)}</pre> */}
              </form>
            )}
          />
        </> : null }
      </div>
    </>
  );
}

export default withStyles(styles)(TOTPPopoverForm)
