import React from "react";
import MaterialTable from "material-table";
import NumberFormat from "react-number-format";
import { Chip, Link, Box, Grid, Popover, Typography } from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

import Moment from "react-moment";
import moment from "moment";
import "moment-timezone";

import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    width: 500,
    marginRight: theme.spacing(2),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  sideBySideRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  greenButtonText: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
});

const booleanIcons = {
  true: "✅",
  false: "❌",
};

function CouponTable(props) {
  const { classes, coupons, loading } = props;

  function renderTimeCondition(name, value) {
    let caption = name;
    let renderedValue = value;

    if (name == "minTime") {
      caption = "From";
      renderedValue = (
        <Moment format="MMM DD, YYYY hh:mm a">{moment.unix(value)}</Moment>
      );
    }

    if (name == "maxTime") {
      caption = "To";
      renderedValue = (
        <Moment format="MMM DD, YYYY hh:mm a">{moment.unix(value)}</Moment>
      );
    }

    return (
      <>
        <Grid item xs={8}>
          <strong>{caption}</strong>
        </Grid>
        <Grid item xs={4}>
          {renderedValue}
        </Grid>
      </>
    );
  }

  function renderShopperCondition(name, value) {
    let caption = name;
    let renderedValue = value;

    if (name == "maxOrderCount") {
      caption = "Max. number of orders";
    }

    if (name == "validShopperIds") {
      caption = "Only Shoppers";
      renderedValue = value.map((el) => (
        <>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={`/shoppers/${el}`}
          >
            {el.split("-", 1)}
          </Link>
          <br />
        </>
      ));
    }

    if (name == "serviceName") {
      caption = "Store";
    }

    if (name == "clientUseCount") {
      caption = "Clients allowed";
    }

    return (
      <>
        <Grid item xs={8}>
          <strong>{caption}</strong>
        </Grid>
        <Grid item xs={4}>
          {renderedValue}
        </Grid>
      </>
    );
  }

  function renderClientCondition(name, value) {
    let caption = name;
    let renderedValue = value;

    if (name == "mustBeFirstOrder") {
      caption = "Only first order";
      renderedValue = booleanIcons[true];
    }

    if (name == "mustNotBeFirstOrder") {
      caption = "Every order except first";
      renderedValue = booleanIcons[true];
    }

    if (name == "mustBeFirstNOrders") {
      caption = "Only for the first N orders";
    }

    if (name == "usesPerClient") {
      caption = "Uses allowed";
    }

    if (name == "validClientIds") {
      caption = "Only Clients";
      renderedValue = value.map((el) => (
        <>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={`/clients/${el}`}
          >
            {el.split("-", 1)}
          </Link>
          <br />
        </>
      ));
    }

    return (
      <>
        <Grid item xs={8}>
          <strong>{caption}</strong>
        </Grid>
        <Grid item xs={4}>
          {renderedValue}
        </Grid>
      </>
    );
  }

  function renderWhoPays(name, value) {
    let caption = name;
    let renderedValue = value;

    if (name == "percentDeliveryFeePaidByShopper") {
      caption = "Delivery fee paid by shopper (%)";
      renderedValue = <span>{value}%</span>;
    }

    if (name == "percentPlatformFeePaidByShopper") {
      caption = "Platform fee paid by shopper (%)";
      renderedValue = <span>{value}%</span>;
    }

    if (name == "percentAbsoluteDiscountPaidByShopper") {
      caption = "Discount paid by shopper ($)";
      renderedValue = (
        <NumberFormat
          value={value / 100.0}
          displayType={"text"}
          thousandSeparator={true}
          fixedDecimalScale={true}
          decimalScale={2}
          prefix={"$"}
        />
      );
    }

    if (name == "percentPercentDiscountPaidByShopper") {
      caption = "Discount paid by shopper (%)";
      renderedValue = <span>{value}%</span>;
    }

    return (
      <>
        <Grid item xs={8}>
          <strong>{caption}</strong>
        </Grid>
        <Grid item xs={4}>
          {renderedValue}
        </Grid>
      </>
    );
  }
  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        title="Coupons"
        data={coupons}
        isLoading={!coupons || loading}
        options={{
          pageSize: 25,
          pageSizeOptions: [25],
          debounceInterval: 500,
          padding: "dense",
        }}
        columns={[
          {
            title: "Code",
            field: "couponCode",
            editable: "never",
            render: (rowData) => <strong>{rowData.couponCode}</strong>,
          },
          {
            title: "Line Item Name",
            field: "displayName",
            editable: "never",
          },
          {
            title: "Value",
            field: "defaultCouponValue",
            editable: "never",
            render: (rowData) => (
              <NumberFormat
                value={rowData.defaultCouponValue / 100.0}
                displayType={"text"}
                thousandSeparator={true}
                fixedDecimalScale={true}
                decimalScale={2}
                prefix={"$"}
              />
            ),
          },
          {
            title: "Discount",
            editable: "never",
            render: (rowData) => {
              let discounts = [];
              if (rowData.couponEffects) {
                if (rowData.couponEffects.absoluteDiscount) {
                  discounts.push(
                    <NumberFormat
                      value={rowData.couponEffects.absoluteDiscount / 100.0}
                      displayType={"text"}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={"$"}
                    />
                  );
                }
                if (rowData.couponEffects.percentDiscount) {
                  discounts.push(
                    <span>{rowData.couponEffects.percentDiscount}%</span>
                  );
                }
              }

              if (discounts.length > 0) {
                return discounts.reduce((prev, curr) => [prev, " / ", curr]);
              }

              return "None";
            },
          },
          {
            title: "Waive Delivery Fee",
            field: "waveDeliveryFee",
            editable: "never",
            render: (rowData) =>
              booleanIcons[!!rowData?.couponEffects?.waiveDeliveryFee],
          },
          {
            title: "Waive Platform Fee",
            field: "wavePlatformFee",
            editable: "never",
            render: (rowData) =>
              booleanIcons[!!rowData?.couponEffects?.waivePlatformFee],
          },
          {
            title: "Min. order total",
            field: "minOrderTotal",
            editable: "never",
            render: (rowData) =>
              rowData?.postConditions?.minOrderTotal && (
                <NumberFormat
                  value={rowData?.postConditions?.minOrderTotal / 100.0}
                  displayType={"text"}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={"$"}
                />
              ),
          },
          {
            title: "Conditions",
            field: "preConditions",
            editable: "never",
            render: (rowData) => (
              <Grid item xs={5}>
                <div>
                  <PopupState variant="popover" popupId="demo-popup-popover">
                    {(popupState) => (
                      <div className={classes.sideBySideRow}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Typography
                            {...bindTrigger(popupState)}
                            displayType={"text"}
                            className={classes.greenButtonText}
                            style={{ cursor: "pointer", width: 80 }}
                          >
                            Details (
                            {Object.keys(rowData?.preConditions?.shopper || {})
                              .length +
                              Object.keys(rowData?.preConditions?.client || {})
                                .length}
                            )
                          </Typography>
                        </div>
                        <Popover
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Box
                            className={classes.paper}
                            style={{ width: 450, marginRight: 0 }}
                          >
                            {rowData?.preConditions?.time && (
                              <>
                                <Typography variant="h5">Time</Typography>
                                <Grid container>
                                  {Object.keys(
                                    rowData?.preConditions?.time || {}
                                  ).map((name) =>
                                    renderTimeCondition(
                                      name,
                                      rowData.preConditions.client[name]
                                    )
                                  )}
                                </Grid>
                                <br />
                              </>
                            )}
                            {rowData?.preConditions?.shopper && (
                              <>
                                <Typography variant="h5">Shopper</Typography>
                                <Grid container>
                                  {Object.keys(
                                    rowData?.preConditions?.shopper || {}
                                  ).map((name) =>
                                    renderShopperCondition(
                                      name,
                                      rowData.preConditions.shopper[name]
                                    )
                                  )}
                                </Grid>
                                <br />
                              </>
                            )}
                            {rowData?.preConditions?.client && (
                              <>
                                <Typography variant="h5">Client</Typography>
                                <Grid container>
                                  {Object.keys(
                                    rowData?.preConditions?.client || {}
                                  ).map((name) =>
                                    renderClientCondition(
                                      name,
                                      rowData.preConditions.client[name]
                                    )
                                  )}
                                </Grid>
                              </>
                            )}
                          </Box>
                        </Popover>
                      </div>
                    )}
                  </PopupState>
                </div>
              </Grid>
            ),
          },
          {
            title: "Payment details",
            field: "whoPays",
            editable: "never",
            render: (rowData) => (
              <Grid item xs={5}>
                <div>
                  <PopupState variant="popover" popupId="demo-popup-popover">
                    {(popupState) => (
                      <div className={classes.sideBySideRow}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Typography
                            {...bindTrigger(popupState)}
                            displayType={"text"}
                            className={classes.greenButtonText}
                            style={{ cursor: "pointer", width: 80 }}
                          >
                            Details
                          </Typography>
                        </div>
                        <Popover
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Box
                            className={classes.paper}
                            style={{ width: 500, marginRight: 0 }}
                          >
                            <Grid container>
                              {Object.keys(rowData?.whoPays || {}).map((name) =>
                                renderWhoPays(name, rowData.whoPays[name])
                              )}
                            </Grid>
                          </Box>
                        </Popover>
                      </div>
                    )}
                  </PopupState>
                </div>
              </Grid>
            ),
          },
        ]}
      />
    </div>
  );
}

export default withStyles(styles)(CouponTable);
