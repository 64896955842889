import React, { Component } from "react";
import "../Home.css";
import { API } from "aws-amplify";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import Container from '@material-ui/core/Container'
import ShopperAutocomplete from './ShopperAutocomplete.js'
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

// check for a valid phone number
const phoneRegExp = /^(?:\+?1|1?)([0-9]{10})$/

// remove spaces and dashes from a string
function stripPhone(phone) {
  return phone.replace(/\s/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '')
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2, 2),
    marginBottom: theme.spacing(2)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  buttonWrapper: {
    position: 'relative',
  },
  button: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
  buttonProgress: {
    //color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: 2,
    marginLeft: -6,
  },
  bigAvatar: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 0,
    width: 100,
    height: 100,
  },
  header: {
    color: theme.palette.text.secondary
  },
  mega: {
    paddingBottom: 14
  }
});


class Shoppers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      shopper: null,
      shopperNotFound: false,
      phone: '',
      shopperId: ''
    };
  }

  validate = values => {
    const errors = {};
    
    if ((!values.submitType && values.phone) || (values.submitType === "phone" && values.phone)) {
      const stripedPhone = stripPhone(values.phone)

      if (!stripedPhone.match(phoneRegExp)) {
        errors.phone = "Not a valid phone number"
      }
    }
    if ((!values.submitType && values.shopperID) || (values.submitType === "shopperID" && values.shopperID)) {
      const stripedShopperID = stripPhone(values.shopperID)

      if (stripedShopperID.length !== 32) {
        errors.shopperID = "Not a valid user id"
      }
    }

    if ((!values.submitType && values.zipcode) || (values.submitType === "zipcode" && values.zipcode)) {
      const stripedZipcode = stripPhone(values.zipcode)

      if (stripedZipcode.length !== 5) {
        errors.zipcode = "Not a valid zipcode"
      }
    }

    return errors;
  };

  onSubmit = async values => {
    const errors = {};
    const queryStringParameters = {};

    // check which button was pressed and validate accordingling
    switch (values.submitType) {
      case "phone":
        if (!values.phone) {
          errors.phone = 'Required';
          return errors
        }
        // clean it up before submitting (remove +1 or 1)
        const match = phoneRegExp.exec(stripPhone(values.phone))
        if (match && match[1]) {
          queryStringParameters.phone = match[1]
        } else {
          // this should not happen
          errors.phone = "Unknown error"
          return errors
        }
        break
      case "shopperID":
        if (!values.shopperID) {
          errors.shopperID = 'Required';
          return errors;
        }
        // put the id in the correct uid format if it's not already
        const stripedShopperID = stripPhone(values.shopperID)
        const cleanShopperID = stripedShopperID.substr(0,8) + '-' + stripedShopperID.substr(8,4) + '-' + stripedShopperID.substr(12,4) + '-' + stripedShopperID.substr(16,4) + '-' + stripedShopperID.substr(20,12)
        queryStringParameters.id = cleanShopperID
        break
      default:
        break
    }

    this.setState({
      'shopper': null,
      'shoppers': [],
      'shopperNotFound': false
    })

    const response = await API.get('shoppers', '/shoppers', {
      queryStringParameters
    }).catch(error => {
      console.log(error.response)
    });

    if (!isEmpty(response)) {
      // this returns just one person so forcing it into an array until we switch everything over to mega search
      this.setState({
        'shoppers': [response],
        'shopperNotFound': false
      })
    } else {
      this.setState({
        'shoppers': null,
        'shopperNotFound': true
      })
    }

    // reset the submitType so if the user switches to a different submit button it won't error on the prev
    delete values.submitType
  };

  onSubmitZipcode = async values => {
    const errors = {};
    const queryStringParameters = {};

    // check which button was pressed and validate accordingling
    switch (values.submitType) {
      case "zipcode":
          if (!values.zipcode) {
            errors.zipcode = 'Required';
            return errors;
          }
          // put the id in the correct uid format if it's not already
          this.props.history.push(`./shoppers/search/${values.zipcode}`)
          break
      default:
        break
    }

    // reset the submitType so if the user switches to a different submit button it won't error on the prev
    delete values.submitType
  };

  renderShopperPreview(shoppers) {
    const { classes } = this.props;
    if (shoppers) {
      return shoppers.map( (theShopper, i) => (
        <Paper key={i} className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs>
              <Avatar alt="Shopper Image" src={theShopper.image} className={classes.bigAvatar} />
          </Grid>
          <Grid item xs={10} container direction="column" justify="center">
            <Grid item xs container direction="row" spacing={2}>
              <Grid item>
                <Typography gutterBottom variant="h5">
                  {theShopper.firstName} {theShopper.lastName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h5">
                  -
                </Typography>
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h5">
                    {theShopper.businessName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs container direction="row" alignItems="flex-start" spacing={2}>
              <Grid item>
                <Typography gutterBottom variant="body1">
                  <span className={classes.header}>Phone</span>
                  &nbsp;&nbsp;
                  <NumberFormat format="###-###-####" value={theShopper.phone ? theShopper.phone.slice(2) : null} displayType={'text'}/>
                </Typography>
              </Grid>
              <Grid item>
                {theShopper.businessPhone &&
                  <Typography gutterBottom variant="body1">
                    <span className={classes.header}>Biz Phone</span>
                    &nbsp;&nbsp;
                    <NumberFormat format="###-###-####" value={theShopper.businessPhone.slice(2)} displayType={'text'}/>
                  </Typography>
                }
              </Grid>
            </Grid>
            <Grid item xs>
              <Button variant="contained" component={Link} color="primary" to={`/shoppers/${theShopper.id}`}>
                Details
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      ))
    }
  }

  search = async (value) => {
    this.setState({ loading: true });
  
    const response = await API.get('shoppers', '/shoppers/search', {
      'queryStringParameters': {
        'query': value
      }
    });
    if (!isEmpty(response)) {
      this.setState({
        'shoppers': response
      })
    } else {
      this.setState({
        'shoppers': []
      })
    }
    this.setState({ loading: false });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={6} style = {{minWidth: "300px"}}>
            <Paper className={classes.root}>
              <Typography variant="h5" component="h3">
                Shoppers Search
              </Typography>
              <Form
                onSubmit={this.onSubmit}
                validate={this.validate}
                render={({ handleSubmit, form, reset, submitting, pristine, values }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.buttons}>
                      <Field
                        label="Phone"
                        name="phone"
                        component={TextField}
                        type="text"
                        className={classes.textField}
                        margin="normal"
                      />
                      <div className={classes.buttonWrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className={classes.button}
                          disabled={submitting}
                          onClick={() => {
                            form.change("submitType", "phone");
                          }}>
                          Search
                        </Button>
                        {submitting && values.submitType === "phone" && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                    </div>
                    <div className={classes.buttons}>
                      <Field
                        label="User id"
                        name="shopperID"
                        component={TextField}
                        type="text"
                        style={{maxWidth: 320}}
                        fullWidth={true}
                        className={classes.textField}
                        margin="normal"
                      />
                      <div className={classes.buttonWrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className={classes.button}
                          disabled={submitting}
                          onClick={() => {
                            form.change("submitType", "shopperID");
                          }}>
                          Search
                        </Button>
                        {submitting && values.submitType === "shopperID" && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                    </div>
                  </form>
                )}
              />
            </Paper>
          </Grid>
          <Grid item xs={6} style = {{minWidth: "300px"}}>
            <Paper className={classes.root}>
              <Typography className={classes.mega} variant="h5" component="h3">
                Marketplace Order Debug
              </Typography>
              <Form
                onSubmit={this.onSubmitZipcode}
                validate={this.validate}
                render={({ handleSubmit, form, reset, submitting, pristine, values }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.buttons}>
                      <Field
                        label="Zipcode"
                        name="zipcode"
                        component={TextField}
                        type="text"
                        className={classes.textField}
                        margin="normal"
                      />
                      <div className={classes.buttonWrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className={classes.button}
                          disabled={submitting}
                          onClick={() => {
                            form.change("submitType", "zipcode");
                          }}>
                          Submit
                        </Button>
                        {submitting && values.submitType === "zipcode" && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                    </div>
                  </form>
                )}
              />
            </Paper>
          </Grid>
        </Grid>
        <Paper className={classes.root}>
          <Typography className={classes.mega} variant="h5" component="h3">
            Mega Search (beta)
          </Typography>
          <ShopperAutocomplete search={this.search}/>
        </Paper>
        {this.renderShopperPreview(this.state.shoppers)}
        {this.state.shopperNotFound &&
          <Container maxWidth="sm">
            <Typography component="div" style={{textAlign: 'center', paddingTop: '80px'}} variant="h5" color="textPrimary">
              No Match Found
            </Typography>
          </Container>
        }
      </div>
    );
  }
}

export default withStyles(styles)(Shoppers)
