const dev = {
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://ah4cu3rgw6.execute-api.us-east-2.amazonaws.com/dev",
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_VAmy1Cln9",
    APP_CLIENT_ID: "9c2kv2ncmsblgiphi2mhhf8l3",
    IDENTITY_POOL_ID: "us-east-2:069bf799-2f5a-4238-95d3-be37a1edeafe",
  },
  Storage: {
    AWSS3: {
      bucket: "dev-dumplingmygig",
      region: "us-east-2",
    },
  },
};

const prod = {
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://api-bao.dumpling.us/prod",
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_5YgornLN7",
    APP_CLIENT_ID: "7ttjf01nmleilbbeeus3544hm",
    IDENTITY_POOL_ID: "us-east-2:ece6af6e-0c3d-4c6d-8184-20ee02cd61bb",
  },
  Storage: {
    AWSS3: {
      bucket: "dumplingmygig",
      region: "us-east-2",
    },
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default {
  // Add common config values here

  ...config,
};
