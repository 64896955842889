import React, { Component } from "react";
import NumberFormat from "react-number-format";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import PopupState, {
  bindTrigger,
  bindPopover,
  bindMenu,
} from "material-ui-popup-state";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/InfoOutlined"
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Moment from "react-moment";
import moment from "moment";
import "moment-timezone";
import PopoverFrom from "./PopoverForm.js";
import ShopperNotesTable from "./ShopperNotesTable.js";
import NotesPopoverForm from "./NotesPopoverForm.js";
import WebPathPopoverForm from "./WebPathPopoverForm.js";
import PhonePopoverForm from "./PhonePopoverForm.js";
import SubscriptionPopoverForm from "./SubscriptionPopoverForm.js";
import copy from "copy-to-clipboard";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import MCCPopoverForm from "./MCCPopoverForm.js";
import UserRemappingPopoverForm from "./UserRemappingPopoverForm.js";
import ShopperNote from "./ShopperNote";
import SyntheticTransactionPopoverForm from "./SyntheticTransactionPopoverForm";
import UnpausePopoverForm from "./UnpausePopoverForm.js";

// for the snackbar
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const nowTimestamp = moment().unix()

// Money values are in cents.
const fundingProfiles = {
  basic: {
    name: "Basic",
    transactionLimit: 27500,
    fundingPerOrder: 27500,
    dailySpendLimitCeiling: 27500,
    maxDanglingSpend: 27500,
  },
  newbie: {
    name: "Newbie",
    transactionLimit: 27500,
    fundingPerOrder: 27500,
    dailySpendLimitCeiling: 55000,
    maxDanglingSpend: 55000,
  },
  standard: {
    name: "Standard",
    transactionLimit: 37500,
    fundingPerOrder: 37500,
    dailySpendLimitCeiling: 112500,
    maxDanglingSpend: 112500,
  },
  trusted: {
    name: "Trusted",
    transactionLimit: 75000,
    fundingPerOrder: 37500,
    dailySpendLimitCeiling: 150000,
    maxDanglingSpend: 150000,
  },
  vip: {
    name: "VIP",
    transactionLimit: 150000,
    fundingPerOrder: 50000,
    dailySpendLimitCeiling: 200000,
    maxDanglingSpend: 200000,
  },
  baomaster: {
    name: "Bao Master",
    transactionLimit: 200000,
    fundingPerOrder: 50000,
    dailySpendLimitCeiling: 250000,
    maxDanglingSpend: 250000,
  },
  haithamspecial: {
    name: "Haitham Special",
    transactionLimit: 150000,
    fundingPerOrder: 150000,
    dailySpendLimitCeiling: 300000,
    maxDanglingSpend: 300000,
  },
  pao: {
    name: "Pao 💥",
    transactionLimit: 150000,
    fundingPerOrder: 67500,
    dailySpendLimitCeiling: 700000,
    maxDanglingSpend: 700000,
  },
  resort: {
    name: "Resort ⛷",
    transactionLimit: 200000,
    fundingPerOrder: 100000,
    dailySpendLimitCeiling: 1500000,
    maxDanglingSpend: 1000000,
  },
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  // shopper summary
  bigAvatar: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 0,
    width: 100,
    height: 100,
  },
  chip: {
    margin: theme.spacing(1),
  },
  errorChip: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
  },
  header: {
    color: theme.palette.text.secondary,
  },
  expansionRoot: {
    width: "100%",
  },
  expansionRootNotes: {
    width: "100%",
    paddingTop: 10,
  },
  summary: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 550,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  deactivateButton: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  },
  buttonWrapper: {
    position: "relative",
  },
  buttonProgressRed: {
    color: red[200],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {},
  notesDetails: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  column: {
    flexBasis: "50.0%",
    paddingRight: theme.spacing(2),
  },
  errorText: {
    color: theme.palette.error.main,
  },
  inactiveText: {
    color: theme.palette.grey[500],
  },
  greenText: {
    color: theme.palette.primary.main,
  },
  rowStyle: {
    display: "flex",
    flexDirection: "row",
  },
  inputButton: {
    fontSize: 16,
  },
  finalGrid: {
    paddingBottom: 6,
  },
  greenButtonText: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  sideBySide: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  sideBySideRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  sideBySideRowCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  select: {
    marginTop: 4,
  },
  mccCode: {
    marginLeft: 15,
  },
  paper: {
    padding: theme.spacing(2),
    width: 500,
    marginRight: theme.spacing(2),
  },
  tooltip: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: "normal",
  },
  progressRed: {
    color: red[200],
    verticalAlign: "middle",
  },
  badShopperSearch: {
    color: '#f44336'
  }
});

const booleanIcons = {
  true: "✅",
  false: "❌",
};

class ShopperDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deactivateButtonLoading: false,
      zipcodeSearchButtonLoading: false,
      zipcodeSearchOptInbuttonLoading: false,
      optInButtonLoading: false,
      savingInstantPayoutEnabled: false,
      savingExternalTransactionsEnabled: false,
      savingResortClosedDatesEnabled: false,
      stripeVerifyButtonLoading: false,
      copiedToClipboardSnackOpen: false,
      skilljarShow: false,
      skilljarAnchor: null,
      unpauseUntilDeleteLoading: false,
      logoutButtonLoading: false,
      savingHasBetaAccess: false,
    };
  }

  handleSkilljarClick(event) {
    this.setState({ skillJarAnchor: event.currentTarget, skilljarShow: true });
  }

  handleSkilljarClose() {
    this.setState({ skillJarAnchor: null, skilljarShow: false });
  }

  // if thre is not a single active card, make the menu title red
  getGlobalCardStatusTextClass() {
    const { shopperCards, classes } = this.props;
    if (shopperCards) {
      let globalCardStatusTextClass = classes.errorText;
      for (const shopperCardId in shopperCards) {
        const shopperCard = shopperCards[shopperCardId];
        if (shopperCard.status.toLowerCase() === "active") {
          globalCardStatusTextClass = classes.greenText;
        }
      }
      return globalCardStatusTextClass;
    } else {
      return classes.greenText;
    }
  }

  renderShopperCards(shopperCards) {
    const { classes, shopper } = this.props;
    var autoPauseMsg = "";
    if (shopper.cardsDeactivated) {
      autoPauseMsg = " (AutoPaused)";
    }
    if (shopperCards && shopperCards.length > 0) {
      return (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Typography
                {...bindTrigger(popupState)}
                displayType={"text"}
                className={this.getGlobalCardStatusTextClass()}
                style={{ cursor: "pointer" }}
              >
                Credit Cards{autoPauseMsg}
              </Typography>
              <Menu {...bindMenu(popupState)}>
                {shopperCards.map((shopperCard, i) => {
                  const cardStatus = shopperCard.status.toLowerCase();
                  const cardAcctId = shopperCard.cardAcctId;
                  var cardLast4 = "XXXX";
                  if (shopperCard.last4) {
                    cardLast4 = shopperCard.last4;
                  }
                  // change the color of the status text according to the status
                  let statusClassName = classes.errorText;
                  if (cardStatus === "active") {
                    statusClassName = classes.greenText;
                  } else if (cardStatus == "inactive") {
                    statusClassName = classes.inactiveText;
                  } // everything else will be red.  blocked/closed/etc
                  if (cardAcctId.startsWith("ic_")) {
                    // Stripe card
                    return (
                      <MenuItem onClick={popupState.close}>
                        <Link
                          key={i}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://dashboard.stripe.com/issuing/cards/${cardAcctId}`}
                        >
                          Stripe Card {cardLast4} -{" "}
                          <span className={statusClassName}>{cardStatus}</span>
                        </Link>
                      </MenuItem>
                    );
                  } else {
                    return (
                      <MenuItem onClick={popupState.close}>
                        <Link
                          key={i}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://admin.pexcard.com/admin.html#/admin/card/profile/${cardAcctId}`}
                        >
                          PEX Card {cardLast4} -{" "}
                          <span className={statusClassName}>{cardStatus}</span>
                        </Link>
                      </MenuItem>
                    );
                  }
                })}
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      );
      // TODO - make this a menu instead
    }
  }

  renderShopperAccountTypeChip() {
    const { classes, shopper } = this.props;
    // Adjust the subscription plan displayed accordingling
    var subscriptionPlan = "UNKNOWN"; // not possible to show this
    switch (shopper.subscriptionPlan) {
      case "Legacy Pro":
        subscriptionPlan = "PIONEER PRO"; // rename legacy to pioneer
        break;
      case "":
        subscriptionPlan = "PIONEER STARTER"; // no subscription plan or a empty string means legacy starter, ie pioneer starter
        break;
      case null:
        subscriptionPlan = "PIONEER STARTER"; // no subscription plan or a empty string means legacy starter, ie pioneer starter
        break;
      case undefined:
        subscriptionPlan = "PIONEER STARTER"; // no subscription plan or a empty string means legacy starter, ie pioneer starter
        break;
      default:
        subscriptionPlan = shopper.subscriptionPlan.toUpperCase(); // could consider a switch for all plans to show unknown if no match
    }

    if (shopper.deactivated) {
      return (
        <Chip
          size="small"
          label="DEACTIVATED"
          className={classes.errorChip}
          color="primary"
        />
      );
    } else if (shopper.acctType && shopper.acctType.toLowerCase() === "test") {
      return (
        <Chip
          size="small"
          label="TEST"
          className={classes.errorChip}
          color="primary"
        />
      );
    } else {
      return (
        <>
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>
                <Chip
                  {...bindTrigger(popupState)}
                  size="small"
                  label={subscriptionPlan}
                  className={classes.chip}
                  color="primary"
                />
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Box p={2}>
                    <SubscriptionPopoverForm
                      submitFunc={async (values) => {
                        if (
                          window.confirm(
                            "Changing the subscription plan in Bao will not update their payments in Stripe.  You must do that manually."
                          )
                        ) {
                          const response =
                            await this.props.updateSubscriptionPlan(values);
                          if (response) {
                            popupState.close(); // update was succesful, close the popover
                          }
                        }
                      }}
                      name="subscriptionPlan"
                      label="Subscription Plan"
                      initialValues={{
                        subscriptionPlan: shopper.subscriptionPlan
                          ? shopper.subscriptionPlan
                          : "Legacy Starter",
                      }}
                    />
                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        </>
      );
    }
  }

  createIntercomLink() {
    const { shopper } = this.props;
    // construct the intercom link parameters and base64 encode as that is how their filter systmem works
    const parameters = `{"predicates":[{"attribute":"role","comparison":"eq","type":"role","value":"user_role"},{"attribute":"user_id","comparison":"eq","type":"string","value":"${shopper.id}"}]}`;
    const base64Parameters = btoa(parameters);
    // change the link based on if this is dev or prod
    const intercomId =
      process.env.REACT_APP_STAGE === "prod" ? "hejmix7h" : "ixbgxe1m";
    const linkURL = `https://app.intercom.com/a/apps/${intercomId}/users/segments/all-users:${base64Parameters}`;
    return linkURL;
  }

  formattedMccs(shopper, classes, deleteMccFromShopper) {
    function deleteMccWrapper(mcc) {
      return function () {
        if (
          window.confirm(
            "Are you really sure you want to remove this extra MCC code from the shopper profile?"
          )
        ) {
          deleteMccFromShopper(mcc);
        }
      };
    }

    var currentTime = parseInt(+new Date() / 1000);

    return shopper.extraMccs.map(function (mcc) {
      if (mcc.expiresAt == null) {
        return (
          <Badge
            badgeContent={"∞"}
            color="primary"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Button
              variant="outlined"
              color="primary"
              className={classes.mccCode}
              data-value={mcc.mcc}
              onClick={deleteMccWrapper(mcc.mcc)}
            >
              {mcc.mcc}
            </Button>
          </Badge>
        );
      } else {
        const hours = (mcc.expiresAt - currentTime) / 60.0 / 60.0;

        if (hours < 24) {
          return (
            <Badge
              badgeContent={`${Math.round(hours)} h`}
              color="primary"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Button
                variant="outlined"
                color="primary"
                className={classes.mccCode}
                data-value={mcc.mcc}
                onClick={deleteMccWrapper(mcc.mcc)}
              >
                {mcc.mcc}
              </Button>
            </Badge>
          );
        } else {
          return (
            <Badge
              badgeContent={`${Math.round(hours / 24)} d`}
              color="primary"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Button
                variant="outlined"
                color="primary"
                className={classes.mccCode}
                data-value={mcc.mcc}
                onClick={deleteMccWrapper(mcc.mcc)}
              >
                {mcc.mcc}
              </Button>
            </Badge>
          );
        }
      }
    });
  }


  formattedUserRemapping(shopperUserRemapping, classes, deleteUserRemappingFromShopper) {
    function deleteUserRemappingWrapper(userId) {
      return function () {
        if (
          window.confirm(
            "Are you sure you want to remove this user remapping from this Shopper?"
          )
        ) {
          deleteUserRemappingFromShopper(userId);
        }
      };
    }

    var currentTime = parseInt(+new Date() / 1000);

    return shopperUserRemapping.map(function (userRemapping) {
      const type = userRemapping.type
      if (userRemapping.expiresAt == null) {
        return (
          <Badge
            badgeContent={(type && type.startsWith("BossBuddy") ? "Boss Buddy " : "") + "∞"}
            color="primary"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Button
              variant="outlined"
              color="primary"
              className={classes.mccCode}
              data-value={userRemapping.userId}
              onClick={deleteUserRemappingWrapper(userRemapping.userId)}
            >
              {userRemapping.userId}
            </Button>
          </Badge>
        );
      } else {
        const hours = (userRemapping.expiresAt - currentTime) / 60.0 / 60.0;
        if (hours < 24) {
          return (
            <Badge
              badgeContent={(type && type.startsWith("BossBuddy") ? "Boss Buddy " : "") + `${Math.round(hours)} h`}
              color="primary"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Button
                variant="outlined"
                color="primary"
                className={classes.mccCode}
                data-value={userRemapping.userId}
                onClick={deleteUserRemappingWrapper(userRemapping.userId)}
              >
                {userRemapping.userId}
              </Button>
            </Badge>
          );
        } else {
          return (
            <Badge
              badgeContent={`${Math.round(hours / 24)} d`}
              color="primary"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Button
                variant="outlined"
                color="primary"
                className={classes.mccCode}
                data-value={userRemapping.userId}
                onClick={deleteUserRemappingWrapper(userRemapping.userId)}
              >
                {userRemapping.userId}
              </Button>
            </Badge>
          );
        }
      }
    });
  }

  formattedUnpause(shopper, classes, deleteUnpauseUntilFromShopper) {
    const unpauseUntil = shopper.unpausedByOperatorUntilTimestamp

    if (!unpauseUntil) {
      return null 
    }

    return (
      <div className={classes.buttonWrapper}>
        <Chip
          label={<Moment unix format="MMM DD, YYYY hh:mm a" tz={'Pacific/Honolulu'}>{unpauseUntil}</Moment>}
          className={classes.mccCode}
          disabled={this.state.unpauseUntilDeleteLoading}
          variant="outlined"
          color="primary"

          onDelete={async () => { 
            if (
              window.confirm(
                "Please confirm you would like to remove the unpause until date from this shopper."
              )
            ) {
              this.setState({ unpauseUntilDeleteLoading: true });
              await deleteUnpauseUntilFromShopper(unpauseUntil)
              this.setState({ unpauseUntilDeleteLoading: false });
            }
          }}
        >
        </Chip>
        
        {this.state.unpauseUntilDeleteLoading && (
          <CircularProgress
            size={24}
            className={classes.buttonProgress}
          />
        )}
      </div>
    );
  }

  render() {
    const {
      classes,
      shopper,
      shopperCards,
      shopperNotes,
      shopperNotesLoading,
      shopperCardsLoading,
      shopperOnboardingStatus,
      shopperUserRemapping
    } = this.props;
    const fundingProfile = shopper.fundingProfile
      ? shopper.fundingProfile
      : "standard"; // bad hack to people who don't have a funding profile variable into the 'standard' one
    const fundingProfileName = fundingProfiles[fundingProfile].name;
    const transactionLimit = shopper.transactionLimit
      ? shopper.transactionLimit
      : fundingProfiles[fundingProfile].transactionLimit;
    const fundingPerOrder = shopper.fundingPerOrder
      ? shopper.fundingPerOrder
      : fundingProfiles[fundingProfile].fundingPerOrder;
    const maxDanglingSpend = shopper.maxDanglingSpend
      ? shopper.maxDanglingSpend
      : fundingProfiles[fundingProfile].maxDanglingSpend;

    const ExpansionPanelDetailsPadded = withStyles((theme) => ({
      root: {
        padding: theme.spacing(1, 8, 3, 3), // this is to compensate for the expansion icon on the right
      },
    }))(MuiExpansionPanelDetails);

    const shopperSearchReady =
      shopper.onboardingCallComplete &&
      shopper.optInZipcode &&
      !shopper.kickedOutZipcode &&
      shopperOnboardingStatus.businessNameValid &&
      shopperOnboardingStatus.displayNameValid &&
      shopperOnboardingStatus.imageValid &&
      shopperOnboardingStatus.orderInLast60Days &&
      shopperOnboardingStatus.hasAvailableSchedule &&
      shopperOnboardingStatus.isScheduleAvailable &&
      shopperOnboardingStatus.hasServiceOfferings &&
      shopperOnboardingStatus.hasActiveChargeCard && 
      (!shopper.pausedFromMarketplaceByConversionRate || shopper.unpausedByOperatorUntilTimestamp > nowTimestamp) &&
      (!shopper.pausedFromMarketplaceByCancelationRate || shopper.unpausedByOperatorUntilTimestamp > nowTimestamp);

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item>
            <Grid
              item
              xs={3}
              sm
              container
              direction="column"
              alignItems="center"
              justify="flex-start"
            >
              <Grid item xs>
                <Avatar
                  alt="Shopper Image"
                  src={shopper.image}
                  className={classes.bigAvatar}
                />
              </Grid>
              <Grid item xs>
                {this.renderShopperAccountTypeChip()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container spacing={1}>
              <Grid item xs>
                <Grid
                  item
                  xs
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Typography gutterBottom variant="h5">
                      {shopper.firstName} {shopper.lastName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="h5">
                      -
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="h5">
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://shop.dumpling.us/${shopper.webPath}`}
                        className={classes.link}
                      >
                        {shopper.businessName}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="h5">
                      <PopupState
                        variant="popover"
                        popupId="demo-popup-popover"
                      >
                        {(popupState) => (
                          <div>
                            <Button
                              className={classes.inputButton}
                              color="primary"
                              {...bindTrigger(popupState)}
                            >
                              {shopper.webPath}
                            </Button>
                            <Popover
                              {...bindPopover(popupState)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              <Box p={2}>
                                <WebPathPopoverForm
                                  submitFunc={async (values) => {
                                    const response =
                                      await this.props.updateShopperWebpath(
                                        values
                                      );
                                    if (response) {
                                      popupState.close(); // update was succesful, close the popover
                                    }
                                  }}
                                  name="webPath"
                                  label="Web Path"
                                  initialValues={{ webPath: shopper.webPath }}
                                />
                              </Box>
                            </Popover>
                          </div>
                        )}
                      </PopupState>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item xs>
                    <Grid
                      item
                      xs
                      container
                      direction="row"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item>
                        <Typography gutterBottom variant="body1">
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-popover"
                          >
                            {(popupState) => (
                              <div>
                                <span className={classes.header}>Phone</span>
                                &nbsp;&nbsp;
                                <NumberFormat
                                  {...bindTrigger(popupState)}
                                  className={classes.greenButtonText}
                                  style={{ cursor: "pointer" }}
                                  format="###-###-####"
                                  value={shopper.phone.slice(2)}
                                  displayType={"text"}
                                />
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <Box p={2}>
                                    <PhonePopoverForm
                                      submitFunc={async (values) => {
                                        const response =
                                          await this.props.changePhone(values);
                                        if (response) {
                                          popupState.close(); // update was succesful, close the popover
                                          // TODO - make this a dialog
                                          alert(
                                            "Make sure to remind the biz owner that this does not change their business phone number.  If it is the same as their old phone number they will need to manually change it."
                                          );
                                        }
                                      }}
                                      name="phone"
                                      label="Phone Number"
                                    />
                                  </Box>
                                </Popover>
                              </div>
                            )}
                          </PopupState>
                        </Typography>
                      </Grid>
                      <Grid item>
                        {this.props.shopperPhoneIsOptedOut !== null ? (
                          <Typography variant="body1" gutterBottom>
                            {this.props.shopperPhoneIsOptedOut ? (
                              <span className={classes.errorText}>
                                * Opted Out{" "}
                              </span>
                            ) : (
                              ""
                            )}
                          </Typography>
                        ) : (
                          <Typography variant="body1" gutterBottom></Typography>
                        )}
                      </Grid>
                      <Grid item>
                        {shopper.businessPhone && (
                          <Typography gutterBottom variant="body1">
                            <span className={classes.header}>Biz Phone</span>
                            &nbsp;&nbsp;
                            <NumberFormat
                              format="###-###-####"
                              value={shopper.businessPhone.slice(2)}
                              displayType={"text"}
                            />
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" gutterBottom>
                        <span className={classes.header}>Email</span>
                        &nbsp;&nbsp;
                        {shopper.email}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <span className={classes.header}>Id</span>
                        &nbsp;&nbsp;
                        {shopper.id}
                      </Typography>
                      <Grid
                        item
                        xs
                        container
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid item>
                          {shopperCardsLoading ? (
                            <Typography variant="body1" gutterBottom>
                              <span>Loading cards...</span>
                            </Typography>
                          ) : shopperCards && shopperCards.length > 0 ? (
                            <Typography variant="body1" gutterBottom>
                              {this.renderShopperCards(shopperCards)}
                            </Typography>
                          ) : (
                            <Typography variant="body1" gutterBottom>
                              <span className={classes.errorText}>
                                No PEX/Stripe Card
                              </span>
                            </Typography>
                          )}
                        </Grid>
                        <Grid item>
                          {shopper.stripeConnectAcctId && (
                            <Typography variant="body1" gutterBottom>
                              <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://dashboard.stripe.com/connect/accounts/${shopper.stripeConnectAcctId}`}
                              >
                                Stripe Connect
                              </Link>
                            </Typography>
                          )}
                        </Grid>
                        <Grid item>
                          {shopper.stripeCustomerId && (
                            <Typography variant="body1" gutterBottom>
                              <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://dashboard.stripe.com/customers/${shopper.stripeCustomerId}`}
                              >
                                Stripe Customer
                              </Link>
                            </Typography>
                          )}
                        </Grid>
                        <Grid item>
                          {shopper.stripeConnectAcctId && (
                            <Typography variant="body1" gutterBottom>
                              <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://dashboard.stripe.com/search?query=${shopper.firstName}%20${shopper.lastName}`}
                              >
                                Stripe Search
                              </Link>
                            </Typography>
                          )}
                        </Grid>
                        <Grid item>
                          {shopper.id && (
                            <Typography variant="body1" gutterBottom>
                              <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                href={this.createIntercomLink()}
                              >
                                Intercom
                              </Link>
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Grid
                      item
                      xs
                      container
                      direction="row"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <Grid
                        item
                        xs
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                      >
                        <Typography variant="body1">
                          <span className={classes.header}>Today's Spend</span>
                        </Typography>
                        <Typography variant="body1">
                          <Button
                            disabled={true}
                            style={{
                              color: `rgba(0, 0, 0, 0.87)`,
                              fontSize: 16,
                            }}
                          >
                            N/A
                            {/* <NumberFormat value={shopper.todaysSpend ? shopper.todaysSpend / 100.0 : 0} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} prefix={'$'} /> */}
                          </Button>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                      >
                        <Typography variant="body1">
                          <span className={classes.header}>Open Spend</span>
                        </Typography>
                        <Typography variant="body1">
                          <Button
                            disabled={true}
                            style={{
                              color: `rgba(0, 0, 0, 0.87)`,
                              fontSize: 16,
                            }}
                          >
                            <NumberFormat
                              value={
                                shopper.danglingSpend
                                  ? shopper.danglingSpend / 100.0
                                  : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={2}
                              prefix={"$"}
                            />
                          </Button>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                      >
                        <Typography variant="body1">
                          <span className={classes.header}>
                            Current Balance
                          </span>
                        </Typography>
                        <Typography variant="body1">
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-popover"
                          >
                            {(popupState) => (
                              <div>
                                <Button
                                  className={classes.inputButton}
                                  color="primary"
                                  {...bindTrigger(popupState)}
                                >
                                  <NumberFormat
                                    value={
                                      shopper.virtualBalance
                                        ? shopper.virtualBalance / 100.0
                                        : 0
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    prefix={"$"}
                                  />
                                </Button>
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <Box p={2}>
                                    <PopoverFrom
                                      submitFunc={async (values) => {
                                        const response =
                                          await this.props.updateFunding(
                                            values
                                          );
                                        if (response) {
                                          popupState.close(); // update was succesful, close the popover
                                        }
                                      }}
                                      name="fundingAmount"
                                      label="Current Balance"
                                      initialValues={{
                                        fundingAmount:
                                          shopper.virtualBalance / 100.0,
                                      }}
                                    />
                                  </Box>
                                </Popover>
                              </div>
                            )}
                          </PopupState>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                      >
                        <Typography variant="body1">
                          <span className={classes.header}>
                            Funding Profile
                          </span>
                        </Typography>
                        <Typography variant="body1">
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-popover"
                          >
                            {(popupState) => (
                              <div>
                                <Button
                                  className={classes.inputButton}
                                  color="primary"
                                  {...bindTrigger(popupState)}
                                >
                                  {fundingProfileName}
                                </Button>
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <Box p={2}>
                                    <PopoverFrom
                                      submitFunc={async (values) => {
                                        const response =
                                          await this.props.updateFunding(
                                            values
                                          );
                                        if (response) {
                                          popupState.close(); // update was succesful, close the popover
                                        }
                                      }}
                                      name="fundingProfile"
                                      label="Funding Profile"
                                      initialValues={{
                                        fundingProfile: fundingProfile,
                                      }}
                                    />
                                  </Box>
                                </Popover>
                              </div>
                            )}
                          </PopupState>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs
                      container
                      direction="row"
                      justify="space-evenly"
                      spacing={1}
                    >
                      <Grid
                        item
                        xs
                        container
                        directon="column"
                        justify="center"
                      >
                        <Typography gutterBottom variant="body1">
                          <span className={classes.header}>
                            Transaction Limit
                          </span>
                          &nbsp;&nbsp;
                        </Typography>
                        <Typography gutterBottom variant="body1">
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-popover"
                          >
                            {(popupState) => (
                              <div>
                                <NumberFormat
                                  {...bindTrigger(popupState)}
                                  className={classes.greenButtonText}
                                  style={{ cursor: "pointer" }}
                                  value={transactionLimit / 100.0}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                  decimalScale={2}
                                  prefix={"$"}
                                />
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <Box p={2}>
                                    <PopoverFrom
                                      submitFunc={async (values) => {
                                        const response =
                                          await this.props.updateFunding(
                                            values
                                          );
                                        if (response) {
                                          popupState.close(); // update was succesful, close the popover
                                        }
                                      }}
                                      name="transactionLimit"
                                      label="Transaction Limit"
                                      initialValues={{
                                        transactionLimit:
                                          transactionLimit / 100.0,
                                      }}
                                    />
                                  </Box>
                                </Popover>
                              </div>
                            )}
                          </PopupState>
                        </Typography>
                      </Grid>
                      <Grid item xs justify="center">
                        <Typography gutterBottom variant="body1">
                          <span className={classes.header}>
                            Funding Per Order
                          </span>
                          &nbsp;&nbsp;
                          <NumberFormat
                            value={fundingPerOrder / 100.0}
                            displayType={"text"}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            prefix={"$"}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs
                      container
                      direction="row"
                      justify="space-evenly"
                      spacing={1}
                      className={classes.finalGrid}
                    >
                      <Grid item>
                        <Typography gutterBottom variant="body1">
                          <span className={classes.header}>
                            Daily Spend Limit
                          </span>
                          &nbsp;&nbsp;
                          <NumberFormat
                            value={
                              shopper.dailySpendLimit
                                ? shopper.dailySpendLimit / 100.0
                                : 0
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            prefix={"$"}
                          />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography gutterBottom variant="body1">
                          <span className={classes.header}>Max Open Spend</span>
                          &nbsp;&nbsp;
                          <NumberFormat
                            value={maxDanglingSpend / 100.0}
                            displayType={"text"}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            prefix={"$"}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.expansionRoot}>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              <div className={classes.column}>
                <Typography className={classes.summary} display="inline">
                  Onboarding
                </Typography>
                &nbsp;&nbsp;&nbsp;
                {shopperSearchReady ? (
                  <Chip
                    label="Marketplace Search"
                    size="small"
                    color="primary"
                    icon={<DoneIcon />}
                  />
                ) : (
                  <Chip
                  label="Marketplace Search"
                  size="small"
                  color="error"
                  //className={classes.errorText}
                  icon={<CloseIcon />}
                />
                )}
              </div>
              <div className={classes.column}>
                <Typography className={classes.summary}>
                  Account Actions
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetailsPadded className={classes.details}>
              <div className={classes.column}>
                <Box className={classes.paper} style={{ float: "left" }}>
                  <Typography variant="h6">Onboarding stats</Typography>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography className={classes.secondaryHeading}>
                        Created at
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.heading}>
                        <Moment unix format="MMM DD, YYYY">
                          {Number(shopper.creation_time)}
                        </Moment>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.secondaryHeading}>
                        Intercom email
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.heading}>
                        {shopper.intercomEmail}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.secondaryHeading}>
                        Number of clients
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.heading}>
                        {shopper.clientCount ? shopper.clientCount : 0}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.secondaryHeading}>
                        Stripe Connect
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <div style={{ verticalAlign: "middle" }}>
                        {booleanIcons[!!shopper.stripeConnectAcctExists]}
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.secondaryHeading}>
                        Client marketing
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <div style={{ verticalAlign: "middle" }}>
                        {booleanIcons[!!shopper.optInEngage]}
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.secondaryHeading}>
                        Phone opted in
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <div style={{ verticalAlign: "middle" }}>
                        {booleanIcons[!this.props.shopperPhoneIsOptedOut]}
                      </div>
                    </Grid>
                  </Grid>
                  <hr />
                  <Typography variant="h6">Shopping Location</Typography>
                  <Grid container>
                    <Typography className={classes.secondaryHeading}>
                      Zipcode
                    </Typography>
                    &nbsp;&nbsp;
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://shop.dumpling.us/shoppers/${shopper.deliveryAreaZipcode}`}
                    >
                      <Typography className={classes.heading}>
                        {shopper.deliveryAreaZipcode}
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid container>
                    <Typography className={classes.secondaryHeading}>
                      Location description
                    </Typography>
                    &nbsp;&nbsp;
                    <Typography className={classes.heading}>
                      {shopper.deliveryArea}
                    </Typography>
                  </Grid>
                  <hr />
                  <Typography variant="h6">Marketplace Search</Typography>
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography className={classes.secondaryHeading}>
                        Opted-in
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ verticalAlign: "middle" }}>
                        {booleanIcons[!!shopper.optInZipcode]}
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        className={classes.secondaryHeading}
                        onClick={(event) => this.handleSkilljarClick(event)}
                      >
                        Onboarded
                      </Typography>
                      <div style={{marginLeft: 10}}>
                        <PopupState
                          variant="popover"
                          popupId="demo-popup-popover"
                        >
                          {(popupState) => (
                            <div className={classes.sideBySideRow}>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <Typography
                                  {...bindTrigger(popupState)}
                                  displayType={"text"}
                                  className={classes.greenButtonText}
                                  style={{ cursor: "pointer", width: 80 }}
                                >
                                  details
                                </Typography>
                              </div>
                              <Popover
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              >
                                <Box
                                  className={classes.paper}
                                  style={{ width: 450, marginRight: 0 }}
                                >
                                  <Typography variant="h5">
                                    Skilljar Courses Completed
                                  </Typography>
                                  <hr />
                                  <Grid container>
                                    {Object.keys(
                                      shopperOnboardingStatus.coursesDetails ||
                                        {}
                                    ).map((name) => (
                                      <>
                                        <Grid item xs={11}>
                                          {name}
                                        </Grid>
                                        <Grid item xs={1}>
                                          {
                                            booleanIcons[
                                              shopperOnboardingStatus
                                                .coursesDetails[name]
                                            ]
                                          }
                                        </Grid>
                                      </>
                                    ))}
                                  </Grid>
                                </Box>
                              </Popover>
                            </div>
                          )}
                        </PopupState>
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ verticalAlign: "middle" }}>
                        {booleanIcons[!!shopper.onboardingCallComplete]}
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={classes.secondaryHeading}>
                        Order last 30 days
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ verticalAlign: "middle" }}>
                        {
                          booleanIcons[
                            !!shopperOnboardingStatus.orderInLast60Days
                          ]
                        }
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={classes.secondaryHeading}>
                        Service listed
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ verticalAlign: "middle" }}>
                        {
                          booleanIcons[
                            !!shopperOnboardingStatus.hasServiceOfferings
                          ]
                        }
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={classes.secondaryHeading}>
                        Schedule open
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ verticalAlign: "middle" }}>
                        {
                          booleanIcons[
                            !!shopperOnboardingStatus.hasAvailableSchedule
                          ]
                        }
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={classes.secondaryHeading}>
                        Schedule available
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ verticalAlign: "middle" }}>
                        {
                          booleanIcons[
                            !!shopperOnboardingStatus.isScheduleAvailable
                          ]
                        }
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={classes.secondaryHeading}>
                        Active card
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ verticalAlign: "middle" }}>
                        {
                          booleanIcons[
                            !!shopperOnboardingStatus.hasActiveChargeCard
                          ]
                        }
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={classes.secondaryHeading}>
                        Business name
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ verticalAlign: "middle" }}>
                        {
                          booleanIcons[
                            !!shopperOnboardingStatus.businessNameValid
                          ]
                        }
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={classes.secondaryHeading}>
                        Display name
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ verticalAlign: "middle" }}>
                        {
                          booleanIcons[
                            !!shopperOnboardingStatus.displayNameValid
                          ]
                        }
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={classes.secondaryHeading}>
                        Business Image
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ verticalAlign: "middle" }}>
                        {booleanIcons[!!shopperOnboardingStatus.imageValid]}
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={classes.secondaryHeading}>
                        Not Kicked Out by Operator
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ verticalAlign: "middle" }}>
                        {booleanIcons[!shopper.kickedOutZipcode]}
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={classes.secondaryHeading}>
                        {'Conversion rate >= 10%'}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ verticalAlign: "middle" }}>
                        {booleanIcons[!shopper.pausedFromMarketplaceByConversionRate]}
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={classes.secondaryHeading}>
                        {'Cancelation rate < 34%'}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ verticalAlign: "middle" }}>
                        {booleanIcons[!shopper.pausedFromMarketplaceByCancelationRate]}
                      </div>
                    </Grid>
                    <Grid item xs={12} alignItems="center" container direction="row" justify="center">
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/shoppers/search/${shopper.deliveryAreaZipcode}`}
                      >
                        <Typography className={classes.heading}>
                          Search Debug
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                  <hr />
                  <Grid container>
                    <Grid item xs={6}>
                      <div className={classes.buttonWrapper}>
                          {!shopper.kickedOutZipcode ? (
                              <Button
                                  className={classes.deactivateButton}
                                  color="default"
                                  disabled={this.state.zipcodeSearchButtonLoading}
                                  onClick={async () => {
                                  if (
                                      window.confirm(
                                      "Are you sure you want to remove this shopper from zipcode search? They will not be able to add themselves back in."
                                      )
                                  ) {
                                      this.setState({ zipcodeSearchButtonLoading: true });
                                      await this.props.removeZipcodeSearch();
                                      this.setState({
                                      zipcodeSearchButtonLoading: false,
                                      });
                                  }
                                  }}
                              >
                                  Remove from Marketplace
                              </Button>
                          ) : (
                              <Button
                                  color="primary"
                                  disabled={this.state.zipcodeSearchButtonLoading}
                                  onClick={async () => {
                                  if (
                                      window.confirm(
                                      "Are you sure you want to make this shopper eligible for zipcode search?"
                                      )
                                  ) {
                                      this.setState({ zipcodeSearchButtonLoading: true });
                                      await this.props.unremoveZipcodeSearch();
                                      this.setState({
                                      zipcodeSearchButtonLoading: false,
                                      });
                                  }
                                  }}
                              >
                                  Allow in Marketplace
                              </Button>
                          )}
                        {this.state.zipcodeSearchButtonLoading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgressRed}
                          />
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={classes.buttonWrapper}>
                          {shopper.optInZipcode ? (
                              <Button
                                  className={classes.deactivateButton}
                                  color="default"
                                  disabled={this.state.zipcodeSearchOptInbuttonLoading}
                                  onClick={async () => {
                                  if (
                                      window.confirm(
                                      "Are you sure you want to opt-out this shopper from zipcode search? They can opt themselves back in on their own."
                                      )
                                  ) {
                                      this.setState({ zipcodeSearchOptInbuttonLoading: true });
                                      await this.props.optInOutSearch(false);
                                      this.setState({
                                      zipcodeSearchOptInbuttonLoading: false,
                                      });
                                  }
                                  }}
                              >
                                  Opt-out from Marketplace
                              </Button>
                          ) : (
                              <Button
                                  color="primary"
                                  disabled={this.state.zipcodeSearchOptInbuttonLoading}
                                  onClick={async () => {
                                  if (
                                      window.confirm(
                                      "Are you sure you want to opt-in this shopper to zipcode search?"
                                      )
                                  ) {
                                      this.setState({ zipcodeSearchOptInbuttonLoading: true });
                                      await this.props.optInOutSearch(true);
                                      this.setState({
                                      zipcodeSearchOptInbuttonLoading: false,
                                      });
                                  }
                                  }}
                              >
                                  Opt-in to Marketplace
                              </Button>
                          )}
                        {this.state.zipcodeSearchOptInbuttonLoading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgressRed}
                          />
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid item >
                        <Typography variant="body1">
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-popover"
                          >
                            {(popupState) => (
                              <div>
                                <Button
                                  //className={classes.inputButton}
                                  color="primary"
                                  {...bindTrigger(popupState)}
                                >
                                  Ignore thresholds until
                                </Button>
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <Box p={2}>
                                    <UnpausePopoverForm
                                      submitFunc={async (unpauseUntilTimestamp) => {
                                        const response =
                                          await this.props.unpauseShopperUntil(
                                            unpauseUntilTimestamp
                                          );
                                        if (response) {
                                          popupState.close(); // update was succesful, close the popover
                                        }
                                      }}
                                    />
                                  </Box>
                                </Popover>
                              </div>
                            )}
                          </PopupState>
                        </Typography>
                      </Grid>
                      <Grid item>
                        {this.formattedUnpause(
                          shopper,
                          classes,
                          this.props.deleteUnpauseUntilFromShopper
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </div>
              <div className={classes.column}>
                <Box className={classes.paper} style={{ float: "left" }}>
                  <Typography variant="h6">
                    Actions
                  </Typography>
                  <Grid container>
                    <div className={classes.buttonWrapper}>
                      {shopper.deactivated ? (
                        <Button
                          className={classes.deactivateButton}
                          color="default"
                          disabled={this.state.deactivateButtonLoading}
                          onClick={async () => {
                            if (
                              window.confirm(
                                "Are you sure you want to activate this shopper?  This will allow them access to the app. NOTE: They will need to add themselves back to search and marketing."
                              )
                            ) {
                              this.setState({ deactivateButtonLoading: true });
                              await this.props.activate();
                              this.setState({ deactivateButtonLoading: false });
                            }
                          }}
                        >
                          Activate Shopper
                        </Button>
                      ) : (
                        <Button
                          className={classes.deactivateButton}
                          color="default"
                          disabled={this.state.deactivateButtonLoading}
                          onClick={async () => {
                            if (
                              window.confirm(
                                "Are you sure you want to deactivate this shopper?  This will prevent them from performing any actions in the app.  It will also remove them from search and client marketing."
                              )
                            ) {
                              this.setState({ deactivateButtonLoading: true });
                              await this.props.deactivate();
                              this.setState({ deactivateButtonLoading: false });
                            }
                          }}
                        >
                          Deactivate Shopper
                        </Button>
                      )}
                      {this.state.deactivateButtonLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgressRed}
                        />
                      )}
                    </div>
                  </Grid>
                  {this.props.shopperPhoneIsOptedOut ? (
                    <Grid container>
                      <div className={classes.buttonWrapper}>
                        <Button
                          className={classes.deactivateButton}
                          color="default"
                          disabled={this.state.optInButtonLoading}
                          onClick={async () => {
                            if (
                              window.confirm(
                                "Only opt-in shoppers whom have indicated they are unable to receive SMS messages."
                              )
                            ) {
                              this.setState({ optInButtonLoading: true });
                              await this.props.optInPhone();
                              this.setState({ optInButtonLoading: false });
                            }
                          }}
                        >
                          Opt-In Phone Number
                        </Button>
                        {this.state.optInButtonLoading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgressRed}
                          />
                        )}
                      </div>
                    </Grid>
                  ) : null}
                  {this.props.shopper.stripeConnectAcctExists ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <div className={classes.buttonWrapper}>
                          <Button
                            color="primary"
                            disabled={this.state.stripeVerifyButtonLoading}
                            onClick={async () => {
                              if (
                                window.confirm(
                                  "NOTE! This Link expire after 5 minutes!  Please send to the user via intercom, not text or the link will break."
                                )
                              ) {
                                this.setState({
                                  stripeVerifyButtonLoading: true,
                                });
                                const link =
                                  await this.props.createStripeVerifyAcctLink();
                                if (link) {
                                  copy(
                                    `This link will expire in 5 mins: ${link.url}`
                                  );
                                  this.setState({
                                    copiedToClipboardSnackOpen: true,
                                  });
                                }
                                this.setState({
                                  stripeVerifyButtonLoading: false,
                                });
                              }
                            }}
                          >
                            Copy Stripe Acct Verify Link
                          </Button>
                          {this.state.stripeVerifyButtonLoading && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <PopupState
                          variant="popover"
                          popupId="demo-popup-popover"
                        >
                          {(popupState) => (
                            <div>
                              <Button
                                //className={classes.inputButton}
                                color="primary"
                                {...bindTrigger(popupState)}
                              >
                                Add synthetic transaction
                              </Button>
                              <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              >
                                <Box p={2}>
                                  <SyntheticTransactionPopoverForm
                                    submitFunc={async (values) => {
                                      const response =
                                        await this.props.addSyntheticTransaction(
                                          values
                                        );

                                      if (response) {
                                        popupState.close();
                                      }
                                    }}
                                    shopperCards={
                                      shopperCards
                                        ? shopperCards.filter(
                                            (card) =>
                                              card.status.toLowerCase() ==
                                              "active"
                                          )
                                        : []
                                    }
                                  />
                                </Box>
                              </Popover>
                            </div>
                          )}
                        </PopupState>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <div className={classes.buttonWrapper}>
                      <Button
                        className={classes.deactivateButton}
                        color="default"
                        disabled={this.state.logoutButtonLoading}
                        onClick={async () => {
                          if (
                            window.confirm(
                              "Are you sure you want to logout this shopper?  This log them out from all devices. NOTE! It will take 1 hour for the logout to occur."
                            )
                          ) {
                            this.setState({ logoutButtonLoading: true });
                            await this.props.logoutShopper();
                            this.setState({ logoutButtonLoading: false });
                          }
                        }}
                      >
                        Logout Shopper
                      </Button>
                      {this.state.logoutButtonLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgressRed}
                        />
                      )}
                    </div>
                  </Grid>
                </Box>
                <Box className={classes.paper} style={{ float: "left" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        MCC codes
                      </Typography>
                      <Typography variant="body1">
                        <PopupState
                          variant="popover"
                          popupId="demo-popup-popover"
                        >
                          {(popupState) => (
                            <div>
                              <Button
                                //className={classes.inputButton}
                                color="primary"
                                {...bindTrigger(popupState)}
                              >
                                Add code
                              </Button>
                              <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              >
                                <Box p={2}>
                                  <MCCPopoverForm
                                    submitFunc={async (values) => {
                                      const response =
                                        await this.props.addMccToShopper(
                                          values
                                        );
                                      if (response) {
                                        popupState.close(); // update was succesful, close the popover
                                      }
                                    }}
                                    name="extraMcc"
                                    label="Extra MCC"
                                    initialValues={{
                                      extraMcc: "",
                                      expiresAt: "",
                                    }}
                                  />
                                </Box>
                              </Popover>
                            </div>
                          )}
                        </PopupState>
                      </Typography>
                    </Grid>
                    <Grid item>
                      {this.formattedMccs(
                        shopper,
                        classes,
                        this.props.deleteMccFromShopper
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.paper} style={{ float: "left" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        User Remapping
                      </Typography>
                      <Typography variant="body1">
                        <PopupState
                          variant="popover"
                          popupId="demo-popup-popover"
                        >
                          {(popupState) => (
                            <div>
                              <Button
                                //className={classes.inputButton}
                                color="primary"
                                {...bindTrigger(popupState)}
                              >
                                Add remapping
                              </Button>
                              <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              >
                                <Box p={2}>
                                  <UserRemappingPopoverForm
                                    submitFunc={async (values) => {
                                      const response =
                                        await this.props.addUserRemappingToShopper(
                                          values
                                        );
                                      if (response) {
                                        popupState.close(); // update was succesful, close the popover
                                      }
                                    }}
                                    initialValues={{
                                      expiresAt: "1h",
                                    }}
                                  />
                                </Box>
                              </Popover>
                            </div>
                          )}
                        </PopupState>
                      </Typography>
                    </Grid>
                    <Grid item>
                      {this.formattedUserRemapping(
                        shopperUserRemapping,
                        classes,
                        this.props.deleteUserRemappingFromShopper
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.paper} style={{ float: "left" }}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Pro Features
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <div className={classes.sideBySideRowCenter}>
                        <Typography className={classes.secondaryHeading}>
                          Instant Payouts 
                        </Typography>
                        <Tooltip title={
                          <React.Fragment>
                            <p class={ classes.tooltip }>
                              There are three possible values:
                              <ul>
                                <li style={{ padding: "0.25em 0" }}>
                                  <b style={{paddingRight: "0.5em"}}>Eligible</b>
                                  Instants Payouts is enabled if this Shopper is eligible
                                </li>
                                <li style={{ padding: "0.25em 0" }}>
                                  <b style={{paddingRight: "0.5em"}}>Disabled</b>
                                  Instants Payouts is disabled for this Shopper regardless of eligibility
                                </li>
                                <li style={{ padding: "0.25em 0" }}>
                                  <b style={{paddingRight: "0.5em"}}>Enabled</b>
                                  Instants Payouts is enabled for this Shopper regardless of eligibility
                                </li>
                              </ul>
                            </p>
                          </React.Fragment>
                        }>
                          <InfoIcon fontSize="small" style={{marginLeft: 4}}/>
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item xs style={{textAlign: "right"}}>
                      <div className={classes.buttonWrapper}>
                        <Select
                          value={
                            (
                              this.props.shopper.instantPayoutEnabled === undefined ||
                              this.props.shopper.instantPayoutEnabled === null
                            ) ?
                              "eligible" :
                              this.props.shopper.instantPayoutEnabled
                          }
                          label="Instant Payouts"
                          onChange={async (evt) => {
                            let value = evt.target.value;
                            let proceed = false;
                            if (
                              value === true &&
                              window.confirm('Are you sure you want to enable Instant Payouts?')
                            ) {
                              proceed = true;
                            }
                            else if (
                              value === false &&
                              window.confirm('Please confirm that you want to disable Instant Payouts')
                            ) {
                              proceed = true;
                            }
                            else if (value === 'eligible') {
                              proceed = true;
                            }

                            if (proceed === true) {
                              this.setState({savingInstantPayoutEnabled: true});
                              await this.props.saveInstantPayoutEnabled(value);
                              this.setState({savingInstantPayoutEnabled: false});
                            }
                            else {
                              // ew
                              this.setState({savingInstantPayoutEnabled: false});
                            }
                          }}
                        >
                          <MenuItem value={'eligible'}>Use Eligible</MenuItem>
                          <MenuItem value={false}>Disabled</MenuItem>
                          <MenuItem value={true}>Enabled</MenuItem>
                        </Select>
                        {this.state.savingInstantPayoutEnabled && (
                          <CircularProgress
                            size={18}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={9}>
                      <div className={classes.sideBySideRowCenter}>
                        <Typography className={classes.secondaryHeading}>
                          Eligible for Instant Payouts
                        </Typography>
                        <Tooltip
                          title={
                            <React.Fragment>
                              <p class={ classes.tooltip }>
                                If <u>Yes</u>, this Shopper is eligible for Instant Payouts because one of the following is True:
                                <ul>
                                  <li style={{ padding: "0.25em 0" }}>
                                    This Shopper currently pays for a Pro(Legacy or V2) or Tycoon subscription
                                  </li>
                                  <li style={{ padding: "0.25em 0" }}>
                                    This Shopper is currently on a <u>Trial</u> subscription and <u>completed</u> at least <i>5 orders</i> and the <u>elapsed time</u> between the newest and oldest completed orders is at least <i>2 days</i>
                                  </li>
                                </ul>
                              </p>
                            </React.Fragment>
                          }
                        >
                          <InfoIcon fontSize="small" style={{marginLeft: 4}}/>
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item xs={3} style={{textAlign: "right"}}>
                      {
                        (
                          this.props.shopper.instantPayoutComputeEnabled === null || 
                          this.props.shopper.instantPayoutComputeEnabled === undefined
                        ) ?
                        booleanIcons[false] :
                        (
                          this.props.shopper.instantPayoutComputeEnabled === true ?
                          booleanIcons[true] :
                          booleanIcons[false]
                        )
                      }
                    </Grid>
                    <Grid item xs={8}>
                      <div className={classes.sideBySideRowCenter}>
                        <Typography className={classes.secondaryHeading}>
                          External Transactions
                        </Typography>
                        {/* <Tooltip title={
                          <React.Fragment>
                            <p class={ classes.tooltip }>
                              There are three possible values:
                              <ul>
                                <li style={{ padding: "0.25em 0" }}>
                                  <b style={{paddingRight: "0.5em"}}>Eligible</b>
                                  External transactions are enabled if this Shopper is eligible
                                </li>
                                <li style={{ padding: "0.25em 0" }}>
                                  <b style={{paddingRight: "0.5em"}}>Disabled</b>
                                  External transactions are disabled for this Shopper regardless of eligibility
                                </li>
                                <li style={{ padding: "0.25em 0" }}>
                                  <b style={{paddingRight: "0.5em"}}>Enabled</b>
                                  External transactions are enabled for this Shopper regardless of eligibility
                                </li>
                              </ul>
                            </p>
                          </React.Fragment>
                        }>
                          <InfoIcon fontSize="small" style={{marginLeft: 4}}/>
                        </Tooltip> */}
                      </div>
                    </Grid>
                    <Grid item xs style={{textAlign: "right"}}>
                      <div className={classes.buttonWrapper}>
                        <Select
                          value={ 
                            (
                              this.props.shopper.externalTransactionsEnabled === undefined ||
                              this.props.shopper.externalTransactionsEnabled === null
                            ) ?
                              false :
                              this.props.shopper.externalTransactionsEnabled
                          }
                          label="External Transactions"
                          onChange={async (evt) => {
                            let value = evt.target.value;
                            let proceed = false;
                            if (
                              value === true &&
                              window.confirm('Are you sure you want to enable External Transactions?')
                            ) {
                              proceed = true;
                            }
                            else if (
                              value === false &&
                              window.confirm('Please confirm that you want to disable External Transactions')
                            ) {
                              proceed = true;
                            }
                            else if (value === 'eligible') {
                              proceed = true;
                            }

                            if (proceed === true) {
                              this.setState({savingExternslTransactionsEnabled: true});
                              await this.props.saveExternalTransactionsEnabled(value);
                              this.setState({savingExternslTransactionsEnabled: false});
                            }
                            else {
                              // ew
                              this.setState({savingExternslTransactionsEnabled: false});
                            }
                          }}
                        >
                          {/* <MenuItem value={'eligible'}>Use Eligible</MenuItem> */}
                          <MenuItem value={false}>Disabled</MenuItem>
                          <MenuItem value={true}>Enabled</MenuItem>
                        </Select>
                        {this.state.savingExternslTransactionsEnabled && (
                          <CircularProgress
                            size={18}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </Grid>
                    {/* <Grid item xs={9}>
                      <div className={classes.sideBySideRowCenter}>
                        <Typography className={classes.secondaryHeading}>
                          Eligible for External Transactions
                        </Typography>
                        <Tooltip
                          title={
                            <React.Fragment>
                              <p class={ classes.tooltip }>
                                If <u>Yes</u>, this Shopper is eligible for External Transactions because the following is True:
                                <ul>
                                  <li style={{ padding: "0.25em 0" }}>
                                    This Shopper currently pays for a Resort Pro subscription.
                                  </li>
                                </ul>
                              </p>
                            </React.Fragment>
                          }
                        >
                          <InfoIcon fontSize="small" style={{marginLeft: 4}}/>
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item xs={3} style={{textAlign: "right"}}>
                      {
                        (
                          this.props.shopper.subscriptionPlan === null || 
                          this.props.shopper.subscriptionPlan === undefined || 
                          !this.props.shopper.subscriptionPlan.toLowerCase().includes('resort') // would need to add addition ones here
                        ) ?
                        booleanIcons[false] :
                        (
                          booleanIcons[true] 
                        )
                      }
                    </Grid> */}
                  </Grid>
                </Box>
                <Box className={classes.paper} style={{ float: "left" }}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Beta Features
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <div className={classes.sideBySideRowCenter}>
                        <Typography className={classes.secondaryHeading}>
                          Beta Access
                        </Typography>
                        {/* <Tooltip title={
                          <React.Fragment>
                            <p class={ classes.tooltip }>
                              There are three possible values:
                              <ul>
                                <li style={{ padding: "0.25em 0" }}>
                                  <b style={{paddingRight: "0.5em"}}>Eligible</b>
                                  External transactions are enabled if this Shopper is eligible
                                </li>
                                <li style={{ padding: "0.25em 0" }}>
                                  <b style={{paddingRight: "0.5em"}}>Disabled</b>
                                  External transactions are disabled for this Shopper regardless of eligibility
                                </li>
                                <li style={{ padding: "0.25em 0" }}>
                                  <b style={{paddingRight: "0.5em"}}>Enabled</b>
                                  External transactions are enabled for this Shopper regardless of eligibility
                                </li>
                              </ul>
                            </p>
                          </React.Fragment>
                        }>
                          <InfoIcon fontSize="small" style={{marginLeft: 4}}/>
                        </Tooltip> */}
                      </div>
                    </Grid>
                    <Grid item xs style={{textAlign: "right"}}>
                      <div className={classes.buttonWrapper}>
                        <Select
                          value={ 
                            (
                              this.props.shopper.hasBetaAccess === undefined ||
                              this.props.shopper.hasBetaAccess === null
                            ) ?
                              false :
                              this.props.shopper.hasBetaAccess
                          }
                          label="Beta Access"
                          onChange={async (evt) => {
                            let value = evt.target.value;
                            let proceed = false;
                            if (
                              value === true &&
                              window.confirm('Are you sure you want to enable Beta Access?')
                            ) {
                              proceed = true;
                            }
                            else if (
                              value === false &&
                              window.confirm('Please confirm that you want to disable Beta Access')
                            ) {
                              proceed = true;
                            }
                            else if (value === 'eligible') {
                              proceed = true;
                            }

                            if (proceed === true) {
                              this.setState({savingHasBetaAccess: true});
                              await this.props.saveHasBetaAccess(value);
                              this.setState({savingHasBetaAccess: false});
                            }
                            else {
                              // ew
                              this.setState({savingHasBetaAccess: false});
                            }
                          }}
                        >
                          <MenuItem value={false}>Disabled</MenuItem>
                          <MenuItem value={true}>Enabled</MenuItem>
                        </Select>
                        {this.state.savingHasBetaAccess && (
                          <CircularProgress
                            size={18}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </Grid>
                    {/* <Grid item xs={9}>
                      <div className={classes.sideBySideRowCenter}>
                        <Typography className={classes.secondaryHeading}>
                          Eligible for External Transactions
                        </Typography>
                        <Tooltip
                          title={
                            <React.Fragment>
                              <p class={ classes.tooltip }>
                                If <u>Yes</u>, this Shopper is eligible for External Transactions because the following is True:
                                <ul>
                                  <li style={{ padding: "0.25em 0" }}>
                                    This Shopper currently pays for a Resort Pro subscription.
                                  </li>
                                </ul>
                              </p>
                            </React.Fragment>
                          }
                        >
                          <InfoIcon fontSize="small" style={{marginLeft: 4}}/>
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item xs={3} style={{textAlign: "right"}}>
                      {
                        (
                          this.props.shopper.subscriptionPlan === null || 
                          this.props.shopper.subscriptionPlan === undefined || 
                          !this.props.shopper.subscriptionPlan.toLowerCase().includes('resort') // would need to add addition ones here
                        ) ?
                        booleanIcons[false] :
                        (
                          booleanIcons[true] 
                        )
                      }
                    </Grid> */}
                  </Grid>
                </Box>
                <Box className={classes.paper} style={{ float: "left" }}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Resort Features
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <div className={classes.sideBySideRowCenter}>
                        <Typography className={classes.secondaryHeading}>
                          Closed Dates
                        </Typography>
                        <Tooltip title={
                          <React.Fragment>
                            <p class={ classes.tooltip }>
                              Shopper must have resort subcription as well as feature enabled to see closed dates.
                            </p>
                          </React.Fragment>
                        }>
                          <InfoIcon fontSize="small" style={{marginLeft: 4}}/>
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item xs style={{textAlign: "right"}}>
                      <div className={classes.buttonWrapper}>
                        <Select
                          value={ 
                            (
                              this.props.shopper.resortClosedDatesEnabled === undefined ||
                              this.props.shopper.resortClosedDatesEnabled === null
                            ) ?
                              false :
                              this.props.shopper.resortClosedDatesEnabled
                          }
                          label="Closed Dates"
                          onChange={async (evt) => {
                            let value = evt.target.value;
                            let proceed = false;
                            if (
                              value === true &&
                              window.confirm('Are you sure you want to enable Closed Dates?')
                            ) {
                              proceed = true;
                            }
                            else if (
                              value === false &&
                              window.confirm('Please confirm that you want to disable Closed Dates.')
                            ) {
                              proceed = true;
                            }

                            if (proceed === true) {
                              this.setState({savingResortClosedDatesEnabled: true});
                              await this.props.saveResortClosedDatesEnabled(value);
                              this.setState({savingResortClosedDatesEnabled: false});
                            }
                            else {
                              // ew
                              this.setState({savingResortClosedDatesEnabled: false});
                            }
                          }}
                        >
                          <MenuItem value={false}>Disabled</MenuItem>
                          <MenuItem value={true}>Enabled</MenuItem>
                        </Select>
                        {this.state.savingResortClosedDatesEnabled && (
                          <CircularProgress
                            size={18}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </ExpansionPanelDetailsPadded>
          </ExpansionPanel>
        </div>
        <div className={classes.expansionRootNotes}>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2c-content"
              id="panel2c-header"
            >
              <div className={classes.sideBySide}>
                <div className={classes.sideBySideRow}>
                  <Typography className={classes.summary}>Notes</Typography>
                  {shopperNotes ? (
                    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                      <ShopperNote note={shopperNotes[0]} />
                    </div>
                  ) : shopperNotesLoading ? (
                    <CircularProgress size={20} style={{ marginLeft: 24 }} />
                  ) : null}
                </div>
                <div>
                  <PopupState variant="popover" popupId="demo-popup-popover">
                    {(popupState) => (
                      <div className={classes.sideBySideRow}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Typography
                            {...bindTrigger(popupState)}
                            displayType={"text"}
                            className={classes.greenButtonText}
                            style={{ cursor: "pointer", width: 80 }}
                          >
                            Add Note
                          </Typography>
                        </div>
                        <Popover
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Box p={2}>
                            <NotesPopoverForm
                              submitFunc={async (values) => {
                                const response =
                                  await this.props.addShopperNote(values);
                                if (response) {
                                  popupState.close(); // update was succesful, close the popover
                                }
                              }}
                              name="shopperNote"
                              label="Note"
                              initialValues={{ shopperNote: null }}
                            />
                          </Box>
                        </Popover>
                      </div>
                    )}
                  </PopupState>
                </div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.notesDetails}>
              <ShopperNotesTable
                notes={shopperNotes}
                shopperNoteUpdate={this.props.shopperNoteUpdate}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        <Snackbar
          open={this.state.copiedToClipboardSnackOpen}
          autoHideDuration={5000}
          onClose={() => this.setState({ copiedToClipboardSnackOpen: false })}
        >
          <Alert
            onClose={() => this.setState({ copiedToClipboardSnackOpen: false })}
            severity="success"
          >
            Link text copied to clipboard
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles)(ShopperDetail);
