import React from "react";
import "moment-timezone";
import Linkify from "react-linkify";
import Moment from "react-moment";
import { withStyles } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import { Avatar, IconButton } from "@material-ui/core";

// TODO - define this globally or import
const linkifyComponentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

const styles = (theme) => ({
  noteIcon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    float: "left",
  },
  noteBody: {
    float: "left",
    marginLeft: theme.spacing(1),
  },
});

function ClientNote(props) {
  const { note, showButtons, clientNoteUpdate, classes } = props;

  async function handleClick() {
    if (note.pinned) {
      if (!window.confirm("Are you sure you want to unpin this note?")) {
        return;
      }
    }

    if (clientNoteUpdate) {
      await clientNoteUpdate(note.id, { pinned: !note.pinned });
    }
  }

  return (
    <div>
      <Avatar src="/icons/support.png" className={classes.noteIcon} />
      <div className={classes.noteBody}>
        <strong>
          <Moment
            unix
            format="MMM DD, YYYY h:mm A"
            tz={Intl.DateTimeFormat().resolvedOptions().timeZone}
          >
            {Number(note.createdAtTimestamp)}
          </Moment>
        </strong>
        {" - "}
        <span style={{ fontWeight: 500 }}>
          {note.baoFirstName
            ? `${note.baoFirstName} ${note.baoLastName}`
            : note.baoEmail}
        </span>
        {" - "}
        <Linkify componentDecorator={linkifyComponentDecorator}>
          {note.note}
        </Linkify>
      </div>
      {showButtons ? (
        <IconButton
          style={{ float: "right", zoom: "75%", padding: 6 }}
          color={!!note.pinned ? "primary" : ""}
          onClick={handleClick}
        >
          <StarIcon />
        </IconButton>
      ) : (
        ""
      )}
    </div>
  );
}

export default withStyles(styles)(ClientNote);
