import React, { Component } from "react";
import MaterialTable from "material-table";
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import Moment from 'react-moment';
import 'moment-timezone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';


const styles = theme => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  address: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
  },
  customItem: {
    color: 'black',
    fontWeight: 550
  },
  table: {
    minWidth: 650,
  },
  transactionPrice: {
    color: theme.palette.error.main,
    fontWeight: 550
  }
})

class ShopperDeclinedTransactionsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveryValue: null,
      showLoading: false
    };
  }

  render() {
    const { transactions, classes } = this.props;

    const transactionsList = transactions.map((row, index) => {
      // get the brand based on cardAcctId
      const ccBrand = row.cardAcctId.includes('ic_') ? 'Stripe' : 'PEX'
      var last4 = ''
      // PEX doesn't have last 4
      if (row.webhook.card) { 
        if (row.webhook.card.last4) {
          last4 = row.webhook.card.last4
        }
      }
      // the decline reason is in a different place if this is from PEX or Stripe
      var declineReason = "Unknown reason"
      var declinedByStripe = false  // if the decline is not from our authorizor then we want to make it stand out as that needs to be addressed
      if (row.webhook.Data) {  // PEX
        if (row.webhook.Data.Message) {
          declineReason = row.webhook.Data.Message
        }
      } else if (row.webhook.metadata) {  // Stripe
        if (row.webhook.metadata.declineReasons) {  // we record the reasons why we decliend in the metadata
          declineReason = row.webhook.metadata.declineReasons
        } else {  // declined by stripe
          declinedByStripe = true
          if (row.webhook.request_history) {  // if that is not there for some reason show Stripe's reason
            if (row.webhook.request_history.length) {
              // there are some reasons so blank out the default reason
              declineReason = ''
            }
            row.webhook.request_history.forEach(request => {
              declineReason += request.reason ? request.reason : ''
              // TODO - this is no longer suppored by stripe.  need to see what we are missing out on
              // if (request.violated_authorization_controls.length) {
              //   declineReason += ': '
              //   request.violated_authorization_controls.forEach(violation => {
              //     declineReason += violation.name + ', '
              //   })
              //   // remove final ', '
              //   declineReason = declineReason.slice(0, -2)
              // }
            })
          }
        }
      }
      return (
        <TableRow key={row.id}>
          <TableCell component="th" scope="row">
            <NumberFormat className={classes.transactionPrice} value={-row.transactionAmount/100.0} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} prefix={'$'} /> -{' '}
            {declinedByStripe ? <span style={{fontWeight: 600}}>Stripe: {declineReason}</span> : declineReason}
            {', '}
            {row.merchantName}{', '}
            {row.merchantCity}{', '}
            {row.merchantState}{' - '}
            <span style={{fontWeight: 500}}>{ccBrand}</span>
            {last4 ? `: ${last4}` : ''} 
            {' - '}
            <Moment format="MMM DD, YYYY h:mm A z" tz="America/New_York" >{`${row.transactionUtcTime}Z`}</Moment>{' - '}
            <Moment fromNow>{`${row.transactionUtcTime}Z`}</Moment>
          </TableCell>
        </TableRow>
      )
    })

    return (
      <div style={{ maxWidth: "100%" }}>    
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Declined Transactions (past 12 hours)
                {!transactionsList ? <LinearProgress /> : null}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsList}
          </TableBody>
        </Table> 
      </div>
    );
  }
}

export default withStyles(styles)(ShopperDeclinedTransactionsTable);
