import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore";
import PeopleIcon from "@material-ui/icons/People";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { Auth, Hub } from "aws-amplify";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import PopupState, {
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state";
import TOTPPopoverForm from "./TOTPPopoverForm.js";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logout: {
    marginLeft: 10
  }
});

class Layout extends Component {
  state = {
    open: false,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleLogout = async (event) => {
    await Auth.signOut();
    // This is needed to go back to the login page
    Hub.dispatch("UI Auth", {
      event: "AuthStateChange",
      message: "signedout",
    });
  };

  layoutTitle = (pathname) => {
    const config =
      process.env.REACT_APP_STAGE === "prod"
        ? " (Production Branch)"
        : " (Dev Branch)";
    // just match on the first part of the path
    switch (pathname.replace(/^\/([^/]*).*$/, "$1")) {
      case "shoppers":
        return "Shoppers" + config;
      case "clients":
        return "Clients (Alpha!)" + config;
      case "orders":
        return "Orders" + config;
      case "delivery-map":
        return "Delivery Map" + config;
      default:
        return "Bao" + config;
    }
  };

  render() {
    const {
      classes,
      children,
      location: { pathname },
    } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: this.state.open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {this.layoutTitle(pathname)}
            </Typography>
            <Typography variant="body1">
              <PopupState
                variant="popover"
                popupId="demo-popup-popover"
              >
                {(popupState) => (
                  <div>
                    <Button
                      className={classes.inputButton}
                      color="inherit"
                      {...bindTrigger(popupState)}
                    >
                      MFA Setup
                    </Button>
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Box p={2}>
                        <TOTPPopoverForm
                          submitFunc={async (values) => {
                            const { secretCode} = values
                            let user = await Auth.currentAuthenticatedUser();
                            const response =
                              await Auth.verifyTotpToken(user, secretCode).catch( e => {
                                alert(e)
                              })
                            if (response) {
                              Auth.setPreferredMFA(user, 'TOTP');
                              popupState.close(); // update was succesful, close the popover
                            }
                          }}
                          name="secretCode"
                          label="One-time Code"
                          popupState={popupState}
                        />
                      </Box>
                    </Popover>
                  </div>
                )}
              </PopupState>
            </Typography>
            <Button color="inherit" onClick={this.handleLogout} className={classes.logout}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem
              button
              key="Shoppers"
              component={Link}
              to="/shoppers"
              selected={pathname.startsWith("/shoppers")}
            >
              <ListItemIcon>
                <LocalGroceryStoreIcon />
              </ListItemIcon>
              <ListItemText primary="Shoppers" />
            </ListItem>
            <ListItem
              button
              key="Clients"
              component={Link}
              to="/clients"
              selected={pathname.startsWith("/clients")}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Clients" />
            </ListItem>
            <ListItem
              button
              key="Orders"
              component={Link}
              to="/orders"
              selected={pathname.startsWith("/orders")}
            >
              <ListItemIcon>
                <FormatListNumberedIcon />
              </ListItemIcon>
              <ListItemText primary="Orders" />
            </ListItem>
            <ListItem
              button
              key="Coupons"
              component={Link}
              to="/coupons"
              selected={pathname.startsWith("/coupons")}
            >
              <ListItemIcon>
                <LocalActivityIcon />
              </ListItemIcon>
              <ListItemText primary="Orders" />
            </ListItem>
            {/*<ListItem button key='Delivery Map' component={Link} to='/delivery-map' selected={pathname.startsWith('/delivery-map')} >
              <ListItemIcon><MapIcon /></ListItemIcon>
              <ListItemText primary='Delivery Map' />
            </ListItem>*/}
          </List>
          <Divider />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Layout));
